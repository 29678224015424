import React, { Component, createRef } from 'react'



export default class UserContextMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            x: '',
            y: '',
            visible: false,
            width: '200px',
            user: {}
        }



    }
    handleDocumentClicks = (e) => {
        if (e.target.getAttribute('name') !== 'user_menu_toggler') {
            this.hide();
        }

        console.log(e);
    }
    handleOnProfile = () => {
        this.props.userDialog.current.show(this.state.user);
    }
    handleJudgementRemove() {
        // this.confirmDialogRef.current.show("Are you sure you want to delete this judgement from the database?");

    }
    show = (x, y, user) => {

        document.addEventListener("click", this.handleDocumentClicks)
        this.setState({ x: x, y: y, visible: true, user: user });
    }
    hide() {
        document.removeEventListener("click", this.handleDocumentClicks);
        this.setState({ visible: false });
    }
    render() {
        let { x, y, visible, width, height, user } = this.state;
        return (
            <div className={'bg-white dark:bg-gray-900 dark:text-white dark:border-gray-800 border  shadow-lg ' + (visible ? ' block ' : '  hidden ')} style={{ width: width, top: (y) + 'px', left: (x - 200) + 'px', position: 'absolute', zIndex: '9000' }} >
                <div onClick={() => this.handleOnProfile()} className='flex px-3 py-2 text-sm hover:bg-gray-300 dark:hover:bg-gray-800 hover:rounded-sm dark:border-b-gray-700 border-b' style={{ cursor: 'pointer' }}>
                    Profile
                </div>
                <div onClick={() => this.props.confirmRemove(user)} className='flex px-3 py-2 text-sm hover:bg-gray-300 dark:hover:bg-gray-800 hover:rounded-sm dark:border-b-gray-700 border-b' style={{ cursor: 'pointer' }}>
                    Remove
                </div>

            </div>




        )
    }
}
