import React, { Component, createRef } from 'react'
import JudgementPreviewDialog from './judgementPreviewDialog';

export default class ReferenceItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_expanded: false,

        }

        this.judgementPreviewDialog = createRef();
    }
    toggleTagItemsView = () => {
        let { expanded } = this.state;
        this.setState({ expanded: !expanded });
    }
    renderHeader() {
        return (
            <div onClick={() => this.toggleTagItemsView()} className={'flex cursor-pointer justify-between mb-2 items-center dark:border-gray-700 ' + (this.state.expanded ? ' rounded py-2 px-0.5 bg-gray-300 dark:bg-gray-800' : 'py-2 mt-1')}>

                <div className='flex justify-start'>
                    <button className='dark:text-white' >
                        {this.state.expanded ?
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        }
                    </button>
                    <div className='dark:text-gray-200 font-semibold text-sm  ms-2'>{this.props.reference.judgement?.titleStmt_main}({this.props.reference.paragraphs.length})</div>

                </div>

            </div >
        );
    }
    toggleJudgementPreview(reference, annotation) {
        this.judgementPreviewDialog.current.show(reference, annotation, this.props.reference.paragraphs);
    }
    renderBody() {
        return (
            <div className=''>
                <div>
                    <div className='border-b dark:border-gray-700 border-gray-300 mb-2 py-1'>
                        <div className='text-gray-500 text-sm'>Annotation</div>
                        <div>{this.props.annotation.annotation}</div>
                    </div>
                    <div className='border-b dark:border-gray-700 border-gray-300 mb-2 py-1'>
                        <div className='text-gray-500 text-sm'>Judgement subtitle</div>
                        <div>{this.props.reference.judgement.titleStmt_sub}</div>
                    </div>

                    <div className='border-b dark:border-gray-700 border-gray-300 mb-2 py-1'>
                        <div className='text-gray-500 text-sm'>Paragraphs</div>
                        <div className='flex flex-wrap'>
                            {this.props.reference.paragraphs.map((item, index) => {
                                return (
                                    <div className='text-blue-500 ml-1'>{item.paragraph.paragraph_number}, </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className='py-1 border-b dark:border-gray-700 border-gray-300  flex justify-start'>
                        <div onClick={() => this.toggleJudgementPreview(this.props.reference.judgement, this.props.annotation)} className='cursor-pointer items-center text-md flex p-1 rounded'>
                            Preview
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 ml-1 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    render() {

       

        return (

            <div className={'mt-3 border-gray-300 dark:border-gray-700  w-full ' + (this.state.expanded ? '  ' : 'dark:bg-gray-900  border rounded-md')}>
                {this.renderHeader()}
                {this.state.expanded ? this.renderBody() : ''}

                <JudgementPreviewDialog parent_functions={this.props.parent_functions} parent_state={this.props.parent_state} ref={this.judgementPreviewDialog} />
            </div>


        )
    }
}
