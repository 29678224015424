import React, { Component } from 'react'
import { scrollToParagraph } from '../utils/utils';
import { getAnnotationReference } from '../backend_handlers/referenceController';
import { ToastContainer, toast } from 'react-toastify';
import ReferenceViewDialog from './referenceViewDialog';
export default class JudgementPreviewDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_visible: false,
            judgement: {},
            annotation: {},
            paragraphs: []
        }


    }
    show = (judgement, annotation, paragraphs) => {
        this.setState({ is_visible: true, judgement: judgement, annotation: annotation, paragraphs: paragraphs });
    }
    hide() {
        this.setState({ is_visible: false })
    }
    async showReference(e) {

        let annotation = this.props.parent_state.annotation_metadata.find(Obj => {
            return Obj.annotation.annotation.trim() == e.target.innerText.trim();
        })

        this.props.parent_functions.referenceViewDialog.current.show(annotation?.reference);

    }
    addMarkups = (data) => {

        //add markups to highlihted texts
        let { annotation } = this.state;

        var regex = new RegExp("\\b" + this.escapeRegExp(annotation.annotation) + "\\b", "gi");


        let paragraphs = [];

        data = data.replace(regex, function (match) {
            paragraphs.push()
            return '<span name="annotation_item"   style="cursor:pointer; background-color:#FF0000" class="p-0.5 pt-0  text-center inline:flex items-center text-white rounded-md ">' + match + '</span>';
        });


        var html_data = { __html: data };

        return (<div onClick={(e) => this.showReference(e)} className='' dangerouslySetInnerHTML={html_data} ></div>);

    }

    escapeRegExp(text) {

        if (text) {
            return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
        }

    }
    scrollToParagraph(paragraph) {

        if (paragraph <= 2)
            paragraph = 2

        else
            paragraph = paragraph - 2;

        let element = document.getElementById('VParagraph-' + (parseInt(paragraph)));
        console.log(element);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    render() {



        return (
            <div className={`${this.state.is_visible ? 'block' : 'hidden'} bg-opacity-60 h-screen w-screen z-10 bg-gray-500 fixed top-0 left-0`}>
                <div id='' className={'overflow-x-hidden overflow-y-auto bg-white  dark:bg-gray-900   dark:border-gray-800 w-4/5 h-2/3  sm:w-3/5  sm:h-1/2' + (this.state.is_visible == true ? ' block ' : ' hidden ')} style={{ boxShadow: '0px 0px 30px 4px rgba(0,0,0,0.6)', zIndex: '9000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}  >

                    <div className='sticky top-0'>
                        <div className=' flex justify-between items-center bg-gray-300 border-none  text-gray-900 py-1 px-2 dark:bg-gray-800 dark:text-gray-100'>
                            <div>
                                <div className='font-semibold'>{this.state.judgement?.titleStmt_main}</div>
                                <div className='text-sm'>{this.state.judgement?.titleStmt_sub}</div>
                            </div>
                            <div onClick={() => this.hide()} className='cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 text-gray-600 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>

                        </div>

                        <div className='bg-gray-100 p-2 flex justify-between p-2 border '>
                            <div className='flex flex-wrap'>
                                <div className=''>{this.state.annotation.annotation}</div>
                                <div className='flex flex-wrap px-1'> [{this.state.paragraphs.map((paragraph, index) => {
                                    return (
                                        <span onClick={() => this.scrollToParagraph(paragraph.paragraph.paragraph_number)} className='cursor-pointer text-blue-500 mx-1'>{paragraph.paragraph.paragraph_number},</span>
                                    );
                                })}]</div>
                            </div>
                        </div>
                    </div>


                    <div className='p-4 bg-white  text-gray-900'>
                        {this.state.judgement?.Paragraphs?.map((paragraph, index) => {
                            return (
                                <div className='mb-2  flex justify-between'>
                                    <div id={'VParagraph-' + paragraph.paragraph_number} className=''>
                                        {this.addMarkups(paragraph.parapgraph_content.trim())}
                                    </div>
                                    <div className='ml-2 text-gray-500'>
                                        {paragraph.paragraph_number}
                                    </div>

                                </div>
                            )
                        })}
                    </div>

                </div>



            </div>
        )
    }
}
