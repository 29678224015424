import React, { Component, createRef } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import JudgementDialog from '../../../components/judgementDialog';
import ConfirmDialog from '../../../components/confirmDialog';
import { deleteJudgement } from '../../../backend_handlers/JudgementController';
import { ToastContainer, toast } from 'react-toastify';
import Paginator from '../../../components/paginator';
import JudgementTagDiloag from '../../../components/judgementTagDialog';
import NewNotesDialog from '../../../components/newNotesDoloag';
import NoteContextDialog from '../../../components/noteContextMenu';
import Searchable from '../../../components/Searchable';
import { isInJudgementTexts } from '../../../utils/utils';



export default class JudgementListing extends Searchable {

    constructor(props) {
        super(props);

        this.state = {
            placeholder_text: "Search judgement"
        }

        this.judgementDialogref = createRef();
        this.confirmDialogref = createRef();
        this.judgementTagDialogRef = createRef();
        this.newNotesDialogRef = createRef();
        this.noteMenuRef = createRef();
        this.deleteJudgement = deleteJudgement.bind(this);
        this.isInJudgementTexts = isInJudgementTexts.bind(this);

    }

    handleOnJudgementDelete = (judgement) => {
        this.setState({ selected_judgement: judgement });
        this.confirmDialogref.current.show("Are you sure you want to remove this judgement from the database?", this.judgementDeleteConfirmedCallback);
    }
    showNotes = () => {
        alert('showing notes');
    }

    judgementDeleteConfirmedCallback = async () => {
        let response = await this.deleteJudgement(this.state.selected_judgement.id, this.props.toast);
        if (response) {
            if (response.status == 200) {
                this.props.parent_functions.fetchJudgements();
            }
        }

    }

    renderHeader() {

        let judgements = this.props.parent_state.judgements;
        return (
            <div className='flex items-center justify-between p-2 rounded-md text-lg'>
                <div className='w-2/3 '>
                    <div className='dark:text-gray-200 text-xl'>Judgement Listings</div>
                    {this.state.is_loading ? '' : <div className='text-sm dark:text-gray-500'>Showing {judgements.length} results</div>}
                </div>
                <div className='w-1/4  flex items-center '>
                    {this.renderSearchBar()}
                </div>
            </div>
        );
    }
    renderList() {

        let judgements = this.props.parent_state.judgements;

        if (this.props.parent_state.is_loading) {
            return (
                <div className='border p-5 my-5 rounded-md bg-white dark:bg-gray-900 dark:border-gray-800'>
                    <div className='p-5 flex align-center  justify-center'>
                        <div className='text-center'>
                            <ProgressSpinner style={{ height: '40px' }} />
                            <div className='dark:text-white '>Fetching Judgements..... </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (judgements.length == 0) {
            return (
                <div className='p-10 border bg-white dark:bg-gray-900 dark:border-gray-800  rounded-md border'>
                    <div className='p-5 text-gray-500 text-center  '>
                        No Judgements found. Please try again later
                    </div>
                </div>
            );
        }
        return (
            <div className='my-2  dark:border-gray-700'>

                {judgements.map((judgement, index) => {


                    if (this.props.parent_state.applied_tags != 0 && !this.props.parent_state.applied_tags.some(element => {
                        return (judgement.JudgementTags.map((item, index) => { return item.tag })).includes(element);
                    })) {
                        console.log('not match')
                    } else {

                        if (this.state.search_filter) {
                            if (judgement.titleStmt_main.toLowerCase().includes(this.state.search_filter?.trim().toLowerCase())
                                || this.isInJudgementTexts(judgement.JudgementTexts, this.state.search_filter)
                            ) {
                                return (
                                    <div className=' bg-white px-2 border rounded-md py-2 dark:border-b dark:bg-gray-900 dark:border-gray-800 mb-1   '>
                                        <div className='flex justify-between items-top'>
                                            <div style={{ cursor: 'hand' }} className='text-gray-800 font-semibold dark:text-blue-400   cursor-hand'>
                                                <Link to={'/judgement-view/' + judgement.id}>{judgement.titleStmt_main}</Link></div>

                                            <button onClick={(event) => this.judgementDialogref.current.show(event.clientX, event.clientY, judgement)} className='rounded-md dark:text-gray-200  dark:bg-gray-800 hover:bg-gray-700'>
                                                <svg name='judgement_menu_toggler' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                    <path name='judgement_menu_toggler' stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className='dark:text-gray-300 text-sm text-gray-600'>{judgement.titleStmt_sub}</div>
                                        <div className='flex justify-between'>
                                            <div className='text-gray-500 dark:text-gray-400 text-sm'>{judgement.Taxomies[0]['category_description']}</div>
                                            <div onClick={() => this.props.parent_functions.showNotes(judgement)} className='text-gray-700 bg-gray-200 rounded-sm py-1 text-sm hover:shadow-sm hover:bg-gray-300 cursor-pointer px-2'>Summary</div>
                                        </div>

                                    </div>
                                )
                            }
                        }
                        else {
                            return (
                                <div className=' bg-white px-2 border rounded-md py-2 dark:border-b dark:bg-gray-900 dark:border-gray-800 mb-1   '>
                                    <div className='flex justify-between items-top'>
                                        <div style={{ cursor: 'hand' }} className='text-gray-800 font-semibold dark:text-blue-400   cursor-hand'>
                                            <Link to={'/judgement-view/' + judgement.id}>{judgement.titleStmt_main}</Link></div>

                                        <button onClick={(event) => this.judgementDialogref.current.show(event.clientX, event.clientY, judgement)} className='rounded-md dark:text-gray-200  dark:bg-gray-800 hover:bg-gray-700'>
                                            <svg name='judgement_menu_toggler' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path name='judgement_menu_toggler' stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className='dark:text-gray-300 text-sm text-gray-600'>{judgement.titleStmt_sub}</div>
                                    <div className='flex justify-between'>
                                        <div className='text-gray-500 dark:text-gray-400 text-sm'>{judgement.Taxomies[0]['category_description']}</div>
                                        <div onClick={() => this.props.parent_functions.showNotes(judgement)} className='text-gray-700 bg-gray-200 rounded-sm py-1 text-sm hover:shadow-sm hover:bg-gray-300 cursor-pointer px-2'>Summary</div>
                                    </div>

                                </div>
                            )
                        }
                    }

                })}

                {/* <Paginator /> */}

            </div>
        );
    }
    render() {


        return (
            <div className=''>
                {this.renderHeader()}
                {this.renderList()}
                <JudgementDialog parent_functions={this.props.parent_functions} notesDialog={this.newNotesDialogRef} judgementTagDialog={this.judgementTagDialogRef} parent_state={this.props.parent_state} onDelete={this.handleOnJudgementDelete} ref={this.judgementDialogref} />
                <ConfirmDialog ref={this.confirmDialogref} />
                <JudgementTagDiloag parent_functions={this.props.parent_functions} parent_state={this.props.parent_state} ref={this.judgementTagDialogRef} />
                <NewNotesDialog
                    parent_functions={this.props.parent_functions}
                    parent_state={this.props.parent_state}
                    user={this.props.parent_state.user}
                    type={'judgement'}
                    paragraph={this.props.paragraph}
                    ref={this.newNotesDialogRef}
                />

            </div>
        )
    }
}
