import React, { Component, createRef } from 'react'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/primereact.css';
import ReferenceViewDialog from './referenceViewDialog';


export default class ReferencesTable extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }

        this.referenceViewDialog = createRef();
    }
    renderReferencesButton(rowData) {
        return (
            <div>
                <button className='bg-blue-500 text-white rounded p-1'>References</button>
            </div>
        )
    }
    render() {
        return (
            <div className='my-2'>

                {
                    this.props?.references?.map((item, index) => {
                        return (
                            <div onClick={() => this.referenceViewDialog.current.show(item)} className='flex mb-1 justify-between items-center bg-white p-2 border hover:bg-gray-300 hover:cursor-pointer rounded'>
                                <div className=''>
                                    <div className='font-semibold'>{item.annotation}</div>
                                    <div className='text-gray-500 text-sm'>
                                        <div>Judge_2019_1, Judge_2019_3</div>
                                    </div>
                                </div>
                                <div>
                                    <span className='bg-gray-400 text shadow-none text-white text-xs  rounded-md px-3 py-1'>{item.name}</span>
                                </div>

                            </div>
                        )
                    })
                }


                <ReferenceViewDialog ref={this.referenceViewDialog} />

            </div>
        )
    }
}
