import React, { Component } from 'react'
import EmailForm from '../sections/auth/recover-password/form';
import EmailVerify from '../sections/auth/recover-password/email_verify';
import ChoosePassword from '../sections/auth/recover-password/choose_password';
import { ToastContainer } from 'react-toastify';

export default class RecoverPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current_page: 'form',
            form_data: {},
        }
    }
    switchPage = (page) => {
        this.setState({ current_page: page });
    }
    updateFormData = (key, value) => {
        let { form_data } = this.state;
        form_data[key] = value;
        this.setState(form_data);
    }
    renderCurrentPage = () => {
        switch (this.state.current_page) {
            case 'form':
                return <EmailForm form_data={this.state.form_data} updateFormData={this.updateFormData} switchPage={this.switchPage} />
                break;

            case 'email_verify':
                return <EmailVerify updateFormData={this.updateFormData} form_data={this.state.form_data} switchPage={this.switchPage} />

            case 'choose_password':
                return <ChoosePassword form_data={this.state.form_data} switchPage={this.switchPage} updateFormData={this.updateFormData} />

            default:
                break;
        }
    }
    render() {
        return (
            <div className='h-[100vh] flex justify-center items-center'>

                <div className='w-full md:w-1/3 '>
                    <div className='p-5 py-7 rounded-md bg-white dark:bg-gray-900 dark:border-0 rounded-md  mx-2 md:mx-0  border rounded-md'>
                        <div>
                            <a href='/' className='cursor-pointer text-xl font-semibold dark:text-gray-100'>Malawi Law Annotator</a>
                            <div className='text-gray-500'>Reset your account password</div>
                        </div>

                        <div className=''>

                            {this.renderCurrentPage()}

                        </div>
                    </div>
                    <div className='text-center text-gray-500 text-xs mt-5 bottom-0'>&copy;2023 Malawi Law Annotator</div>
                </div>
              
              <ToastContainer />

            </div>
        )
    }
}

document.title = "Recover Password";
