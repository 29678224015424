import React, { Component, createRef } from 'react'
import AdminNavBar from '../../../components/admin-nav';
import JudgementListing from '../../sections/index/judgement-listings';
import { uploadJudgementFile } from '../../../backend_handlers/JudgementController';
import { ProgressSpinner } from 'primereact/progressspinner';
import { toast, ToastContainer } from 'react-toastify';
import judgementDialog from '../../../components/judgementDialog';


export default class AdminJudgements extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form_data: {},
            form_errors: {},
            current_page: 'upload_form',
            is_uploading: false
        }
        this.uploadJudgementFile = uploadJudgementFile.bind(this);
        this.judgementListingRef = createRef();
    }
    fileChangeHandler = (event) => {
        let { form_data } = this.state;
        form_data[event.target.name] = event.target.files[0];
        console.log(form_data);
        this.setState({ form_data });
    }
    renderCurrentPage() {
        switch (this.state.current_page) {
            case 'upload_form':
                this.renderCreateForm();
                break;
            case 'loading':
                this.renderLoading();
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <div>
                <AdminNavBar />
                <div className='p-2  sm:px-10 sm:flex'>
                    <div className='w-full sm:w-3/4'>
                        <JudgementListing ref={this.judgementListingRef} />
                    </div>



                </div>

                <ToastContainer />
            </div>
        )
    }
}
