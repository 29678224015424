import axios from "axios";

export async function login(email, password, toast) {

    try {

        let response = await axios.post(`${process.env.REACT_APP_URL}/api/auth/login`, { 'email': email, 'password': password });
        return response;

    } catch (error) {
        console.log(error);
        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error("There was an error while signing in", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        return error;
    }

}