import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { setLogout } from '../../redux';

export default class PendingApproval extends Component {

    logout() {
        this.props.dispatch(setLogout());
        window.location.href = "/";
    }

    render() {



        return (
            <div className='h-[100vh] flex justify-center items-center'>

                <div className='w-full sm:w-2/4 '>
                    <div className='p-5 rounded-md bg-white dark:bg-gray-900 dark:border-gray-800 rounded-md  mx-2 sm:mx-0  border rounded-md'>

                        <div className='sm:flex items-center '>

                            <div className='wfull sm:w-2/4'>
                                <div className='text-3xl font-semibold dark:text-gray-100'>Pending Approval!</div>
                            <div className='text-gray-500 my-2'>Hey {this.props.user?.name}, <br /> The administrator has not yet approved approved your annotator account. Please check again later.</div>
                            <button onClick={() => this.logout()} className='bg-blue-500 p-2 mt-3 rounded-md text-md text-white'>Back to Home</button>
                        </div>

                        <div className='w-full text-center mt-2 sm:mt-0 sm:w-2/4'>
                            <img style={{ height: "300px" }} src={'/pending.png'}></img>
                        </div>

                    </div>

                </div>
            </div>
            </div >

        )

    }
}
