import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    token: null, 
    print_data : null
}


export const userSlice = createSlice({

    name: 'auth',
    initialState,
    reducers:{
        setLogin: (state,action)=>{
           state.user = action.payload.user;
           state.token = action.payload.token;
        },
        setPrintData :(state, action)=>{
            state.print_data = action.print_data
        },
        clearPrintData: (state)=>{
            state.print_data =null
        },
        setLogout: (state) =>{
            state.user= null;
            state.token=null;
        }
    }
})


export const {setLogin,setLogout, setPrintData, clearPrintData} = userSlice.actions;

export default userSlice.reducer;