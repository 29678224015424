import React, { Component } from 'react'
import { createTag } from '../backend_handlers/TagController'
import { toast } from 'react-toastify';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from 'primereact/radiobutton';
import { createJudgementTag } from '../backend_handlers/judgementTagController';

export default class JudgementTagDiloag extends Component {
  constructor(props) {
    super(props);
    this.createJudgementTagTag = createJudgementTag.bind(this);

    this.state = {
      form_data: {
        scope: 'Private'
      },
      form_errors: {

      },
      is_visible: false,
      is_loading: false,
      judgement: {}
    }
  }
  changeHandler = (e) => {
    let { form_data } = this.state;
    form_data[e.target.name] = e.target.value;
    this.setState({ form_data });
  }
  show = (judgement) => {
    this.setState({ is_visible: true, judgement: judgement });
  }
  async handleCreateTag() {

    let { form_data, judgement } = this.state;
    form_data['judgement_id'] = judgement.id;
    form_data['user_id'] = this.props.parent_state.user.id;




    if (!form_data.name || form_data.name === "") {
      let { form_errors } = this.state;
      form_errors.name = 'Name can not be empty';
      this.setState({ form_errors });
    }
    else {
      this.setState({ is_loading: true });
      let response = await this.createJudgementTagTag(form_data, toast);


      if (response?.status == 200) {
        this.setState({ is_loading: false, is_visible: false, form_errors: {}, form_data: {} });
        this.props.parent_functions.addTag(response.data);

      }
      else {
        this.setState({ is_visible: false, is_loading: false, form_data: {}, form_errors: {} });
      }

    }

  }
  renderCreateFormBody = () => {
    let { form_data, form_errors } = this.state;
    return (
      <div className='px-3 py-3 bg-white'>
        <div className=''>
          <div className=''>
            <div>Tag Label</div>
            <input placeholder={'type tag name here'} name='name' value={form_data.name} onChange={this.changeHandler} class={"mt-1.5 text-sm border-gray-300 rounded w-full py-1.5 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.name ? ' border-red-500 ' : ' ')} type="text" />
            <div className='text-red-500 text-sm'>{form_errors.name}</div>
          </div>
          <div className="my-1 mt-3">
            <label>Accessibillity </label>
            <div className="text-sm text-gray-500">
              Choose wheather this tag should be public or private
            </div>
            <div className="flex my-1  py-1 px-1 flex-wrap gap-3">
              <div className="flex  items-center mb-1">
                <RadioButton
                  inputId="access1"
                  name="scope"
                  value="Private"
                  onChange={(e) => this.changeHandler(e)}
                  checked={this.state.form_data.scope === "Private"}
                />

                <div className="ml-2">
                  <label htmlFor="ingredient1" className="">
                    Private
                  </label>
                  <div className="text-xs text-gray-500">
                    This tag will be visible to you only
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <RadioButton
                  inputId="access2"
                  name="scope"
                  value="Public"
                  onChange={(e) => this.changeHandler(e)}
                  checked={this.state.form_data.scope === "Public"}
                />
                <div className="ml-2">
                  <label htmlFor="ingredient1" className="">
                    Public
                  </label>
                  <div className="text-xs text-gray-500">
                    This tag will be visible to all{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>

      </div>
    )
  }
  handleOnClose = (e) => {
    if (!this.state.is_loading) {
      this.setState({ is_visible: false, form_data: {}, form_errors: {} });
    }
  }
  renderLoading() {
    return (
      <div className='p-2  my-5 bg-white flex justify-center'>
        <div className='text-center'>
          <ProgressSpinner style={{ width: '40px', height: '40px' }} />
          <div className='text-gray-800  dark:text-gray-500 text-sm'>Creating tag. Please wait...</div>
        </div>

      </div>
    );
  }
  render() {

    return (
      <div className={`${this.state.is_visible ? "block" : "hidden"} bg-opacity-50 h-screen w-screen z-10 bg-gray-500 fixed top-0 left-0`} >
        <div name="new_tag_dialog" className={'bg-white dark:bg-gray-800 shadow-lg border dark:border-gray-700 w-4/5  sm:w-1/3' + (this.state.is_visible == true ? ' block ' : ' hidden ')} style={{ zIndex: '9000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}  >
          <div className='p-2 bg-gray-200 dark:bg-gray-800 dark:text-gray-100 flex justify-between'>
            <div>Create Judgement Tag</div>
            <div className='text-gray-500' onClick={() => this.handleOnClose()} style={{ cursor: 'pointer' }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          </div>
          {this.state.is_loading ?
            this.renderLoading() :
            this.renderCreateFormBody()
          }

          <div className='flex border bg-gray-100 justify-end py-1 px-2'>
            <button
              onClick={() => this.handleCreateTag()}
              className="hover:bg-blue-700 bg-blue-500 px-4 py-1 text-sm rounded-sm text-white mx-1 "
            >
              Save
            </button>
            <button
              onClick={() => this.handleOnClose()}
              className="hover:bg-gray-300 bg-gray-100 border px-4 py-1 text-sm rounded-sm text-blue-500 mx-1"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    )
  }
}
