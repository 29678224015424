import { Routes, Route, useParams, Navigate } from "react-router-dom";
import IndexPage from "./pages/index";
import ViewPage from "./pages/judgement_view/view_page";
import JudgementsPage from "./pages/judgements/judgements";
import LoginPage from "./pages/auth/login";
import AdminHome from "./pages/admin/home";
import AdminJudgements from "./pages/admin/judgements/index";
import RegisterPage from "./pages/auth/register";
import UsersPage from "./pages/admin/users";
import PendingApproval from "./pages/others/pending_approval";
import { useSelector, useDispatch } from "react-redux";

import NotFound from "./pages/others/not_found";
import ReferencesPage from "./pages/admin/references/references";
import RecoverPassword from "./pages/auth/recover_password";
import AboutPage from "./pages/about/about";
import LegislationPage from "./pages/admin/legislation/LegislationPage";



function App() {

  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const Wrapper = (props) => {
    const params = useParams();
    return <ViewPage  {...{ ...props, match: { params } }} />
  }

  return (
    <Routes>

      <Route path="/" element={<IndexPage />} />

      <Route path="/home" element={user?.role == 'annotator-pending' ? <Navigate to={'/annotator/pending'} /> : user?.role == 'admin' ? <Navigate to={'/admin/home'} /> : <Navigate to={'/'} />} />
      

      <Route path="/login" element={token ? <Navigate to={user.role == 'admin' ? '/admin/home' : '/'} /> : <LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/recover-password" element={<RecoverPassword />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/judgements" element={<JudgementsPage />} />
      <Route path="/admin/judgements" element={<AdminJudgements />} ></Route>



      {user?.role == 'admin' ?
        <>
          <Route path="/admin/home" element={<AdminHome user={user} token={token} />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/references" element={<ReferencesPage user={user} token={token} />} />
          <Route path="/legislation" element={<LegislationPage user={user} token={token} />} />
        </>
        :
        <></>
      }
      <Route path="/annotator/pending" element={<PendingApproval user={user} dispatch={dispatch} />}></Route>
      <Route path="/judgement-view/:id" user={user} element={<Wrapper />} />

      <Route path="*" element={<NotFound />} />


    </Routes>


  );
}

export default App;
