import React, { Component, useEffect } from 'react'
import NavBar from './navbar'
import AdminNavBar from './admin-nav';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../redux';
import Footer from './footer';

export default class Page extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dispatch: null,
            user: null,
            token: null
        }
    }
    DummyView = () => {
        const user = useSelector(state => state.user)
        const token = useSelector(state => state.token)
        const dispatch = useDispatch()
        useEffect(() => {
            this.setState({
                user: user,
                token: token,
                dispatch: dispatch
            })
        }, [])
        return null
    }
    /* child class will provide implementation of this method */
    renderBody() { }



    render() {

        return (
            <div className=' h-screen flex flex-col  '>

                <NavBar />

                <div className=' dark:bg-black'>
                    {this.renderBody()}
                </div>

                <this.DummyView />

                <Footer />

            </div>
        )
    }
}
