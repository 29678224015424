import React, { Component } from 'react'

export default class SidePane extends Component {

    renderHeader() {
        return (
            <div className=' bg-dark:grey-800 p-2 mb-3  dark:border-gray-700 '>
                <div className=' dark:text-gray-200 dark:font-semibold text-lg '>Quick Stats</div>
                <div className='text-gray-500 text-sm '>Quick stats on user activity and accounts</div>
            </div>
        );
    }
    renderPendingList() {


        return (
            <div className='' >
                <div className='p-1 border-b dark:border-gray-700'>
                    <div className='text-blue-500 text-2xl font-semibold' >
                        {this.props.stats.all_users ? this.props.stats.all_users : '--'}
                    </div>
                    <div className='text-gray-600'>All user accounts</div>
                </div>
                <div className='p-1 border-b dark:border-gray-700'>
                    <div className='text-blue-500 text-2xl font-semibold' >
                        {this.props.stats.all_users ? this.props.stats.admin_users : '--'}
                    </div>
                    <div className='text-gray-600'>Administrator accounts</div>
                </div>

                <div className='p-1 border-b dark:border-gray-700'>
                    <div className='text-blue-500 text-2xl font-semibold' >
                        {this.props.stats.all_users ? this.props.stats.pending_annotator : '--'}
                    </div>
                    <div className='text-gray-600'>Pending Annotator accounts</div>
                </div>
            </div>
        );

    }
    render() {
        return (
            <div className=''>
                {this.renderHeader()}

                <div className='mt-2'>
                    {this.renderPendingList()}
                </div>

            </div>
        )
    }
}
