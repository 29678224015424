import { ProgressSpinner } from 'primereact/progressspinner';
import React, { Component } from 'react'
import { validate } from '../../../../backend_handlers/validationController';
import { updateUser } from '../../../../backend_handlers/UsersController';
import { toast } from 'react-toastify';



export default class ChoosePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current_page: 'passwords',
            form_data: this.props.form_data,
            form_errors: {}
        }

        this.validate = validate.bind(this);
        this.updateUser = updateUser.bind(this);
    }

    switchPage = (page) => {
        this.setState({ current_page: page })
    }

    sendChangePasswordRequest = async () => {

        this.setState({ current_page: 'loading' });
        let response = await validate(this.props.form_data, 'passwords');

        if (!response.success) {
            this.setState({ form_errors: response.data.errors })
            toast.error("There was an error while validating passwords", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {

            this.setState({ form_errors: {} })
            let response = await updateUser(this.props.form_data);

            if (response.status == 200) {
                toast.error("Password updated successfully", {
                    position: toast.POSITION.TOP_RIGHT
                });
                window.location.href = '/login';
            } else {
                toast.error("There was an error while updating  password", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }

        this.setState({ current_page: 'passwords' });
    }
    renderCurrentPage() {

        switch (this.state.current_page) {
            case 'passwords':
                return this.renderPasswordsForm();

            case 'loading':
                return this.renderLoading();

            default:
                break;
        }
    }
    changeHandler = (e) => {
        this.props.updateFormData(e.target.name, e.target.value);
    }
    renderPasswordsForm() {
        let { form_data, form_errors } = this.state;
        return (
            <div>
                <div className=''>
                    <div>
                        <div className='text-gray-700 dark:text-gray-100'>Password</div>
                        <input name='password' value={form_data.password} onChange={this.changeHandler} class={"mt-2  appearance-none border-gray-300 rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.otp || form_errors.general_error ? ' border border-red-900' : ' ')} id="otp" type="password" placeholder='choose password' />
                        <div className='text-red-500 text-sm mt-1'>{form_errors.password}</div>
                    </div>

                    <div className='mt-2'>
                        <div className='text-gray-700 dark:text-gray-100'>Confirm Password</div>
                        <input name='password_confirmation' value={form_data.password_confirmation} onChange={this.changeHandler} class={"mt-2  appearance-none border-gray-300 rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.otp || form_errors.general_error ? ' border border-red-500' : ' ')} id="otp" type='password' placeholder="re-type password to confirm" />
                        <div className='text-red-500 text-sm mt-1'>{form_errors.password_confirmation}</div>
                    </div>

                </div>

                <div className='flex justify-between mt-4'>
                    <button onClick={this.sendChangePasswordRequest} className='bg-blue-700 px-4 text-gray-100 rounded-sm py-1.5 hover:bg-blue-500'>
                        Save
                    </button>

                </div>
            </div>
        );
    }

    renderLoading() {
        return (
            <div className='my-2 flex justify-center border border-gray-300 dark:border-gray-800'>
                <div className='flex justify-between items-center'>
                    <div>
                        <ProgressSpinner style={{ width: '30px' }} />
                    </div>

                    <div className='text-sm mx-2  text-gray-500'>Processing. Please wait</div>
                </div>
            </div>
        );
    }
    render() {

        return (
            <div>
                <div className=''>
                    <div className='my-5'>
                        {this.renderCurrentPage()}
                    </div>
                </div>

            </div>
        )
    }
}
