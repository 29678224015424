import axios from "axios";

export async function loadLegislationMetadata(url, toast) {

    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/legislation/get-metadata`, { 'url': url });
        return response;

    } catch (error) {
        console.log(error);
        toast.error("There was an error while fetching document", {
            position: toast.POSITION.TOP_RIGHT
        });
        return error;
    }

}

export async function getLegislations(toast) {

    try {
        let response = await axios.get(`${process.env.REACT_APP_URL}/api/legislation`);
        return response;

    } catch (error) {
        console.log(error);
        toast.error("There was an error while fetching legislations", {
            position: toast.POSITION.TOP_RIGHT
        });
        return error;
    }

}



export async function saveLegislation(url, toast) {
    console.log("saving legislation at "+url);

    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/legislation/save-fromurl`, { 'url': url });
        toast.success("Legislation saved successfully", {
            position: toast.POSITION.TOP_RIGHT
        });
        return response;

    } catch (error) {
        console.log(error);
        toast.error("There was an error while saving legilsation", {
            position: toast.POSITION.TOP_RIGHT
        });
        return error;
    }

}