import React, { Component } from 'react'
import { validate } from '../../../../backend_handlers/validationController'
import { ProgressSpinner } from 'primereact/progressspinner';

export default class EmailForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form_data: this.props.form_data,
            form_errors: {},
            is_validating: false
        }
        this.validateData = validate.bind(this);
    }
    changeHandler = (e) => {
        let { form_data } = this.state;
        form_data[e.target.name] = e.target.value;
        this.setState({ form_data });
    }
    validateEmail = async () => {

        this.setState({ is_validating: true });
        let response = await this.validateData(this.state.form_data, 'reset_password_form');
        if (!response.success) {
            this.setState({ form_errors: response.data.errors });
            this.setState({ is_validating: false });
            return;
        }

        this.setState({ is_validating: false });
        this.props.updateFormData('email', this.state.form_data.email);
        this.props.updateFormData('user_id', response.data.id);
        this.props.switchPage('email_verify');
    }
    render() {

        let { form_data, form_errors } = this.state;
        return (
            <div className='my-4'>

                <div className='dark:text-gray-300 text-gray-800'>
                    Enter your  email  to reset password
                </div>
                <input name='email' value={form_data.email} onChange={this.changeHandler} class={"mt-2  appearance-none border-gray-300 rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.email || form_errors.general_error ? ' border border-red-500' : ' ')} id="username" type="text" placeholder="Email" />
                <div className='text-red-500 text-sm mt-1'>{form_errors.email}</div>
                <button disabled={this.state.is_validating ? true : false} onClick={() => this.validateEmail()} className='hover:bg-blue-700 mt-2 bg-blue-500 px-3 text-gray-100 rounded-sm py-1.5 hover:bg-blue-500'>
                    {this.state.is_validating ?
                        <div className='flex'>
                            <ProgressSpinner style={{ height: '20px', width: '30px' }} />
                            <div>Validating...</div>
                        </div>
                        :
                        "Reset"
                    }
                </button>
            </div>
        )
    }
}
