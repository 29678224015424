import React, { Component } from 'react'
import TagFilterItem from '../../../../components/tag_filter_item'
import { ProgressSpinner } from 'primereact/progressspinner'

export default class JudgementTags extends Component {

    renderContent = () => {
        if (this.props.parent_state.is_loading_tags) {
            return (
                <div className='border py-10 rounded px-2 justify-center items-center flex'>
                    <div className='flex items-cneter'>
                        <ProgressSpinner style={{ height: '20px', width: '20px' }} />
                        <div className="text-sm text-gray-500 ml-1">Fetching Tags. Please wait..</div>
                    </div>

                </div>
            )
        }
        else {
            return (
                <div>
                    {this.props.parent_state.judgement_tags.map((item, index) => {
                        console.log(item);
                        return <TagFilterItem parent_state={this.parent_state} parent_functions={this.props.parent_functions} tag={item} />
                    })}
                </div>


            )
        }
    }


    render() {
        return (
            <div className="sticky top-0 mb-10  py-3  dark:text-gray-100  dark:border-gray-700">
                <div className=" bg-dark:grey-800  mb-3  dark:border-gray-700 ">
                    <div className="dark:text-gray-200 dark:font-semibold text-lg ">
                        Judgement Tags
                    </div>
                    <div className="text-gray-500 text-xs ">
                        Apply Tags to judgements to filter
                    </div>
                </div>

                {this.props.parent_state.applied_tags?.length !=0 ? <div className='flex justify-end text-sm  text-blue-800 items-center '>
                    <div className='flex p-1 rounded items-center '>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>

                        <div onClick={() => this.props.parent_functions.clearAllAppliedTags()} className='text-sm  cursor-pointer'>Clear all</div>
                    </div>

                </div>:''}


                <div>

                    {this.renderContent()}

                </div>

            </div>
        )
    }
}
