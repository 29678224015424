import React, { Component } from 'react'
import { updateAnnotation, removeAnnotation } from '../backend_handlers/AnnotationController';
import { toast } from 'react-toastify';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getAnnotationReference } from '../backend_handlers/referenceController';


export default class AnnotationDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {

            visible: false,
            current_page: 'menu',
            form_data: {},
            x: '',
            y: '',
            annotation: {},
            shown: false,
            is_loading: false
        }
        this.updateAnnotation = updateAnnotation.bind(this);
        this.removeAnnotation = removeAnnotation.bind(this);
    }
    handleUpdate = async () => {

        this.setState({ is_loading: true })
        let response = await this.updateAnnotation(this.state.annotation.id, this.state.form_data.tag, toast)
        if (response) {
            if (response.status == 200) {

                let annotation = response.data;
                annotation.annotation['checked'] = true;
                this.props.parent_functions.updateAnnotation(annotation);

                let tag = this.props.parent_state.tags.find(Obj => Obj.id == annotation.annotation.tag_id);
                tag.expanded = true;
                this.props.parent_functions.updateTag(tag);
            }
        }
        this.hide(true);
    }
    handleRemove() {

        let msg = "Are you sure you want to remove this annotations?'";
        this.props.parent_functions.confirmDialog.current.show(msg, this.deleteConfirmedCallback);

    }
    handleOnPageClicks = (e) => {

        if (this.state.shown && e.target.parentElement) {
            if (e.target.parentElement.getAttribute('name') !== 'annotation_dialog' && e.target.parentElement.getAttribute('name') !== 'tag') {
                this.hide();
            }
        }
        else {
            this.setState({ shown: true })
        }
    }
    deleteConfirmedCallback = async () => {
        console.log(this.state);
        let response = await this.removeAnnotation(this.state.annotation.id, toast);
        if (response) {
            if (response.status == 200) {
                this.props.parent_functions.deleteAnnotation(this.state.annotation.id);
            }
        }
        this.hide(true);
    }
    show = (annotation, x, y) => {
        let { form_data } = this.state;
        form_data['tag'] = annotation.tag_id;
        document.addEventListener('click', this.handleOnPageClicks);
        this.setState({ visible: true, annotation: annotation, x: x, y: y, form_data });
    }
    changeHandler = (e) => {
        let { form_data } = this.state;
        form_data[e.target.name] = e.target.value;
        this.setState({ form_data });
    }
    hide = (force = false) => {

        if (force) {
            document.removeEventListener('click', this.handleOnPageClicks);
            this.setState({ shown: false, visible: false, current_page: 'menu', is_loading: false });
            return;
        }
        if (!this.state.is_loading) {
            document.removeEventListener('click', this.handleOnPageClicks);
            this.setState({ shown: false, visible: false, current_page: 'menu', is_loading: false })
        }
    }
    setCurrentPage = (page) => {
        this.setState({ current_page: page });
    }
    async showReferenceNotes() {
        let ref = this.props.parent_functions.getAnnotationReference(this.state.annotation);
        this.props.parent_functions.referenceViewDialog.current.show(ref);

    }
    renderMenu() {
        return (
            <div className=''>

                {/* <div onClick={() => this.showReferenceNotes()} className='dark:text-gray-100  dark:hover:bg-gray-700 flex text-sm px-3 py-2 hover:bg-gray-200 items-center   hover:rounded-sm' style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-blue-500 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                    </svg>
                    Reference Notes
                </div> */}

                <div onClick={() => this.setState({ current_page: 'change_tag' })} className='dark:text-gray-100  dark:hover:bg-gray-700 flex px-3 py-2 text-sm hover:bg-gray-200 hover:rounded-sm' style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-blue-500 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z" />
                    </svg>
                    Change Tag
                </div>

                <div onClick={() => this.handleRemove()} className='dark:text-gray-100  dark:hover:bg-gray-700 flex text-sm px-3 py-2 hover:bg-gray-200 items-center   hover:rounded-sm' style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-blue-500 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>Remove Annotation
                </div>
            </div>
        )
    }
    renderChangeTag() {
        return (
            <div name="annotation_dialog" className='py-2 px-3'>
                <select name='tag' onChange={this.changeHandler} className={'mt-2 border-gray-300 rounded w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 '}>
                    {this.props.parent_state.tags.map((tag, index) => {
                        return (
                            <option selected={this.state.form_data.tag == tag.id ? true : false} value={tag.id}>{tag.name}</option>
                        );
                    })}
                </select>
                <button onClick={() => this.handleUpdate()} className='bg-blue-500 text-white px-3 py-1.5 my-2 rounded-md text-sm'>Apply</button>
            </div>
        )
    }
    renderCurrentPage = () => {

        if (this.state.is_loading) {
            return (
                <div className='text-center py-5'>
                    <ProgressSpinner style={{ width: '20px', height: '20px' }} />
                    <div className='text-sm'>Processing...</div>
                </div>
            )
        }
        switch (this.state.current_page) {
            case 'menu':
                return this.renderMenu();
                break;
            case 'change_tag':
                return this.renderChangeTag();
                break;
            default:
                break;
        }
    }

    findTag(annotation) {
        let tag = null;
        this.props.parent_state.tags.map((tagItem, index) => {
            tagItem.JudgementAnnotations.map((ann, index) => {
                if (ann.id == annotation.id) {
                    tag = tagItem;
                }
            })
        })
        return tag;

    }
    render() {

        let { visible, annotation, x, y, is_loading } = this.state;
        return (
            <div name="annotation_dialog" className={'dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100 bg-white border shadow-xl   ' + (visible == true ? ' block ' : ' hidden ')} style={{ width: '300px', top: y + 'px', left: x + 'px', position: 'fixed', zIndex: '9000' }}>
                <div name="annotation_dialog">
                    <div name="annotation_dialog" className='bg-gray-200  dark:bg-gray-900 dark:text-gray-100 p-2 flex justify-between'>
                        <div>{annotation.annotation ? annotation.annotation : ''}</div>
                        <button onClick={() => this.hide()}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>

                        </button>
                    </div>


                    {this.renderCurrentPage()}
                </div>

            </div>
        )
    }
}
