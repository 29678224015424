import axios from "axios";

export async function generateOtp(data, toast) {

    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/registrations/generate-otp`, data);
        if (response.status == 200) {
            // toast.success("OTP sent to your mail", {
            //     position: toast.POSITION.TOP_RIGHT
            // });
        }
        return response;
    } catch (error) {
        // toast.error("There was an error sending otp", {
        //     position: toast.POSITION.TOP_RIGHT
        // });
        return error.response.data;
    }

}

export async function verifyOtp(data, toast) {

    try {
        console.log("calling verify otp");
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/registrations/verify-otp`, data);
        // if (response.status == 200) {
        //     toast.success("OTP sent to your mail", {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        // }
       
        return response;
    } catch (error) {
        // toast.error("There was an error sending otp", {
        //     position: toast.POSITION.TOP_RIGHT
        // });
       
        return error.response;
    }

}