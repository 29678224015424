import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import ChooseAccountTypePage from '../sections/auth/register/choose_account_type';
import FieldsForms from '../sections/auth/register/fields_forms';
import EmailVerify from '../sections/auth/register/email_verify';
import ChoosePassword from '../sections/auth/register/choose_password';


export default class RegisterPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current_page: 1,
            form_data: {},
            form_errors: {}
        }
    }
    componentDidMount() {
        document.title = "Register"
    }
    updateFormData = (key, item) => {
        let { form_data } = this.state;
        form_data[key] = item;
        this.setState({ form_data });
    }
    updateFormErrors = (formErrors) => {
        this.setState({ form_errors: formErrors });
    }
    nextPage = (page, type = null) => {
        if (type) {
            let { form_data } = this.state;
            form_data['account_type'] = type;
            this.setState({ current_page: page, form_data: form_data });
        }
        else {
            this.setState({ current_page: page });
        }

    }
    renderWelcomeMessage() {
        return (
            <div className=''>
                <div className='text-md text-gray-500'>Done!, account created</div>

                <div className='border-gray-500 my-5'>
                    Congratulations , {this.state.form_data.name}, your account has been created successfully. Login to start enjoying the service


                </div>
                <div>
                    <button onClick={() => { window.location.href = "/login" }} className='py-1.5 px-2 rounded  bg-blue-700 text-white'>Login to account</button>
                </div>


            </div>
        );
    }
    renderCurrentPage = () => {

        switch (this.state.current_page) {
            case 1:
                return <ChooseAccountTypePage form_data={this.state.form_data} updateFormData={this.updateFormData} nextPage={this.nextPage} />

            case 2:
                return <FieldsForms form_data={this.state.form_data} form_errors={this.state.form_errors} updateFormData={this.updateFormData} updateFormErrors={this.updateFormErrors} nextPage={this.nextPage} />

            case 3:
                return <EmailVerify form_data={this.state.form_data} form_errors={this.state.form_errors} updateFormData={this.updateFormData} updateFormErrors={this.updateFormErrors} nextPage={this.nextPage} />

            case 4:
                return <ChoosePassword form_data={this.state.form_data} form_errors={this.state.form_errors} updateFormData={this.updateFormData} updateFormErrors={this.updateFormErrors} nextPage={this.nextPage} />

            case 5:
                return this.renderWelcomeMessage();

            default:
                break;
        }
    }
    render() {
        return (
            <div className='h-[100vh] flex justify-center items-center'>

                <div className='w-full md:w-1/3 '>
                    <div className='p-5 py-7 rounded-md bg-white dark:bg-gray-900 dark:border-0 rounded-md  mx-2 md:mx-0  border rounded-md'>
                        <div>
                            <a href='/' className='cursor-pointer text-xl font-semibold dark:text-gray-100'>Malawi Law Annotator</a>
                        </div>

                        <div className=''>
                            {this.renderCurrentPage()}

                        </div>
                    </div>
                    <div className='text-center text-gray-500 text-xs mt-5 bottom-0'>&copy;2023 Malawi Law Annotator</div>
                </div>


            </div>
        )
    }
}

document.title = "Register";
