import axios from "axios";

export async function createTag(name, judgement_id, toast) {

    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/tags`, { 'name': name, 'judgement_id': judgement_id });
        toast.success("Tag created successfully", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "toastAvoidsDuplicates1",
        });
        return response;

    } catch (error) {
       
        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates2",
            });
        }
        else {
            toast.error("There was an error while creating a tag", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates3",
            });
        }

        return error.response;
    }

}

export async function deleteTag(tag_id, toast) {

    try {
        let response = await axios.delete(`${process.env.REACT_APP_URL}/api/tags/${tag_id}`);
        toast.success("Tag deleted successfully", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "toastAvoidsDuplicates4",
        });
        return response;

    } catch (error) {

        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error("There was an error while deleting a tag", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates5",
            });
        }
        return error;
    }
}

export async function updateTag(tag_id, name, toast) {

    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/tags/${tag_id}`, { 'name': name });
        toast.success("Tag updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "toastAvoidsDuplicates6",
        });
        return response;

    } catch (error) {

        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates7",
            });
        }
        else {
            toast.error("There was an error while updating a tag", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates8",
            });
        }
        return error.response;
    }
}
export async function clearTag(tag_id, toast) {
    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/tags/clear/${tag_id}`);
        toast.success("Tag cleared successfully", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "toastAvoidsDuplicates9",
        });
        return response;
    } catch (error) {
        console.log(error);
        toast.error("There was an error while clearing tags", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "toastAvoidsDuplicates10",
        });
    }
}

export async function fetchTags(judgement_id, toast) {

    try {
        let response = await axios.get(`${process.env.REACT_APP_URL}/api/judgements/tags/${judgement_id}`);
        return response;
    } catch (error) {
        console.log(error);
        toast.error("There was an error while fetching judgement tags", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "toastAvoidsDuplicates11",
        });
    }
}

