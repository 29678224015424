import React, { Component } from "react";
import { Link } from "react-router-dom";
import AnnotationDialog from "../../../components/annotationDialog";
import NewAnnotationDialog from "../../../components/newAnnotationDialog";
import SearchBar from "./view_page_header/search_bar";
import {
  scrollToParagraph,
  checkAnnotationConflict,
  escapeRegExp,
  isWholeWord,
} from "../../../utils/utils";
import MessageDialog from "../../../components/messageDialog";
import TagConflictDialog from "../../../components/tagConflictDialog";


export default class JudgementViewPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annotation_dialog_visible: false,
      clicked_annotation_text: "",
      clicked_annotation_x: "",
      clicked_annotation_y: "",
      selected_text_x: "",
      selected_text_y: "",
      selected_text: "",
      annotation_id: "",
      new_annotation_dialog_visible: false,
      resultsCount: 0,
      results_counts: {},
      encoded_content_text: "",
      isSearching: false,
      searchFilter: "",
      results_paragraphs: [],
      active_result_paragraph: null,
    };

    this.htmlTextRef = React.createRef();
    this.newAnnotationDialogRef = React.createRef();
    this.annotationDialogRef = React.createRef();
    this.scrollToParagraph = scrollToParagraph.bind(this);
    this.messageDialog = React.createRef();
    this.checkAnnotationConflict = checkAnnotationConflict.bind(this);
    this.tagConflictDialog = React.createRef();
    this.escapeRegExp = escapeRegExp.bind(this);
    this.isWholeWord = isWholeWord.bind(this);
  }
  componentDidMount() {
    this.prepareContent();
  }
  updateSearchState = (value) => {
    this.prepareContent(value);
    if (value) {
      this.setState({ isSearching: true, searchFilter: value });
    } else {
      this.setState({ isSearching: false, searchFilter: "" });
    }
  }
  toggleAnnotationDialog = () => {
    let { annotation_dialog_visible } = this.state;
    this.setState({ annotation_dialog_visible: !annotation_dialog_visible });
  }
  closeAnnotationDialog = () => {
    this.setState({ annotation_dialog_visible: false });
  }
  closeNewAnnotationDialog = () => {
    this.setState({ new_annotation_dialog_visible: false });
  }
  scrollToNextOccourence = () => {
    let { results_paragraphs, active_result_paragraph } = this.state;

    results_paragraphs.map((item, index) => {
      if (item == active_result_paragraph) {
        if (index != results_paragraphs.length - 1) {
          this.scrollToParagraph(results_paragraphs[index + 1]);
          this.setState({
            active_result_paragraph: results_paragraphs[index + 1],
          });
        }
      }
    });
  }
  scrollToPrevOccurence = () => {
    let { results_paragraphs, active_result_paragraph } = this.state;

    results_paragraphs.map((item, index) => {
      if (item == active_result_paragraph) {
        if (index != 0) {
          this.scrollToParagraph(results_paragraphs[index - 1]);
          console.log(results_paragraphs[index - 1]);
          this.setState({
            active_result_paragraph: results_paragraphs[index - 1],
          });
        }
      }
    });
  }
  handleOnBack = (e) => {
    window.location.href = "/judgements";
  }
  renderHeader() {
    let judgement = this.props.parent_state.judgement;
    return (
      <div className="sticky top-0 py-2 w-full  border-b  pb-2 mb-2 px-4  bg-white dark:bg-gray-900 dark:border-b-gray-800">
        <div className=" w-full   sm:flex justify-between items-center">
          <div className="sm:w-3/5 w-full">
            <div className="flex   items-center ">
              <div className="mr-2">
                <button
                  onClick={(e) => this.handleOnBack(e)}
                  className="dark:text-white border dark:border-gray-700 rounded-full p-1 dark:hover:bg-gray-900 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                    />
                  </svg>
                </button>
              </div>

              <div>
                <div className="dark:text-gray-200 text-xl font-bold">
                  {judgement.titleStmt_main}
                </div>
                {/* <div className="text-gray-500 text-sm">
                  {judgement.titleStmt_sub}
                </div> */}
              </div>
            </div>
          </div>
          <div className="w-2/5  hidden  sm:block sm:my-0  ">
            <SearchBar
              parent_state={this.state}
              scrollToPrevOccurence={this.scrollToPrevOccurence}
              scrollToNextOccourence={this.scrollToNextOccourence}
              updateSearchState={this.updateSearchState}
            />
          </div>
        </div>
      </div>
    );
  }
  showAnnotationReference = async (annotation) => {
    let ref = this.props.parent_functions.getAnnotationReference(annotation);
    this.props.parent_functions.referenceViewDialog.current.show(ref);
  };
  handleAnnotationClick = (event) => {

    let name = event.target.getAttribute("name");
    let authData = this.props.parent_state.user;

    if (name == "annotation_item") {

      let annotation = this.props.parent_state.annotation_metadata.find(Obj => Obj.annotation.id == event.target.id);

      if (authData) {
        this.annotationDialogRef.current.show(
          annotation.annotation,
          event.clientX,
          event.clientY
        );
      }

    }
  }
  initNewAnnotation = (selectedText, paragraph, x, y) => {
    this.newAnnotationDialogRef.current.show(selectedText, paragraph, x, y);
  }
  handleParagraphClick = (paragraph, e) => {
    //only show if in notes mode
    if (this.props.parent_state.current_side_control == "notes") {
      let strLen = window.getSelection().toString().length;

      if (strLen == 0) {
        if (e.target.getAttribute("name") != "annotation_item") {
          this.props.parent_functions.activateParagraph(paragraph);
        }
      }
    }
  }

  handleTextSelection = async (e, paragraph) => {
    let authData = this.props.parent_state.user;
    let strLen = window.getSelection().toString().length;

    if (authData) {
      if (authData.role != "regular-account") {

        if (window.getSelection() && strLen !== 0) {

          var selectedText = window.getSelection();

          if (selectedText.toString().length <= 1) {
            this.setState({ new_annotation_dialog_visible: false });
          } else {

            let isFullWord = isWholeWord(
              window.getSelection().toString(),
              this.props.parent_state.judgement.Paragraphs
            );

            if (isFullWord) {
              let conflictResult = this.checkAnnotationConflict(
                selectedText.toString().trim(),
                this.props.parent_state.tags,
                this.props.parent_state.annotation_metadata
              );

              if (conflictResult.isConflict) {
                if (conflictResult.conflict_type === "equals") {
                  this.messageDialog.current.show(
                    "The selected text is aleardy annotated as " +
                    conflictResult.tag.name
                  );
                } else if (conflictResult.conflict_type === "includes") {
                  this.tagConflictDialog.current.show(
                    conflictResult,
                    selectedText.toString().trim(),
                    paragraph,
                    e
                  );
                } else if (conflictResult.conflict_type === "included") {
                  this.props.parent_functions.confirmDialog.current.show(
                    "The selected text is included in the annotation '" +
                    conflictResult.matches[0].annotation.trim() +
                    "'. Would you like to split the existing annotation?",
                    this.annotationMergConfirmedCallBack
                  );
                }
              } else {
                this.initNewAnnotation(
                  selectedText,
                  paragraph,
                  e.clientX,
                  e.clientY
                );
              }
            } else {
              this.messageDialog.current.show(
                "Can not annotate partial words. Highlight whole word to annotate "
              );
            }
          }
        }
      }
    }
  };
  annotationMergConfirmedCallBack = () => {
    this.newAnnotationDialogRef.current.setIsMerging(
      true,
      this.state.conflictedAnnotation
    );
    this.newAnnotationDialogRef.current.show(
      this.state.selectedText,
      this.state.paragraph,
      this.state.x,
      this.state.y
    );
  };
  addMarkups = (data, paragraph, highlighted, searchQuery = null) => {
    //add markups to highlihted texts
    highlighted.map((item, index) => {

      var regex = new RegExp(
        "\\b" + this.escapeRegExp(item.annotation.annotation) + "\\b",
        "gi"
      );
      let color = this.props.parent_functions.getTagColor(item.annotation)
      data = data.replace(regex, function (match) {
        return (
          '<span  id="' +
          item.annotation.id +
          '"   name="annotation_item" style="border-bottom: 2px solid ' +
          color +
          "; cursor:pointer; color: " +
          color +
          '" class="p-0.5 pt-0  text-center inline:flex items-center  ">' +
          match +
          "</span>"
        );
      });
    });

    // add markups to search data
    let resultsCount = 0;
    if (searchQuery && !Array.isArray(searchQuery)) {
      var regex = new RegExp(this.escapeRegExp(searchQuery), "gi");
      data = data.replace(regex, function (match) {
        resultsCount++;
        return (
          '<span  class="  px-0.5 underline bg-blue-500 pt-0  text-center inline:flex items-center text-white py-0.5 ">' +
          match +
          "</span>"
        );
      });
    }
    let results_counts = {};
    results_counts[paragraph] = resultsCount;

    var html_data = { __html: data };

    return {
      data: (
        <div
          onClick={(e) => this.handleAnnotationClick(e)}
          className=""
          dangerouslySetInnerHTML={html_data}
        ></div>
      ),
      results_counts: results_counts,
    };
  };
  prepareContent = (
    searchQuery = null,
    highlighted = this.props.parent_state.highlighted
  ) => {
    let paragraph_items = this.props.parent_state.judgement.Paragraphs;
    let results_counts = {};
    let content = null;
    let results_paragraphs = [];
    let active_result_paragraph = null;
    highlighted = this.props.parent_state.annotation_metadata.filter(Obj => Obj.annotation.checked);

    if (paragraph_items) {
      content = (
        <div className="dark:text-gray-200 my-5  rounded-md  shadow-sm">
          {paragraph_items.map((item, index) => {
            let marker_results = this.addMarkups(
              item["parapgraph_content"],
              item["paragraph_number"],
              highlighted,
              searchQuery
            );
            results_counts[item["paragraph_number"]] =
              marker_results.results_counts;

            return (
              <div
                onClick={(e) => this.handleParagraphClick(item, e)}
                name={"paragraph_item cursor-text"}
                onMouseUp={(e) =>
                  this.handleTextSelection(e, item["paragraph_number"])
                }
                id={"paragraph-" + item["paragraph_number"]}
                className={"mb-5 text-base font-sans  flex justify-between "}
              >
                <div className="flex justify-start  items-top">
                  {(item.Notes?.length != 0 &&
                    this.props.parent_state.current_side_control) == "notes" ? (
                    <div className="mt-1.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 text-blue-500 mr-1 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                        />
                      </svg>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className={
                      item.Notes?.length != 0 &&
                        this.props.parent_state.current_side_control == "notes"
                        ? ""
                        : "ml-5 "
                    }
                  >
                    {marker_results.data}{" "}
                  </div>
                </div>

                <div className="cursor-pointer text-sm pl-2.5 p-1 ms-2 text-gray-500">
                  {item["paragraph_number"]}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    //set search results data
    Object.keys(results_counts).map((item, index) => {
      if (parseInt(results_counts[item][item]) > 0) {
        results_paragraphs.push(parseInt(item));
      }
    });

    //navigate to first occurence of result
    if (results_paragraphs.length > 0) {
      this.scrollToParagraph(results_paragraphs[0]);
      active_result_paragraph = results_paragraphs[0];
    }

    this.setState({
      encoded_content_text: content,
      results_counts: results_counts,
      results_paragraphs: results_paragraphs,
      active_result_paragraph: active_result_paragraph,
    });
  };

  renderFrontPage = () => {
    let judgement = this.props.parent_state.judgement;
    return (
      <div className="my-2 px-5">

        <div className="text-center">

          <div className="my-5">
            <div className="text-xl font-bold ">{judgement.titleStmt_main}</div>
            <div className="text-gray-500">{judgement.titleStmt_sub}</div>
          </div>
          <div>
            <div className="font-bold text-lg ">Case Information</div>
            <div>
              {judgement.JudgementTexts?.map((item, index) => {
                if (item.type === 'caseinfo') {
                  return <div className="text-gray-500 ">{item.item}</div>
                }
              })}


            </div>
          </div>

          <div className="my-5">
            <div className="font-bold text-lg">Case Parties</div>
            {judgement.JudgementTexts?.map((item, index) => {
              if (item.type === 'parties') {
                return <div className="text-gray-500 ">{item.item}</div>
              }
            })}

          </div>


          <div className="my-5">
            <div className="font-bold text-lg">Coram</div>
            {judgement.JudgementTexts?.map((item, index) => {
              if (item.type === 'coram') {
                return <div className="text-gray-500 ">{item.item}</div>
              }
            })}

          </div>



        </div>

      </div>
    );
  }
  render() {

    return (
      <div className="w-full top-0 ">
        {this.renderHeader()}

        {this.renderFrontPage()}

        <div>{this.state.encoded_content_text}</div>
        <NewAnnotationDialog
          ref={this.newAnnotationDialogRef}
          parent_state={this.props.parent_state}
          parent_functions={this.props.parent_functions}
          selectedParagraph={this.state.selectedParagraph}
          selectedText={this.state.selected_text}
        />
        <AnnotationDialog
          ref={this.annotationDialogRef}
          parent_functions={this.props.parent_functions}
          parent_state={this.props.parent_state}
        />
        <MessageDialog ref={this.messageDialog} />
        <TagConflictDialog
          initNewAnnotation={this.initNewAnnotation}
          parent_functions={this.props.parent_functions}
          parent_state={this.props.parent_state}
          ref={this.tagConflictDialog}
        />
      </div>
    );
  }
}
