import axios from "axios";

export async function createJudgementTag(form_data, toast) {

    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/judgementtags`, form_data);
        toast.success("Tag created successfully", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "toastAvoidsDuplicates1",
        });
        return response;

    } catch (error) {

        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates2",
            });
        }
        else {
            toast.error("There was an error while creating a tag", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates3",
            });
        }

        return error.response;
    }

}
export async function fetchJudgementTags(toast) {

    try {
        let response = await axios.get(`${process.env.REACT_APP_URL}/api/judgementtags`);
        return response;
    } catch (error) {
        console.log(error);
        toast.error("There was an error while fetching judgement tags", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "toastAvoidsDuplicates11",
        });
    }
}

