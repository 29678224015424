import React, { Component } from "react";
import TagEditDialog from "./tagEditDialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AnnotationDialog from "./annotationDialog";

export default class TagItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
    this.annotationDialogRef = React.createRef();
  }
  toggleTagItemsView = () => {
    let tag = this.props.tag;
    tag.expanded = !tag.expanded;
    this.props.parent_functions.updateTag(tag);
  }
  showEditDialog = (event, tag) => {
    if (event !== null) {
      this.props.showEditDialog(event.clientX, event.clientY, tag);
    }
  }
  highlightAll = (e) => {

    if (e.target.checked == true) {
      this.props.parent_functions.activateAllAnnotations(this.props.tag);

    } else {
      this.props.parent_functions.deactivateAllAnnotations(this.props.tag);
    }


  }
  highlightiterChangeHandler = async (event, annotation) => {

    if (event.target.checked == true) {
      this.props.parent_functions.toggleHighlight(annotation)
      this.scrollToParagraph(annotation.paragraph);
    } else {
      this.props.parent_functions.toggleHighlight(annotation)
    }
  }

  renderHeader() {
    return (
      <div className="flex  justify-between mb-2 items-center dark:border-gray-700">
        <div className="flex justify-start">
          <button
            className="dark:text-white"
            onClick={() => this.toggleTagItemsView()}
          >
            {this.props.tag.expanded ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            )}
          </button>
          <div className="dark:text-gray-200 font-semibold text-sm  ms-2">
            {this.props.tag.name} ({(this.props.parent_state.annotation_metadata.filter(Obj => Obj.annotation.tag_id == this.props.tag.id)).length}){" "}
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex  items-center  ">
            <div>
              <div
                style={{ backgroundColor: `${this.props.tag.color}` }}
                className={"p-2 mt-1 rounded-full"}
              ></div>
            </div>

            <div> <div><input style={{ color: this.props.tag.color }} onChange={this.highlightAll} className="border-gray-300 rounded p-1 ml-1" type="checkbox" /> </div></div>

          </div>

          {this.props.parent_state.user?.role != "regular-account" ? (
            <button
              onClick={(e) => this.showEditDialog(e, this.props.tag)}
              className="hover:bg-gray-200 text-gray-500 rounded-md "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                />
              </svg>
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
  renderAnnotationMetadata(annotation) {
    return (
      <div className="text-sm text-gray-500 ">
        <div className="border-b  dark:border-b-gray-700 w-full border-gray-200 py-2 mb-2">
          <div className="">All occurences</div>
          <div className="text-blue-700 dark:text-blue-500">
            {this.props.parent_state.annotation_metadata.map((item, index) => {
              if (item.annotation.id == annotation.id) {
                return item.total_occurences;
              }
            })}
          </div>
        </div>

        <div className="border-b dark:border-b-gray-700 w-full border-gray-200 py-2">
          <div className="">Paragraphs</div>
          <div className="flex flex-wrap">
            {this.props.parent_state.annotation_metadata.map((item, index) => {
              if (item.annotation.id == annotation.id) {
                return item.paragraphs.map((occurence, index) => {
                  return (
                    <div
                      onClick={() =>
                        this.scrollToParagraph(occurence.paragraph)
                      }
                      className="text-blue-700 dark:text-blue-500 pr-2 cursor-pointer"
                    >
                      {occurence.paragraph},
                    </div>
                  );
                });
              }
            })}
          </div>
        </div>

        <div className="border-b  dark:border-b-gray-700 w-full border-gray-200 py-2 mb-2">
          <div className="flex justify-between items-center">
            <div>
              <div>Cross References</div>
              <div className="text-blue-700 dark:text-blue-500">
                {this.props.parent_state.annotation_metadata.map(
                  (item, index) => {
                    if (item.annotation.id == annotation.id) {
                      return item.cross_references?.length;
                    }
                  }
                )}
              </div>
            </div>
            <div>
              <svg
                onClick={() => this.props.parent_functions.switchSideControl("references", annotation)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 cursor-pointer"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderBody() {
    let { formdata } = this.state;

    return (

      <div className="">
        {this.props.parent_state.annotation_metadata.filter(Obj => Obj.annotation.tag_id == this.props.tag.id).map((metaItem, index) => {


          return (
            <div
              ref={this.annotation_ref}
              className="my-2 py-2 dark:border-gray-700 "
            >
              <div className="bg-gray-300 dark:bg-gray-800 p-1 flex justify-between items-center">
                <div>
                  <div className="dark:text-gray-200 text-sm font-semibold text-md ">
                    {" "}
                    <span></span>
                    {index + 1}. {metaItem.annotation["annotation"]}
                  </div>
                </div>
                <div className="flex items-center">
                  <input
                    style={{ color: this.props.tag.color }}
                    name={metaItem.annotation.id}
                    checked={metaItem.annotation.checked ? true : false}
                    onChange={(event) =>
                      this.highlightiterChangeHandler(event, metaItem.annotation)
                    }
                    type="checkbox"
                    class="w-4 h-4  text-yellow-500 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <button
                    onClick={(e) => this.annotationDialogRef.current.show(metaItem.annotation, (e.clientX - 300), e.clientY)}
                    className="hover:bg-gray-200 text-gray-500 rounded-md "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                      />
                    </svg>
                  </button>
                </div>

              </div>

              <div>{this.renderAnnotationMetadata(metaItem.annotation)}</div>
            </div>
          );
        })}
      </div>
    );
  }
  scrollToParagraph = async (paragraph) => {
    if (paragraph == 1) paragraph = 2;

    if (paragraph == 2) paragraph = 3;

    let element = document.getElementById(
      "paragraph-" + (parseInt(paragraph) - 1)
    );
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  render() {
    return (
      <div>
        <div
          className={
            "mt-3 border-gray-300 dark:border-gray-700 p-2 w-full " +
            (this.props.tag.expanded ? " " : "dark:bg-gray-900  border rounded-md")
          }
        >
          {this.renderHeader()}
          {this.props.tag.expanded ? this.renderBody() : ""}
        </div>
        <AnnotationDialog ref={this.annotationDialogRef} parent_state={this.props.parent_state} parent_functions={this.props.parent_functions} />
      </div>
    );
  }
}
