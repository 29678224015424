import React, { Component, createRef } from 'react'

export default class UserModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_visible: false,
            user: {}
        }

    }
    show = (user) => {
        this.setState({ is_visible: true, user: user });
    }
    hide = () => {
        this.setState({ is_visible: false, user: {} });
    }
    confirmApprove() {
        this.setState({ is_visible: false });
        this.props.confirmApprove(this.state.user);
    }
    confirmRevoke() {
        this.setState({ is_visible: false });
        this.props.confirmRevoke(this.state.user);
    }
    renderAppropriateButton() {
        if (this.state.user.role == 'annotator-pending') {
            return (
                <button onClick={() => this.confirmApprove()} className='bg-blue-600 text-gray-100 shadow-sm py-1 px-2 flex items-center pb-2'>
                    Approve annotator
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5  ml-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                </button>
            );
        }
        else if (this.state.user.role == 'annotator') {
            return (<button onClick={() => this.confirmRevoke()} className='bg-red-600 text-gray-100 shadow-sm py-1 px-2 flex items-center pb-2'>
                Revoke annotator rights
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>

            </button>
            )
        }
    }
    render() {
        return (
            <div name="user_profile_dialog" className={'bg-white dark:bg-gray-900 shadow-lg border dark:border-gray-800 w-4/5  sm:w-1/3' + (this.state.is_visible == true ? ' block ' : ' hidden ')} style={{ zIndex: '9000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}  >
                <div className='dark:bg-gray-800 px-3 dark:border-gray-700 bg-gray-200 p-2 flex justify-between items-center items-center '>

                    <div className='flex items-center'>
                        <div className='text-blue-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>
                        </div>
                        <div className='ml-2 dark:text-gray-200'>
                            <div >{this.state.user.name}</div>
                            <div className='text-gray-500'>{this.state.user.email}</div>
                        </div>
                    </div>
                    <div onClick={() => this.hide()} className='text-gray-500' style={{ cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>

                    </div>



                </div>

                <div className='px-5 py-3 dark:text-gray-100'>

                    <div className='sm:flex'>
                        <div className='w-full sm:w-1/2'>
                            <div className='text-gray-500'>Name</div>
                            <div>{this.state.user.name}</div>
                        </div>

                        <div className=' w-full sm:w-1/2'>
                            <div className='text-gray-500'>Role</div>
                            <div>{this.state.user.role}</div>
                        </div>

                    </div>

                    <div className='sm:flex mt-2 '>
                        <div className=' w-full sm:w-1/2'>
                            <div className='text-gray-500'>Email</div>
                            <div>{this.state.user.email}</div>
                        </div>

                        <div className=' w-full sm:w-1/2'>
                            <div className='text-gray-500'>Phone</div>
                            <div>{this.state.user.phone}</div>
                        </div>
                    </div>

                    <div className='sm:flex mt-2'>
                        <div className='w-full sm:w-1/2'>
                            <div className='text-gray-500'>Country Code</div>
                            <div>{this.state.user.country_code}</div>
                        </div>

                        <div className='w-full sm:w-1/2'>
                            <div className='text-gray-500'>Registered Date</div>
                            <div>{this.state.user.createdAt}</div>
                        </div>

                    </div>
                </div>

                <div className={'px-5 my-2 mb-5'}>
                    {this.renderAppropriateButton()}
                </div>


            </div>
        )
    }
}
