import { Component } from "react"
import Page from "../../components/page"
import { Link } from "react-router-dom"
import Footer from "../../components/footer"

export default class IndexPage extends Page {



    componentDidMount() {
        document.title = "Home"
    }

    renderLeaveUsAmessageSection() {
        return (
            <div className="py-20 px-3  sm:px-20 bg-gray-100  flex justify-center  dark:bg-gray-900">

            </div>
        )
    }
    renderHowItWorksSection() {
        return (
            <div className="px-3 py-10 sm:py-20 sm:px-20 bg-white  dark:bg-gray-800">

                <div className="text-start">
                    <div className="text-2xl dark:text-gray-100 text-slate-700 font-bold">
                        Hundreds+  Annotated Cases
                    </div>


                </div>
                <div class="text-start text-gray-600 dark:text-gray-400 my-2 text-md ">
                    We have annotated over 600 judgements from the Criminal High Courts in Malawi. This tools allows you to browse these judgements and their annotations. We are in the process of extending this to other types of judgements.
                </div>

            </div>
        )
    }
    renderWhoCanUse() {
        return (<div className="py-20 px-3  sm:px-20 bg-gray-100  flex justify-center  dark:bg-gray-900">

            <div className="">

                <div className="">
                    <div className="font-bold text-2xl text-slate-700 dark:text-gray-200">Who Should Use the Annotator?</div>
                    <div className="text-gray-500">The Malawi Law Annotator is designed for legal researchers, students, law practitioners but also for the general public. You can view, search and navigate between cases, laws and commentaries. Registered users can also add
                        their own notes and annotations to judgements.</div>
                </div>
                <div className="grid sm:grid-cols-3 gap-4 my-3">

                    <div className="dark:bg-gray-800 dark:border-0 w-200 border bg-white p-5 shadow-md rounded-md hover:shadow-lg ">
                        <div><img className="text-blue-500" src={'/researcher.png'} style={{ width: '50px', height: '50px' }}></img></div>
                        <div className="font-semibold text-lg text-slate-800 dark:text-gray-200">Researchers</div>
                        <div className="text-gray-500">
                            Find cases of public interest and learn about how the Malawi law was applied.
                        </div>
                    </div>
                    <div className="dark:bg-gray-800 dark:border-0 w-200 border bg-white p-5 shadow-md rounded-md hover:shadow-lg ">
                        <div><img className="text-blue-500" src={'/student.png'} style={{ width: '50px', height: '50px' }}></img></div>
                        <div className="font-semibold text-lg text-slate-800 dark:text-gray-100">Law Students</div>
                        <div className="text-gray-500">
                            The tools allows you to add annotations to the legal text, link pieces of legislation and learn how case law came in effect for various judgements.
                        </div>
                    </div>
                    <div className=" dark:bg-gray-800 dark:border-0 w-200 border bg-white p-5 shadow-md rounded-md hover:shadow-lg ">
                        <div><img className="text-blue-500" src={'/lawyer.png'} style={{ width: '50px', height: '50px' }}></img></div>
                        <div className="font-semibold text-lg text-slate-800 dark:text-gray-200">Legal practitioners</div>
                        <div className="text-gray-500">
                            Contribute to this tool as a registered annotator and add  your legal insights as comments on the text of the judgements.
                        </div>
                    </div>
                </div>
            </div>

        </div>)
    }
    renderRelatedPublications() {
        return (
            <div className="px-3 sm:px-20 py-10 dark:bg-gray-800 bg-white  items-center">

                <div className="w-full mb-2 sm:mb-0 ">
                    <div className="font-bold text-xl sm:text-2xl text-slate-700 dark:text-gray-200 dark:text-gray-10 ">Related Publications</div>
                    <div className="text-gray-500">Here are some papers and news articles related to this project</div>
                </div>



                <div className="w-full mt-5 ">

                    <div className="border border-gray-300 p-2 pb-2 sm:p-3   border-gray-100 sm:dark:border-gray-700 sm:border-gray-200 sm:border  ">
                        <div className="sm:flex justify-between items-center">
                            <a target="_blank" href="https://link.springer.com/article/10.1007/s10506-021-09303-6" style={{ cursor: "pointer" }} className="dark:text-gray-300 text-slate-700 text-md font-semibold">Short paper that appeared in Springer Artificial Intelligence and Law journal, volume 31, pages 1 to 11, 2023</a>
                            <div className="text-slate-700 sm:font-bold text-sm">springer.ai.and.law</div>
                        </div>
                        <div className="mb-2 ">
                            <div className="text-gray-500  text-sm my-1 mb-3">Towards a machine understanding of Malawi legal text.</div>
                            <a target="_blank" href="https://link.springer.com/article/10.1007/s10506-021-09303-6" className="p-2  px-4 bg-blue-500 text-sm text-white ">
                                Read
                            </a>
                        </div>

                    </div>
                    <div className="border border-gray-300 p-2 pb-2 sm:p-3 mt-1  border-gray-300 sm:dark:border-gray-700 sm:border-gray-200 sm:border  ">
                        <div className="sm:flex justify-between items-center">
                            <a target="_blank" href="https://africa.ai4d.ai/blog/extracting-meta-data-from-malawi-court-judgments/" style={{ cursor: "pointer" }} className="dark:text-gray-300 text-slate-700 text-md font-semibold">How this project started</a>
                            <div className="text-slate-700 sm:font-bold text-sm">africa.ai4d.ai</div>
                        </div>
                        <div className="mb-2 ">
                            <div className="text-gray-500  text-sm my-1 mb-3">A small AI4D grant allowed us to embark on a pilot-research. We have set the task to develop semi-automatic methods for extracting key information from criminal cases issued by courts in Malawi.</div>
                            <a target="_blank" href="https://africa.ai4d.ai/blog/extracting-meta-data-from-malawi-court-judgments/" className="p-2  px-4 bg-blue-500 text-sm text-white ">
                                Read
                            </a>
                        </div>

                    </div>
                    <div className="border border-gray-300 p-2  pb-2 mt-1 sm:p-3 border-gray-300 sm:dark:border-gray-700 sm:border-gray-200 sm:border  ">
                        <div className="sm:flex justify-between">
                            <a target="_blank" href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3796171" style={{ cursor: "pointer" }} className="dark:text-gray-200 text-slate-700 font-semibold text-md">Longer paper detailing our objectives and methodology for annotating court judgements. </a>
                            <div className="text-slate-700 sm:font-bold text-sm">SSRN</div>
                        </div>
                        <div className="text-gray-500 mb-2">
                            <div className="text-gray-500"></div>
                            <div className="text-sm my-1 mb-3">Legal professionals in Malawi find themselves having to rely on a limited number of textbooks, outdated law reports, and inadequate library services......</div>
                            <a target="_blank" href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3796171" className="p-2  px-4 bg-blue-500 text-sm text-white ">
                                Read
                            </a>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
    renderAdvertImage() {
        return (
            <div class="min-h-[80vh]  bg-secondary  bg-no-repeat bg-cover " style={{ backgroundImage: "url(/landing_image.jpg)", backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(/landing_image.jpg)' }}>
                <div class="bg-primary bg-opacity-50 h-full min-h-[80vh] flex justify-center items-center">
                    <div class="text-white text-center">
                        <div class="text-4xl font-bold">Why Register</div>
                        <div class="my-2 mx-5">This is placeholder text. Maybe here we can describe that the registered user can have some access rights not avaiable to other users. Or rather state that only subscribed users can access content </div>


                        <button class="btn btn-secondary btn-md my-2 rounded-full text-white"> Join Us Today</button>
                    </div>
                </div>

            </div>
        );
    }
    renderMainScreen() {

        return (
            <div class="h-[60vh] sm:h-[70vh] bg-black bg-no-repeat bg-cover"
                style={{ backgroundImage: "url(/landing_image.jpg)", backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(/landing_image.jpg)' }}>
                <div className=" h-full flex items-center justify-start">
                    <div className="bg-black bg-opacity-50 mx-2 px-4 sm:mx-20 py-10 sm:p-10 mx-2 rounded-lg text-start">
                        <div className="text-3xl font-bold text-white mb-2">Malawi Law Annotator</div>
                        <div className="text-white ">
                            The Malawi Law Annotator is a repository of Malawi court judgements annotated with references to Laws and other cases. Most Malawi court judgements that are available online are in image form, are unstructured and contain no legal meta-data, summaries, keynotes. Hence they do not support a system of citation that is essential to legal research. The aim of this project is to enable  intelligent annotation of court judgements so that judgements are linked and analysed.
                        </div>

                        <div className="mt-5  flex justify-start">
                            <Link to={'/login'}> <button className="bg-blue-500 text-white btn btn-sm p-2 px-10 rounded-md flex">Get Started

                            </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>)

    }

    renderBody() {
        return (

            <div>
                {this.renderMainScreen()}

                {this.renderHowItWorksSection()}

                {this.renderWhoCanUse()}

                {this.renderRelatedPublications()}


            </div>


        )
    }

}

document.title = "Home"


