export function scrollToParagraph(paragraph) {

    if (paragraph == 1)
        paragraph = 2;

    if (paragraph == 2)
        paragraph = 3;

    let element = document.getElementById('paragraph-' + (parseInt(paragraph) - 1));

    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}
export function checkAnnotationConflict(selectedText, tags, annotations) {

    let result = { isConflict: false };
    let check_result = isEquality(selectedText, tags, annotations);
    if (check_result[0]) {
        result = check_result[1];
        return result;
    }

    check_result = isFullyIncludes(selectedText, tags, annotations);
    if (check_result[0]) {
        result = check_result[1];
        console.log(result);
        return result;
    }

    return result;


}
function isEquality(selectedText, tags, annotations) {

    let found = false;
    let isLooping = true;
    let result = null;
    let length = annotations.length;

    for (let i = 0; i < length; i++) {

        if (!isLooping) {
            break;
        }

        let annotation = annotations[i].annotation;

        if (selectedText === annotation.annotation.trim()) {

            let tag = tags.find(Obj => Obj.id == annotation.tag_id);
            result = { isConflict: true, conflict_type: 'equals', matches: [annotation], tag: tag }
            found = true;
            isLooping = false;
            break;
        }


    }
    return [found, result];

}
function isFullyIncludes(selectedText, tags, annotations) {

    let found = false;
    let result = null;
    let length = annotations.length;

    let matches = [];
    for (let i = 0; i < length; i++) {

        let annotation = annotations[i].annotation;

        if (selectedText.toLowerCase().includes(annotation.annotation.trim().toLowerCase())) {
            matches.push(annotation);
        }

    }


    if (matches.length != 0) {
        result = { isConflict: true, conflict_type: 'includes', matches: matches }
        found = true;
    }
    return [found, result];
}

export function getInitials(name) {
    var parts = name.split(' ')
    var initials = ''
    for (var i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== '') {
            initials += parts[i][0]
        }
    }
    return initials
}

export function getDaysDifference(date) {
    var today = new Date();
    var date1 = new Date(date);
    var Difference_In_Time = today.getTime() - date1.getTime();
    return Difference_In_Time / (1000 * 3600 * 24);


}
export function getDaysDifferenceString(date) {
    var diff = getDaysDifference(date);

    if (diff < 1) {
        return " today "
    }
    else if (diff.toFixed(0) == 1) {
        return " yesterday "
    }
    else {
        return diff.toFixed(0) + " days ago";
    }
}
export function isWholeWord(text, paragraphs) {

    let count = 0;
    paragraphs.map((item, index) => {

        var regex = new RegExp("\\b" + escapeRegExp(text.trim()) + "\\b", "gi");
        let result = item.parapgraph_content.match(regex);


        if (result?.length > 0) {
            count = result.length;
        }

    })

    if (count > 0) {
        return true;
    }
    else {
        return false;
    }

}
export function escapeRegExp(text) {

    if (text) {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }


}
export function isInJudgementTexts(judgementTexts, item) {

    if (item?.trim() == '') {
        return false;
    }
    for (var index = 0; index < judgementTexts?.length; index++) {


        if (judgementTexts[index].item.trim().toLowerCase().includes(item?.trim().toLowerCase())) {
            return true;
        }
    }

    return false
}
export function getAnnotationCsv(parent_state) {

    let data = [];

    parent_state.annotation_metadata.forEach(element => {
        let annoInstance = { judgement_id: parent_state.judgement.file_id }
        let paragraphs = [];
        element.paragraphs.forEach(paragraph => {
            let tag = parent_state.tags.find(Obj => Obj.id == element.annotation.tag_id);
            let tag_text = '{""start"":0,""end"":0,""label"": ""' + tag.name + '""}';
            annoInstance = { judgement_id: parent_state.judgement.file_id, annotator: "sys", paragraph: paragraph.paragraph, annotation: element.annotation.annotation, tag: `${tag_text}` }
            data.push(annoInstance);
        })

    });

    return data;

}
