import React, { Component, createRef } from "react";
import TagItem from "../../../components/tag_item";
import { ScrollPanel } from "primereact/scrollpanel";
import NewTagDialog from "../../../components/newTagDialog";
import "react-toastify/dist/ReactToastify.css";
import { ProgressSpinner } from "primereact/progressspinner";
import TagEditDialog from "../../../components/tagEditDialog";
import { uploadAnnotationFile } from "../../../backend_handlers/AnnotationController";
import { getAnnotationCsv } from "../../../utils/utils";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import CsvDownloader from 'react-csv-downloader';

export default class JudgementTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_tag_dialog_visible: false,
      is_loading: false,
      tag_edit_dialog_visible: false,
      clickedX: 0,
      clickedY: 0,
      selectedTag: "",
      selectedParagraph: {},
      current_page: "tags",
      authData: JSON.parse(localStorage.getItem("authData")),
      selected_annotatin: {},
      user: this.props.parent_state.user,
    };
    this.tagEditDialogRef = createRef();
    this.newTagDialogRef = createRef();
    this.uploadAnnotationFile = uploadAnnotationFile.bind(this);
    this.annotationFileChooserRef = createRef();
    this.paragraphNoteRef = createRef();
    this.getAnnotationCsv = getAnnotationCsv.bind(this);
  }

  fileChangeHandler = (event) => {
    if (event.target.files[0]) {
      let formData = new FormData();
      console.log(event.target.files[0]);
      formData.append("annotation", event.target.files[0]);
      formData.append("annotation_id", "tets string here");
      this.uploadAnnotationFile(formData, toast);
    }
  };

  showEditDialog = (x, y, tag) => {
    this.tagEditDialogRef.current.show(x, y, tag);
  };
  handleAnnotationFileUpload = (e) => {
    this.annotationFileChooserRef.current.click();
  };
  closeEditDialog = () => {
    this.setState({ tag_edit_dialog_visible: false });
  };
  createNewTag = (e) => {
    this.newTagDialogRef.current.show(this.props.parent_state.judgement);
  };
  toggleDialog = () => {
    let { new_tag_dialog_visible } = this.state;
    this.setState({ new_tag_dialog_visible: !new_tag_dialog_visible });
  };
  renderAnnotations() {
    let tags = this.props.parent_state.tags;

    if (tags.length != 0) {
      return tags.map((tag, index) => {
        return (
          <TagItem
            switchPage={this.switchPage}
            parent_functions={this.props.parent_functions}
            parent_state={this.props.parent_state}
            showEditDialog={this.showEditDialog}
            tag={tag}
          />
        );
      });
    } else {
      return (
        <div className="w-full my-5 text-center p-10 rounded-md border text-gray-500">
          No Tags found
        </div>
      );
    }
  }
  handleAnnotationDownload = () => {
    this.downloadAnnotation(this.props.parent_state);
  };
  getCsvParsedAnnotations() {
    return this.getAnnotationCsv(this.props.parent_state);
  }
  renderCreateButton() {
    if (this.props.parent_state.user) {
      if (this.props.parent_state.user != "regular-account") {
        return (
          <button
            name="create_tag_btn "
            onClick={() => this.createNewTag()}
            className="hover:bg-gray-400  bg-gray-200 text-gray-900 rounded-md px-2 py-1 text-sm flex dark:bg-gray-900  dark:text-gray-100 dark:border-gray-700 dark:hover:bg-gray-800"
          >
            <svg
              name="create_tag_btn"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 mx-1"
            >
              <path
                name="create_tag_btn"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </button>
        );
      }
    }
  }
  renderTagsUploadSection() {
    if (this.props.parent_state.user) {
      if (this.props.parent_state.user.role != "regular-account") {
        return (
          <div className="flex justify-between  p-2 items-center  ">
            <div className="text-gray-900 flex items-center dark:text-gray-100 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 text-blue-500 h-5 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 6h.008v.008H6V6z"
                />
              </svg>
              Tags
            </div>
            <div className="flex mr-2.5">
              {this.renderCreateButton()}

              <button
                onClick={(e) => this.handleAnnotationFileUpload(e)}
                className="ml-1 hover:bg-gray-400  bg-gray-200 text-gray-900 rounded-md px-2 py-1 text-sm flex dark:bg-gray-900  dark:text-gray-100 dark:border-gray-700 dark:hover:bg-gray-800"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 mx-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
                  />
                </svg>
              </button>

              <CSVLink filename={this.props.parent_state.judgement.file_id + "_annotations.csv"} data={this.getCsvParsedAnnotations()} separator={","}>
                {/* <CsvDownloader filename="MyFile" separator="," id="CsvDownloader" noHeader={true} datas={this.getCsvParsedAnnotations()} > */}
                <button
                  onClick={() => this.getCsvParsedAnnotations()}
                  className="ml-1 hover:bg-gray-400  bg-gray-200 text-gray-900 rounded-md px-2 py-1 text-sm flex dark:bg-gray-900  dark:text-gray-100 dark:border-gray-700 dark:hover:bg-gray-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 mx-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
                    />
                  </svg>
                </button>
              </CSVLink>
              {/* </CsvDownloader> */}
            </div>
          </div>
        );
      }
    }
  }

  render() {
    return (
      <div className="sticky top-0 mb-10  py-3  dark:text-gray-100  dark:border-gray-700">
        <div className=" bg-dark:grey-800  mb-3  dark:border-gray-700 ">
          <div className="dark:text-gray-200 dark:font-semibold text-lg ">
            Judgement Annonations
          </div>
          <div className="text-gray-500 text-xs ">
            View and management judgement annotations
          </div>
        </div>

        {this.renderTagsUploadSection()}
        {this.state.is_loading ? (
          <div className="border p-10 w-full my-5 rounded-md ">
            <div className="text-center">
              <ProgressSpinner style={{ width: "40px", height: "40px" }} />
              <div className="text-gray-700">
                Fetching annotations. Please wait...
              </div>
            </div>
          </div>
        ) : (
          <ScrollPanel
            style={{ width: "100%", height: "500px", padding: "0px" }}
            className="custombar2  w-full m-0 b-0"
          >
            {this.renderAnnotations()}
          </ScrollPanel>
        )}

        <TagEditDialog
          ref={this.tagEditDialogRef}
          parent_state={this.props.parent_state}
          parent_functions={this.props.parent_functions}
          onClose={() => this.closeEditDialog()}
          visible={this.state.tag_edit_dialog_visible}
          tag={this.state.selectedTag}
          x={this.state.clickedX}
          y={this.state.clickedY}
        />

        <NewTagDialog
          parent_functions={this.props.parent_functions}
          ref={this.newTagDialogRef}
        />

        <input
          className="hidden"
          onChange={this.fileChangeHandler}
          accept=".csv"
          style={{ visibility: "hidden" }}
          ref={this.annotationFileChooserRef}
          id="annotationFileChooser"
          type="file"
        />
      </div>
    );
  }
}
