import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class NotFound extends Component {
  logout() {
    localStorage.removeItem("authData");
    window.location.href = "/";
  }
  render() {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <div className="w-full sm:w-2/4 ">
          <div className="p-5 rounded-md bg-white dark:bg-gray-900 dark:border-gray-800 rounded-md  mx-2 sm:mx-0  border rounded-md">
            <div className="sm:flex items-center px-3 ">
              <div className="wfull sm:w-2/4">
                <Link to={"/"}>
                  <div className="text-3xl font-semibold dark:text-gray-100">
                    404 NOT FOUND
                  </div>
                </Link>
                <div className="text-gray-500 my-2">
                  The page you are trying to access does not exists
                </div>
                <button
                  onClick={() => this.logout()}
                  className="bg-blue-500 p-2 mt-3 rounded-md text-md text-white"
                >
                  Back to Home
                </button>
              </div>

              <div className="w-full text-center mt-2 sm:mt-0 sm:w-2/4">
                <img style={{ height: "300px" }} src={"/pending.png"}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
