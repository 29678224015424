import React, { Component, createRef } from 'react'
import { Link } from 'react-router-dom';
import { deleteJudgement } from '../backend_handlers/JudgementController';



export default class JudgementDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            x: '',
            y: '',
            visible: false,
            width: '200px',
            judgement: {}
        }



    }
    handleDocumentClicks = (e) => {
        if (e.target.getAttribute('name') !== 'judgement_menu_toggler') {
            this.hide();
        }
    }
    handleJudgementRemove() {
        this.confirmDialogRef.current.show("Are you sure you want to delete this judgement from the database?");
        //this.setState({visible:false});
    }
    show(x, y, judgement) {
        document.addEventListener("click", this.handleDocumentClicks)
        this.setState({ x: x, y: y, visible: true, judgement: judgement });
    }
    hide() {
        document.removeEventListener("click", this.handleDocumentClicks);
        this.setState({ visible: false });
    }
    AddTag = (judgement) => {
        this.hide();
        this.props.judgementTagDialog.current.show(judgement);
    }
    createSummaryNote = (judgement) => {
        this.hide();
        this.props.notesDialog.current.show('create', null, judgement.id);
    }
    render() {
        let { x, y, visible, width, height, judgement } = this.state;
        return (
            <div className={'bg-white dark:bg-gray-800 dark:text-white dark:border-gray-700 border  shadow-lg ' + (visible ? ' block ' : ' hidden ')} style={{ width: width, top: (y) + 'px', left: (x - 200) + 'px', position: 'absolute', zIndex: '9000' }} >
                <Link to={'/judgement-view/' + judgement.id}><div className='flex px-3 py-2 text-sm hover:bg-gray-300 dark:hover:bg-gray-900 hover:rounded-sm dark:border-b-gray-700 border-b' style={{ cursor: 'pointer' }}>
                    Open
                </div></Link>
                {/* <div onClick={() => this.setState({ current_page: 'rename', styleType: 'centered' })} className='flex px-3 py-2 text-sm hover:bg-gray-300 hover:rounded-sm border-b' style={{ cursor: 'pointer' }}>
                    Hide
                </div> */}

                {this.props.parent_state.user && (this.props.parent_state.user.role == 'admin' || this.props.parent_state.user.role == 'annotator') ?
                    <div>
                        <div name={"add_tag_btn"} onClick={() => this.AddTag(judgement)} className='flex px-3 py-2 text-sm hover:bg-gray-300 hover:rounded-sm ' style={{ cursor: 'pointer' }}>
                            Add tag
                        </div>
                        <div name={"add_tag_btn"} onClick={() => this.createSummaryNote(judgement)} className='flex px-3 py-2 text-sm hover:bg-gray-300 hover:rounded-sm ' style={{ cursor: 'pointer' }}>
                            Add Summary Note
                        </div>
                    </div>

                    : ''
                }

                {this.props.parent_state.user && this.props.parent_state.user.role == 'admin' ?
                    <div name={"remove_btn"} onClick={() => this.props.onDelete(judgement)} className='flex px-3 py-2 text-sm hover:bg-gray-300 hover:rounded-sm ' style={{ cursor: 'pointer' }}>
                        Remove
                    </div>
                    :
                    ""
                }
            </div>
        )
    }
}
