import React, { Component } from 'react'
import Page from '../../components/page'

export default class AboutPage extends Page {

    componentDidMount() {

        document.title = "About"
    }
    renderHeaderSection() {
        return (
            <div className='mb-7 border-b pb-3'>
                <div className='text-xl font-semibold'>About Malawi Law Annotator</div>
                <div className='text-sm text-gray-500'>Embarking on a Digital Legal Revolution in Malawi!</div>
            </div>
        )
    }
    renderContent() {
        return (
            <div className='px-2'>
                <div className='my-5'>

                    <div className='flex items-center   w-fit pe-3 rounded'>
                        <div className=' mr-1 '>🌟</div>
                        <div className='font-semibold'>Early Days</div>
                    </div>
                    <div className='mt-2 text-gray-800'>
                        In 2019, the vision was born when Amelia Taylor and Eva Mfutso-Bengo identified the imperative to revolutionize the cataloging of criminal cases at the Malawi Judiciary. Imagine, legal judgments confined to the simplicity of the year of issue! That's when the idea sparked – could cutting-edge AI text processing elevate the game by extracting crucial metadata? With Amelia's expertise in AI and Eva's legal prowess, a dynamic duo was born!
                    </div>


                </div>
                <div className='my-5 mt-8'>

                    <div className='flex items-center w-fit'>
                        <div className='mr-1'>💡</div>
                        <div className='font-semibold'> Innovative Legal Search Tool</div>
                    </div>
                    <div className='mt-2 text-gray-800'>

                        <div>Picture a tool that empowers legal researchers to effortlessly:</div>
                        <ul className='list-disc ps-10'>
                            <li>Find specific statutes on a subject</li>
                            <li>Explore how laws were applied to judgments</li>
                            <li>Gain a comprehensive overview of the law on a specific topic</li>
                            <li>Discover cases applying the law to situations akin to their research</li>
                        </ul>
                    </div>


                </div>

                <div className='my-5 mt-8'>

                    <div className='flex items-center '>
                        <div className=' mr-1'>🚀</div>
                        <div className='font-semibold'>First Results</div>
                    </div>
                    <div className='mt-2 text-gray-800'>

                        In 2020, the project gained momentum, fueled by a grant from AI4D. Over 600 judgments were automatically annotated using an AI pipeline. This dataset opened the door to embarking on a legal classification based on the International Classification of Crime for Statistical Purposes (ICCS). The journey had begun!
                    </div>


                </div>

                <div className='my-5 mt-8'>

                    <div className='flex items-center  pe-10 '>
                        <div className=' mr-1'>🌐</div>
                        <div className='font-semibold'>Malawi Law Annotator Unleashed</div>
                    </div>
                    <div className='mt-3 text-gray-800'>
                        <div>Fast forward to 2023, and the Malawi Law Annotator platform was born with the following aims:</div>
                        <ul className='ps-10 mt-2 list-decimal'>
                            <li><span className='font-semibold'>Empower Legal Practitioners:</span> Open access to annotated legal texts for legal practitioners</li>
                            <li><span className='font-semibold'>Showcase AI in Action: </span>Demonstrating the practical use of AI for text intelligence</li>
                            <li><span className='font-semibold'>Community Building:  </span>Encourage user involvement to collectively enhance the quality of legal research documents in Malawi</li>

                        </ul>
                    </div>


                </div>

                <div className='my-5 mt-8'>

                    <div className='flex items-center  pe-10 '>
                        <div className=' mr-1'>🌈</div>
                        <div className='font-semibold'>User Engagement</div>
                    </div>
                    <div className='mt-3 text-gray-800'>
                        As a guest, preview annotated judgments; as a registered user, add comments and notes. Become an annotator to actively contribute to shaping the future of legal research!
                    </div>


                </div>

                <div className='my-5 mt-8'>

                    <div className='flex items-center  '>
                        <div className=' mr-1'>🔗 </div>
                        <div className='font-semibold'>Get Involved</div>
                    </div>
                    <div className='mt-3 text-gray-800'>
                        Join the movement! Contact us at <a className='font-medium text-blue-600 dark:text-blue-500 hover:underline' href='mailto:info@datapractitioner.org'>info@datapractitioner.org</a>. Your involvement is not just welcome; it's crucial!
                    </div>


                </div>

                <div className='my-5 mt-8'>

                    <div className='flex items-center '>
                        <div className=' mr-1'>👩‍💼 </div>
                        <div className='font-semibold'>Meet the Team:</div>
                    </div>
                    <div className='mt-3 text-gray-800'>
                        <ul className='list-disc ps-10'>
                            <li>Amelia Taylor, PhD: Leading the development of datasets, AI algorithms, research, and system design</li>
                            <li>Eva Mfutso-Bengo: instrumental in laying the foundation for legal classification</li>
                            <li>Enock Nkhoma: responsible for software development</li>
                            <li>Past Collaborators: Binart Kachule facilitating data collection.</li>
                        </ul>
                    </div>


                </div>
                <div className='my-5 mt-8'>

                    <div className='flex items-center '>
                        <div className='mr-1'>💰</div>
                        <div className='font-semibold'>Funding Acknowledgment:</div>
                    </div>
                    <div className='mt-3 text-gray-800'>
                        The initial stages of this groundbreaking initiative were made possible by the support of Artificial Intelligence 4 Development under Grant No. BA200207E.


                    </div>


                </div>

                <div className='mt-10'>
                    The digital transformation of Malawi's legal landscape is here. Be part of the change! 🌐🔍✨
                </div>


            </div>

        )
    }
    renderBody() {
        return (
            <div className='mx-2 sm:mx-20 my-5'>
                {this.renderHeaderSection()}
                {this.renderContent()}
            </div>
        )
    }
}


