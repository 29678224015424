import React, { Component } from 'react'

export default class TagFilterItem extends Component {

    toggleTag = (e) => {
        if (e.target.checked) {
            this.props.parent_functions.applyTag(this.props.tag);
        } else {
            this.props.parent_functions.removeAppliedTag(this.props.tag);
        }
    }
    render() {

        return (
            <div className='border-b border-gray-300 p-1   mb-1 flex justify-between items-center'>
                <div className='flex items-center'>
                    <input checked={this.props.tag.checked? true : false} onChange={this.toggleTag} className='border-gray-300 rounded p-1 mx-1' type='checkbox'></input>
                    <div>
                        <div>{this.props.tag.tag} <span className="text-xs text-gray-500">{this.props.tag.scope}</span></div>

                    </div>

                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 text-gray-500 cursor-pointer h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                    </svg>

                </div>

            </div>
        )
    }
}
