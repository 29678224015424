import axios from "axios";

export async function createUser(data) {

    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/users`, data);
        return response;
    } catch (error) {
        console.log(error);
        return error.response.data;
    }

}
export async function updateUser(data) {

    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/${data.user_id}`, data);
        return response;
    } catch (error) {
        console.log(error);
        return error.response.data;
    }

}
export async function getUsers(data) {

    try {
        let response = await axios.get(`${process.env.REACT_APP_URL}/api/users`, data);
        return response;
    } catch (error) {
        console.log(error);
        return error.response.data;
    }

}

export async function updateRole(user, role, toast) {

    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/users/updateRole/${user}`, { role: role });
        if (response.status == 200) {
            toast.success("Annotator Status Updated Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates1",
            });
        }
        return response;
    } catch (error) {
        toast.success("There was an error while updating annotator Status", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "toastAvoidsDuplicates1",
        });
        return error.response.data;
    }

}

export async function deleteUser(user, toast) {

    try {
        let response = await axios.delete(`${process.env.REACT_APP_URL}/api/users/${user}`);
        if (response.status == 200) {
            toast.success("User removed successfully", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates1",
            });
        }
        return response;
    } catch (error) {
        console.log(error);

        if (error.response.data) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates1",
            });
        }
        else {
            toast.error("There was an error while removing user", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates1",
            });
        }

        return error.response.data;
    }

}