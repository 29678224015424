import React, { Component, createRef } from 'react'
import { deleteTag, createTag, updateTag, clearTag } from '../backend_handlers/TagController';
import { toast } from 'react-toastify';
import { ProgressSpinner } from 'primereact/progressspinner';



export default class TagEditDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      current_page: 'menu',
      form_data: {},
      form_errors: {},
      locationX: '',
      locationY: '',
      styleType: 'relative',
      visible: false,
      tag: {},
      is_loading: false

    }
    this.deleteTag = deleteTag.bind(this);
    this.updateTag = updateTag.bind(this);
    this.clearTag = clearTag.bind(this);


  }
  renderCurrentPage = () => {

    if (this.state.is_loading) {
      return (
        <div className='text-center py-5'>
          <ProgressSpinner style={{ width: '20px', height: '20px' }} />
          <div className='text-sm'>Processing...</div>
        </div>
      )
    }
    switch (this.state.current_page) {
      case 'menu':
        return this.renderMenu();
        break;
      case 'rename':
        return this.renderRename();
      default:
        break;
    }
  }
  changeHandler = (event) => {
    let { form_data } = this.state;
    form_data[event.target.name] = event.target.value;
    this.setState({ form_data });
  }
  renderMenu() {

    let { tag } = this.state;
    if (tag.name) {

      console.log(tag.name);
      return (
        <div>

          {["law", "lawname", "case reference"].includes(tag.name.toLowerCase()) ? '' :
            <div onClick={() => this.setState({ current_page: 'rename', styleType: 'centered' })} className='flex px-3 py-1 text-sm hover:bg-gray-300  hover:rounded-sm border-b dark:hover:bg-gray-700 dark:border-b-gray-700' style={{ cursor: 'pointer' }}>
              Rename
            </div>
          }

          <div onClick={() => this.handleClearAnnotations()} className='flex px-3 py-1 text-sm hover:bg-gray-300 hover:rounded-sm border-b dark:hover:bg-gray-700 dark:border-b-gray-700' style={{ cursor: 'pointer' }}>
            Clear annotations
          </div>

          {["law", "lawname", "case reference"].includes(tag.name.toLowerCase()) ? '' :
            <div onClick={() => this.handleRemove()} className='flex px-3 py-1 text-sm hover:bg-gray-300 hover:rounded-sm  dark:hover:bg-gray-700 dark:border-b-gray-700' style={{ cursor: 'pointer' }}>
              Delete
            </div>
          }
        </div>
      )
    }
  }

  handleRename = async () => {
    this.setState({ is_loading: true });

    let { form_data, form_errors } = this.state;
    if (!form_data.name) {
      form_errors.name = 'Name can not be empty!';
      this.setState({ form_errors });
    }
    else {
      this.handleOnClose(true);
      let response = await this.updateTag(this.state.tag.id, form_data.name, toast);

      if (response.status == 200) {
        this.handleOnClose(true);
        this.props.parent_functions.refreshTags();
      }
      else {
        console.log(response.data);
        form_errors.name = response.data.message;
        this.setState({ form_errors: form_errors, is_loading: false });

      }
    }


  }

  handleRemove = () => {
    this.handleOnClose();
    let msg = "Are you sure you want to remove tag '" + this.state.tag.name + "' ?";
    this.props.parent_functions.confirmDialog.current.show(msg, this.deleteConfirmedCallback);
  }

  handleClearAnnotations = () => {
    this.handleOnClose();
    let msg = "This will delete all annotaions under '" + this.state.tag.name + "' tag. Do you still want to proceed ?";
    this.props.parent_functions.confirmDialog.current.show(msg, this.clearTagsConfirmedCallack);
  }

  deleteConfirmedCallback = async () => {
    this.setState({ is_loading: true });
    let response = await this.deleteTag(this.state.tag.id, toast);
    if (response) {
      if (response.status == 200) {
        this.props.parent_functions.refreshTags();
      }
    }
    this.setState({ is_loading: false });

  }

  clearTagsConfirmedCallack = async () => {
    this.setState({ is_loading: true });
    let response = await this.clearTag(this.state.tag.id, toast);
    if (response) {
      if (response.status == 200) {
        this.props.parent_functions.refreshTags();

        this.props.parent_state.tags.map((tag, index) => {
          tag.JudgementAnnotations.map((annotationItem, index) => {
            this.props.parent_functions.removeHighliting(annotationItem);
          })
        })
      }
    }
    this.setState({ is_loading: false });

  }

  show = (x, y, tag) => {
    this.setState({ visible: true, is_loading: false, locationX: x, locationY: y, tag: tag, styleType: 'relative' });
  }

  renderRename() {
    let { form_data, form_errors, tag } = this.state;

    if (form_data.name == null) {
      form_data.name = this.state.tag.name;
      this.setState({ form_data });
    }
    return (
      <div className='px-2 py-2'>
        <div className=' flex items-center '>
          <input name='name' value={form_data.name} onChange={this.changeHandler} class={" w-4/5 mt-1 border-gray-300 rounded w-full py-1.5 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.name ? ' border-red-500 ' : ' ')} type="text" placeholder="" />
          <button onClick={() => this.handleRename()} className='w-1/5 bg-blue-500 text-white px-3 py-1.5 ml-1 rounded-md my-2 text-sm hover:bg-blue-700'>Save</button>
        </div>
        <div className='text-red-500 text-sm'>{form_errors.name}</div>
      </div>

    );
  }

  handleOnClose = (force = false) => {
    if (force) {
      this.setState({ current_page: 'menu', form_data: {}, visible: false, form_errors: {}, is_loading: false });
    }
    else {
      if (!this.state.is_loading) {
        this.setState({ current_page: 'menu', form_data: {}, visible: false, form_errors: {}, is_loading: false });
      }
    }
  }

  getCurrentStyle() {
    if (this.state.styleType == 'relative') {
      return { width: '200px', top: this.state.locationY + 'px', left: ((this.state.locationX - 200) + 'px'), position: 'fixed', zIndex: '9000' };
    }
    else {
      return { width: '300px', zIndex: '9000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' };
    }
  }
  render() {
    return (
      <div className={'bg-gray-200 dark:bg-gray-800 dark:border-gray-700 border shadow-lg rounded-md' + (this.state.visible == true ? ' block ' : ' hidden ')} style={this.getCurrentStyle()} >
        <div className='p-1 px-3 dark:border-gray-800 dark:text-gray-100  rounded-md text-gray-900 flex justify-between items-center'>
          <div>{this.state.tag.name}</div>
          <div className='text-gray-500 ml-5' onClick={() => this.handleOnClose()} style={{ cursor: 'pointer' }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
        <div className='bg-white  dark:bg-gray-900 py-1 dark:text-gray-100'>
          {this.renderCurrentPage()}
        </div>

      </div>
    )
  }
}
