import axios from "axios";


export async function createNote(form_data, toast) {

    try {


        let response = await axios.post(`${process.env.REACT_APP_URL}/api/notes`, { 'type': form_data.type, 'access': form_data.access, 'label': form_data?.label, 'note': form_data.note, 'user_id': form_data.user_id, 'paragraph_id': form_data.paragraph_id, 'judgement_id': form_data.judgement_id });
        toast.success("Note created successfully", {
            position: toast.POSITION.TOP_RIGHT
        });
        return response;
    }
    catch (error) {

        if (error.response?.data?.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error("There was an error while creating a note", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        return error;


    }
}
export async function updateNote(form_data, toast) {

    try {

        let response = await axios.post(`${process.env.REACT_APP_URL}/api/notes/${form_data.note_id}`, { 'access': form_data.access, 'label': form_data?.label, 'note': form_data.note });
        toast.success("Note updated successfully", {
            position: toast.POSITION.TOP_RIGHT
        });
        return response;
    }
    catch (error) {

        if (error.response?.data?.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error("There was an error while updating a note", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        return error;


    }
}
export async function getNotes(toast, paragraph_id) {
    try {
        let response = await axios.get(`${process.env.REACT_APP_URL}/api/notes/${paragraph_id}`);
        return response;
    }
    catch (error) {
        console.log(error);
        toast.error("There was an error while fetching notes", {
            position: toast.POSITION.TOP_RIGHT
        });

    }
}
export async function deleteNote(note_id, toast) {

    try {
        let response = await axios.delete(`${process.env.REACT_APP_URL}/api/notes/${note_id}`);
        toast.success("Note deleted successfully", {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "toastAvoidsDuplicates4",
        });
        return response;

    } catch (error) {

        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error("There was an error while deleting a note", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates5",
            });
        }
        return error;
    }
}
