import axios from "axios";




export async function uploadJudgementFile(form_data, toast) {

    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/judgements/upload`, form_data, { headers: { "Content-Type": "multipart/form-data" } });
        toast.success("Judgement uploaded successfully", {
            position: toast.POSITION.TOP_RIGHT
        });
        return response;
    }
    catch (error) {
        console.log(error);
        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates7",
            });
        }
        else {
            toast.error("There was an error while parsing the judgement file", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates8",
            });
        }
        return error;
    }

}
export async function deleteJudgement(judgement_id, toast) {

    try {
        let response = await axios.delete(`${process.env.REACT_APP_URL}/api/judgements/${judgement_id}`);
        toast.success("Judgement deleted successfully", {
            position: toast.POSITION.TOP_RIGHT
        });
        return response;

    } catch (error) {

        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error("There was an error while deleting a judgement", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        return error;
    }
}
export async function fetchJudgement(judgement_id, toast) {

    try {
        let response = await axios.get(`${process.env.REACT_APP_URL}/api/judgements/${judgement_id}`);
        return response;

    } catch (error) {
        console.log(error);
        // toast.error("There was an error while fetching judgement data", {
        //     position: toast.POSITION.TOP_RIGHT
        // });
        return error;
    }
}
export async function getAnnotations(judgement_id, toast) {
    try {
        let response = await axios.get(`${process.env.REACT_APP_URL}/api/judgements/annotations/${judgement_id}`);

        return response;

    } catch (error) {

        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error("There was an error while fetching annotations", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        return error;
    }
}
export async function printPdf(print_data, toast) {

    await axios.post(
        `${process.env.REACT_APP_URL}/api/judgements/toPdf`,
        { print_data: print_data },
        { responseType: 'blob' } // !!!
    ).then((response) => {
        window.open(URL.createObjectURL(response.data));

    }).catch(error => {
        console.log(error);
        toast.error("There was an error while printing judgement", {
            position: toast.POSITION.TOP_RIGHT
        });
        return error;
    })



}