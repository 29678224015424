import React, { Component } from 'react'
import { uploadJudgementFile } from '../../../backend_handlers/JudgementController';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ToastContainer, toast } from 'react-toastify';

export default class JudgementUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current_page: 'upload_form',
            is_uploading: false,
            form_data: {},
            form_errors: {}
        }
        this.uploadJudgementFile = uploadJudgementFile.bind(this);
    }
    fileChangeHandler = (event) => {
        let { form_data } = this.state;
        form_data[event.target.name] = event.target.files[0];
        console.log(form_data);
        this.setState({ form_data });
    }
    renderCurrentPage() {
        switch (this.state.current_page) {
            case 'upload_form':
                this.renderCreateForm();
                break;
            case 'loading':
                this.renderLoading();
                break;

            default:
                break;
        }
    }

    uploadFile = async () => {

        let { form_data, form_errors } = this.state;
        console.log(form_data);
        if (!form_data.judgement_xml) {
            form_errors.judgement_xml = "File can not be empty";
            this.setState({ form_errors });
            return;
        } else {

            this.setState({ is_uploading: true });
            let formData = new FormData();
            formData.append('judgement', form_data.judgement_xml);
            formData.append('file_id', 'test file id');

            let response = await uploadJudgementFile(formData, this.props.toast);
            if (response) {
                if (response.status == 200) {
                    this.props.parent_functions.fetchJudgements();
                }
            }
            this.setState({ is_uploading: false });

        }

    }
    renderLoading() {
        return (<div className='p-10 bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100  border text-center rounded-md mt-3'>
            <ProgressSpinner style={{ width: '30px', height: '30px' }} />
            <div className='text-gray-500'>Parsing file. Please wait...</div>
        </div>);
    }
    renderCreateForm() {
        let { form_data, form_errors } = this.state;
        return (
            <div className=' dark:text-gray-100 rounded-md mt-2'>
                <div className='rounded-md'>
                    <div>Choose Judgement file</div>
                    <div className='text-sm text-gray-500'> Only XML encoded judgement files can be parsed and loaded into the database</div>
                    <input name="judgement_xml" onChange={this.fileChangeHandler} type="file" class={"mt-3 bg-white appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.judgement_xml ? ' border border-red-500 ' : ' ')} />
                </div>
                <div className='text-sm text-red-500'>{form_errors.judgement_xml}</div>

                <button onClick={() => this.uploadFile()} className='rounded justify-center my-3 flex items-center text-gray-200  btn  bg-blue-700   py-1 px-4 text-white font-semibold text-sm'>Upload <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 mx-2 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                </svg>
                </button>


            </div>
        );
    }
    render() {
        return (

            <div className='mb-5 mt-5'>
                <div className=''>
                    <div className='mb-5'>
                        <div className=" dark:text-gray-200 dark:font-semibold text-lg ">
                            Judgement Upload
                        </div>
                        <div className="text-gray-500 text-xs ">
                            Upload judgement from xml files
                        </div>
                    </div>
                    {this.state.is_uploading ? this.renderLoading() : this.renderCreateForm()}
                </div>
            </div>

        )
    }
}
