import React, { Component } from 'react'
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { Sidebar } from 'primereact/sidebar';
import { Link } from 'react-router-dom';


export default class AdminNavBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            side_bar_visible: false
        }
    }
    renderStart() {
        return (
            <div>{process.env.REACT_APP_APP_NAME}</div>
        );
    }
    handleLogout() {
        localStorage.removeItem('authData');
        window.location.href = '/login';
    }
    renderSideBar() {


        let route = window.location.pathname;
        return (
            <Sidebar visible={this.state.side_bar_visible} position="left" onHide={() => this.setState({ side_bar_visible: false })} >
                <h2 className='h2 text-lg font-semibold'>Malawi Law Annotator</h2>
                <div className='m-2'>
                    <Link to={'/admin/home'}>
                        <div className={'p-2 flex items-center hover:text-blue-400 hover:underline' + (route == '/admin/home' ? ' bg-blue-500 text-white' : ' ')}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                            </svg>
                            <div className='mx-1'>Home</div>
                        </div>
                    </Link>

                    <Link to={'/admin/judgements'}>
                        <a className={'p-2 flex items-center hover:text-blue-400 hover:underline' + (route == '/admin/judgements' ? ' bg-blue-500 text-white' : ' ')}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                            </svg>

                            <div className='mx-1'>Judgements</div>
                        </a>
                    </Link>


                    <Link to={'/register'}>
                        <div className={'p-2 flex items-center hover:text-blue-400 hover:underline' + (route == '/register' ? ' bg-blue-500 text-white' : ' ')}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>


                            <div className='mx-1'>Users</div>
                        </div>
                    </Link>


                    <div onClick={() => this.handleLogout()} className={'p-1 flex items-center hover:text-blue-400 hover:underline' + (route == '/login' ? ' bg-blue-500 text-white' : ' ')}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                        </svg>

                        <div className='mx-1'>Logout</div>
                    </div>


                </div>
            </Sidebar>
        );
    }
    render() {

        let route = window.location.pathname;
        var pathArray = window.location.pathname.split('/');


        return (
            <div className='text-blue bg-gray-300 px-3 py-3.5 m-0 dark:border-0 dark:bg-gray-900'>
                <div className='flex justify-between'>
                    <div className='flex items-center'>
                        <div className='text-dark-500 dark:text-gray-300 text-lg font-semibold'>Malawi Law Annotator</div>
                    </div>

                    <div >
                        <div className='text-md text-gray-800  hidden sm:flex items-center dark:text-white'>
                            <Link to={"/admin/home"}>
                                <a href='#' className={'flex items-center  mx-1 hover:text-blue-400 ' + (route == '/admin/home' ? ' text-blue-500' : '')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 mx-1  h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                    </svg>Home</a>
                            </Link>

                            <Link to={"/admin/judgements"}>
                                <a href='#' className={'flex items-center  mx-1 hover:text-blue-400 ' + (route == '/admin/judgements' || pathArray[1] == 'judgement-view' ? ' text-blue-500' : '')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mx-1  h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                                    </svg>
                                    Judgements</a></Link>
                            <Link to={"/users"}><a className={'flex items-center  mx-1 hover:text-blue-400 ' + (route == '/users' ? ' text-blue-500' : '')}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mx-1  h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                </svg>
                                Users
                            </a></Link>

                            <a onClick={() => this.handleLogout()} href='#' className={'flex items-center  mx-1 hover:text-blue-400 ' + (route == '/login' ? ' text-blue-500' : '')}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mx-1  h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                                </svg>

                                Logout
                            </a>



                        </div>
                        <div className='sm:hidden'>
                            <button className='btn btn-sm' onClick={() => this.setState({ side_bar_visible: true })}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-500 dark:text-gray-500 ">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                </svg>
                            </button>
                        </div>
                    </div>


                </div>

                {this.renderSideBar()}

            </div>
        )
    }
}


