import React, { Component, createRef } from 'react'
import Page from '../../../components/page'
import ReferencesTable from '../../../components/referencesTable'
import ReferenceTextDialog from '../../../components/referenceTextDialog'
import { getUnique } from '../../../backend_handlers/AnnotationController'
import { createReference, getReferences } from '../../../backend_handlers/referenceController'
import { ToastContainer, toast } from 'react-toastify'
import { ProgressSpinner } from 'primereact/progressspinner'

export default class ReferencesPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            form_data: {},
            annotations: [],
            references: [],
            references_loading: false,
            current_page: 'form'
        }

        this.referenceTextDialog = createRef();
        this.getUniqueAnnotations = getUnique.bind(this);
        this.createReference = createReference.bind(this);
        this.getReferences = getReferences.bind(this);
    }
    componentDidMount() {
        this.fetchReferences();
        this.fetchAnnotations();

    }
    changeHandler = (e) => {
        let { form_data } = this.state;
        form_data[e.target.name] = e.target.value;
        this.setState({ form_data });
    }
    fetchAnnotations = async () => {
        try {
            let response = await this.getUniqueAnnotations(toast);

            if (response.status == 200) {
                this.setState({ annotations: response.data });
            }

        }
        catch (error) {
            console.log(error);
        }
    }
    fetchReferences = async () => {
        this.setState({ references_loading: true });
        try {
            let response = await this.getReferences(toast);
            if (response.status == 200) {
                this.setState({ references: response.data });
            }

        } catch (error) {
            console.log(error);
        }
        this.setState({ references_loading: false });

    }
    setReferenceText = (text) => {
        let { form_data } = this.state;
        form_data['reference'] = text;
        this.setState({ form_data });
    }
    renderHeader() {
        return (
            <div className='mt-2 flex justify-between items-center '>
                <div className=''>
                    <div className='text-xl dark:text-gray-100'>Annotation References</div>
                    <div className='text-gray-500'>View and manage annotation references</div>
                </div>

                <div className='w-1/4 flex items-center'>

                    <div className='w-full'>
                        <input placeholder='Search' className='text-sm w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md p-2 focus:ring-blue-500 focus:border-blue-500 block   dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500' />
                    </div>



                </div>
            </div>
        )
    }
    showReferenceTextInput() {
        this.referenceTextDialog.current.show();
    }
    saveReference = async () => {

        this.setState({ current_page: 'loading' });
        try {

            let response = await this.createReference(this.state.form_data, toast);
            if (response.status == 200) {

                console.log(response.data);
                let references = this.state.references;
                let newRefs = [...references, response.data];
                this.setState({ references: newRefs })
            }
        }
        catch (error) {
            console.log(error);
        }

        this.setState({ current_page: 'form' })
    }
    renderCurrentPage() {

        switch (this.state.current_page) {
            case 'form':
                return this.renderCreateFormBody();
                break;
            case 'loading':
                return this.renderLoading();

            default:
                break;
        }
    }
    renderCreateFormBody() {
        return (
            <div>
                <div className='my-2'>
                    <label className='text-gray-700'>Annotation</label>
                    <select onChange={this.changeHandler} name='annotation' className='border w-full mt-1 text-gray-500 p-1 border border-gray-200 rounded'>
                        <option >Choose annotation</option>
                        {
                            this.state.annotations.map((item, index) => {
                                return (
                                    <option>{item.DISTINCT}</option>
                                )
                            })
                        }
                    </select>
                </div>

                <div className='my-2 border text-center border-gray-200 rounded flex justify-center items-center p-10'>

                    {this.state.form_data.reference != null ?

                        <div className=''>
                            <div className='flex justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6  text-blue-500 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                                </svg>
                            </div>

                            <div className=''>Click to edit reference</div>
                        </div>
                        :
                        <div className='text-center w-full cursor-pointer' onClick={() => this.showReferenceTextInput()}>
                            <div className='flex items-center justify-center  w-full'> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                            </svg></div>

                            <div> Click here to upload reference text </div>
                        </div>
                    }
                </div>

                <div className='flex justify-end my-3'>
                    <button onClick={() => this.saveReference()} className='bg-blue-500 text-white rounded p-1 px-2 hover:bg-blue-700'> Save Reference</button>
                </div>
            </div>
        )
    }
    renderLoading() {
        return (
            <div className='my-5 p-10 border border-gray-200 flex justify-center items-center'>
                <div className='text-gray-500 flex items-center'>
                    <ProgressSpinner className='mx-2' style={{ width: '30px', height: '30px' }} />
                    <div className='mx-2'>Saving. Please wait</div>
                </div>
            </div>
        )
    }
    renderBody() {


        return (
            <div className='p-2 py-2 sm:px-10 '>

                <div className='sm:flex '>
                    <div className='w-full  sm:w-3/4'>
                        {this.renderHeader()}

                        <div>
                            {this.state.references_loading ?
                                <div className='p-20 my-2 border text-gray-500 bg-white  flex justify-center items-center '>
                                    <div className='flex justify-center items-center'>
                                        <ProgressSpinner style={{ height: '30px', width: '30px' }} />
                                        <div className='mx-1'>Fetching annotations. Please wait</div>
                                    </div>
                                </div>
                                :
                                <ReferencesTable references={this.state.references} />
                            }
                        </div>

                    </div>

                    <div className='w-full sm:w-1/4 '>

                        <div className='bg-white border p-2 rounded mt-4 ml-2'>
                            <div className=''>
                                Add  Annotation Reference
                            </div>
                            <div className='text-gray-500 text-sm'>
                                Create new reference to existing judgement annotations. References will apply to all instances of annotations
                            </div>

                            <div>
                                {this.renderCurrentPage()}
                            </div>
                        </div>

                    </div>

                </div>



                <ReferenceTextDialog setReferenceText={this.setReferenceText} ref={this.referenceTextDialog} />

                <ToastContainer />

            </div>
        )
    }
}
