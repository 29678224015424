import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class ChooseAccountTypePage extends Component {
    render() {
        return (
            <div className=''>
                <div className='text-md text-gray-500'>To get started , choose the type of account you would like to register</div>
                <div className='my-5'>
                    <div onClick={() => this.props.nextPage(2, 'regular-account')} style={{ cursor: 'pointer' }} className={'border dark:border-gray-800 p-2 hover:bg-gray-300  dark:hover:bg-gray-800 ' + (this.props.form_data.account_type == 'regular_account' ? 'bg-gray-200 dark:bg-gray-700 ' : '')}>
                        <div className='text-md font-semibold text-gray-800 dark:text-gray-100 '>Regular account</div>
                        <div className='text-sm text-gray-500 '>With this account you can view all judgements and annotations available. </div>
                    </div>
                    <div onClick={() => this.props.nextPage(2, 'annotator-pending')} style={{ cursor: 'pointer' }} className={'border mt-2 dark:border-gray-800 hover:bg-gray-300 p-2 dark:hover:bg-gray-800 ' + (this.props.form_data.account_type == 'annotator' ? 'bg-gray-200 dark:bg-gray-700 ' : '')}>
                        <div className='text-md  text-gray-800 font-semibold dark:text-gray-100'>Annotator</div>
                        <div className='text-sm text-gray-500 '>With this account you can annotate view and annotate all judgements </div>
                    </div>

                    <div className='mt-5 text-gray-500 px-2 text-start text-sm'>
                        Already have an account? <Link to={'/login'}><span className='text-blue-500'>Login Instead</span></Link>
                    </div>
                    <div className='text-end text-sm text-blue-500'>
                        [ 1 of 3 ]
                    </div>
                </div>

            </div>
        )
    }
}
