import React, { Component, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { login } from '../../backend_handlers/authController';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setLogin } from '../../redux';
import { Navigate } from 'react-router-dom';


export default class LoginPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            form_data: {},
            form_errors: {},
            is_loading: false,
            dispatch: null
        }
        this.login = login.bind(this);
    }
    componentDidMount() {
        document.title = "Login"
    }
    DummyView = () => {
        const dispatch = useDispatch();
        useEffect(() => {
            this.setState({ dispatch: dispatch })
        }, [])
        return null;
    }
    changeHandler = (event) => {
        let { form_data } = this.state;
        form_data[event.target.name] = event.target.value;
        this.setState({ form_data });
    }
    handleLogin = async (e) => {


        e.preventDefault();

        let { form_data, form_errors } = this.state;
        form_errors = {};
        if (!form_data.email) {
            form_errors.email = "Email is a requried field";
            this.setState({ form_errors })
            return;
        }
        else if (!form_data.password) {
            form_errors.password = "Password is a requried field";
            this.setState({ form_errors })
            return;

        }
        this.setState({ is_loading: true, form_errors });

        let response = await login(form_data.email, form_data.password, toast);

        if (response) {

            if (response.status == 200) {
                this.state.dispatch(
                    setLogin({
                        user: response.data.user,
                        token: response.data.token
                    })
                )

                window.location.href = '/home';
            }
            else if (response.response) {
                if (response.response.status == 401) {
                    form_errors.general_error = response.response.data.message
                }
            }

        }
        this.setState({ is_loading: false, form_errors });


    }


    render() {



        let { form_data, form_errors, is_loading } = this.state;
        return (

            <div className='h-[100vh] flex justify-center items-center'>

                <div className='w-full sm:w-1/3 '>
                    <div className='p-5 rounded-md bg-white dark:bg-gray-900 dark:border-gray-800 rounded-md  mx-2 sm:mx-0  border rounded-md'>
                        <div>
                            <Link to={'/'}><div className='text-xl font-semibold dark:text-gray-100'>Malawi Law Annotator</div></Link>
                            <div className='text-gray-500'>Sign in to your malawi law annotator account</div>
                        </div>
                        <div className='mt-8'>
                            <form action={(e) => this.handleLogin(e)}>
                                <div className='text-center text-sm text-red-500'>{form_errors.general_error}</div>
                                <div>
                                    <label className='dark:text-gray-100'>Email</label>
                                    <input name='email' value={form_data.email} onChange={this.changeHandler} class={"mt-2  appearance-none border border-gray-300 rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.email || form_errors.general_error ? ' border border-red-500' : ' ')} id="username" type="text" placeholder="Email" />
                                    <div className='text-red-500 text-sm'>{form_errors.email}</div>
                                </div>
                                <div className='my-3'>
                                    <label className='dark:text-gray-100'>Password</label>
                                    <input name='password' value={form_data.password} onChange={this.changeHandler} class={"mt-2  appearance-none border  border-gray-300 rounded w-full py-2.5 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.password || form_errors.general_error ? ' border border-red-500' : ' ')} id="password" type="password" placeholder='Password' />
                                    <div className='text-red-500 text-sm'>{form_errors.password}</div>
                                </div>

                                <div className='mt-6'>
                                    <button disabled={is_loading} onClick={(e) => this.handleLogin(e)} className='bg-blue-500 text-white p-2 rounded-md w-full text-xs hover:bg-blue-700'>{is_loading ? 'Authenticating...' : 'LOGIN'}</button>
                                </div>
                                <div className='my-5 text-end text-gray-500 '>
                                    <a href='/recover-password' className='text-sm block cursor-pointer'>Forgot Password?</a>
                                    <Link to={'/register'}><a className='text-sm'>Do not have an account ? Register now</a></Link>
                                </div>
                            </form>

                        </div>
                    </div>

                    <div className='text-center text-gray-500 text-xs mt-5 bottom-0'>&copy;2023 Malawi Law Annotator</div>

                </div>


                <ToastContainer />

                <this.DummyView />
            </div>

        )
    }
}
