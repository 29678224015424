import { ProgressSpinner } from 'primereact/progressspinner';
import React, { Component } from 'react'
import { generateOtp, verifyOtp } from '../../../../backend_handlers/registrationController';
import { ToastContainer, toast } from 'react-toastify';


export default class EmailVerify extends Component {

    constructor(props) {
        super(props);
        this.state = {

            current_page: 'otp',
            form_data: this.props.form_data,
            form_errors: {}
        }

        this.generateOtp = generateOtp.bind(this);
        this.verifyOtp = verifyOtp.bind(this);
    }
    componentDidMount() {
        this.sendOtpMail();

    }
    sendOtpMail = async () => {

        this.setState({ current_page: 'loading' });
         this.generateOtp(this.props.form_data, toast);
        this.setState({ current_page: 'otp' });

    }

    sendVerifyOtpRequest = async () => {

        this.setState({ current_page: 'loading' });

        let response = await this.verifyOtp(this.state.form_data, toast);

        if (response.status == 412 | response.status == 401) {
            let form_errors = {};
            form_errors['otp'] = response.data.message

            this.setState({ form_errors: form_errors });

        }
        else if (response.status == 200) {
            this.props.switchPage('choose_password');
        }

        this.setState({ current_page: 'otp' });


    }

    switchPage = (page) => {
        this.setState({ current_page: page })
    }
    renderCurrentPage() {

        switch (this.state.current_page) {
            case 'form':
                return this.renderForm();
                break;
            case 'loading':
                return this.renderLoading();

            case 'otp':
                return this.renderOtpForm();

            default:
                break;
        }
    }
    changeHandler = (e) => {
        let { form_data } = this.state;
        form_data[e.target.name] = e.target.value;
        this.setState({ form_data });
    }
    renderOtpForm() {
        let { form_data, form_errors } = this.state;
        console.log(form_data);


        return (
            <div>
                <div className=''>
                    <div>
                        <div className='text-gray-700 dark:text-gray-100'>Enter the 6-digit code</div>
                        <div className='text-gray-500 text-md'>The code was sent to <span className='font-semibold mr-1'>{form_data.email}</span> <span style={{ cursor: 'pointer' }} onClick={() => this.props.switchPage('form')} className='text-blue-500'>Change</span></div>
                        <input name='otp' value={form_data.otp} onChange={this.changeHandler} class={"mt-2  appearance-none border-gray-300 rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.otp || form_errors.general_error ? ' border border-red-500' : ' ')} id="otp" type="text" placeholder="X X X X X X" />
                        <div className='text-red-500 text-sm mt-1'>{form_errors.otp}</div>
                    </div>

                </div>

                <div className='flex justify-between mt-5'>
                    <div className='flex items-below gap-2'>
                        <button onClick={this.sendVerifyOtpRequest} className='bg-blue-700 px-4 text-gray-100 rounded-sm py-1.5 hover:bg-blue-500'>
                            Verify
                        </button>
                        <div className='hover:text-blue-700 text-blue-500 my-1 cursor-pointer' onClick={() => this.sendOtpMail()}>Resend Code</div>

                    </div>



                </div>
            </div>
        );
    }
    renderForm() {
        let { form_data, form_errors } = this.state;

        return (
            <div>
                <div className=''>
                    <div>
                        <div className='dark:text-gray-100'>Your Email</div>
                        <input name='email' value={form_data.email} onChange={this.changeHandler} class={"mt-2  appearance-none border-gray-300 rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.email || form_errors.general_error ? ' border border-red-500' : ' ')} id="username" type="text" placeholder="Email" />
                        <div className='text-red-500 text-sm'>{form_errors.email}</div>
                    </div>

                </div>

                <div className='flex justify-between mt-2'>
                    <button onClick={() => this.switchPage('loading')} className='bg-blue-700 px-4 text-gray-100 rounded-sm py-1.5 hover:bg-blue-500'>
                        Verify
                    </button>
                    <div className='text-end text-sm text-gray-500 mt-2'>
                        [ 3 of 3 ]
                    </div>
                </div>
            </div>
        );
    }
    renderLoading() {
        return (
            <div className='my-2 flex justify-center border border-gray-300 dark:border-gray-800'>
                <div className='flex justify-between items-center'>
                    <div>
                        <ProgressSpinner style={{ width: '30px' }} />
                    </div>

                    <div className='text-sm mx-2  text-gray-500'>Processing. Please wait</div>
                </div>
            </div>
        );
    }
    render() {


        return (
            <div>
                <div className=''>

                    <div className='my-5'>
                        {this.renderCurrentPage()}
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}
