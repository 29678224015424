import { Component, createRef } from "react"
import Page from "../../components/page"
import LeftBar from "../sections/index/left-bar";
import RightBar from "../sections/index/right-bar";
import JudgementUpload from "../sections/judgements/judgement_upload";
import JudgementSearch from "../sections/judgements/judgement_search";
import axios from "axios";
import JudgementListing from "../sections/index/judgement-listings";
import { ToastContainer, toast } from "react-toastify";
import JudgementSideBar from "../sections/judgements/judgement_side_bar";
import { Avatar } from "primereact/avatar";
import { getInitials } from "../../utils/utils";
import { fetchJudgementTags } from "../../backend_handlers/judgementTagController";




export default class JudgementsPage extends Page {


    constructor(props) {
        super(props);
        this.state = {
            judgements: [],
            judgement_tags: [],
            applied_tags: [],
            is_loading: true,
            is_loading_tags: true,
            selected_judgement: {},
            current_side_control: 'tags',
            active_judgement: {}

        }

        this.getInitials = getInitials.bind(this);
        this.fetchJudgementTags = fetchJudgementTags.bind(this);

    }
    showNotes = (judgement) => {
        this.setState({ active_judgement: judgement, current_side_control: 'notes' });

    }

    applyTag = (tag) => {

        this.setState({ applied_tags: [...this.state.applied_tags, tag.tag] });
        tag.checked = true;
    }
    removeAppliedTag = (tag) => {
        let { applied_tags } = this.state;

        let newTags = applied_tags.filter(item => item != tag.tag);

        this.setState({ applied_tags: newTags });
        tag.checked = false;
    }
    clearAllAppliedTags = () => {
        this.setState({ applied_tags: [] })

        this.state.judgement_tags.map((item, index) => {
            item.checked = false;
        })


    }

    renderTopToolBar() {
        return (
            <div className="flex justify-end items-center mt-2">
                <div>
                    <input type="text" className="text-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="search withing the text " />
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.fetchJudgements()
        document.title = "Judgements"
        this.loadJudgementTags();
    }
    fetchJudgements = async () => {

        this.setState({ is_loading: true });

        try {

            let url = `${process.env.REACT_APP_URL}/api/judgements`;
            let response = await axios.get(url);

            if (response.status == 200) {
                this.setState({ judgements: response.data });
            }

        } catch (error) {
            console.log(error);
        }
        this.setState({ is_loading: false });
    }
    loadJudgementTags = async () => {

        this.setState({ is_loading_tags: true })
        let response = await fetchJudgementTags(toast);
        if (response?.status == 200) {
            this.setState({ judgement_tags: response.data });
        }

        this.setState({ is_loading_tags: false })
    }
    getParentFunctions() {
        return {
            fetchJudgements: this.fetchJudgements,
            addTag: this.addTag,
            applyTag: this.applyTag,
            removeAppliedTag: this.removeAppliedTag,
            clearAllAppliedTags: this.clearAllAppliedTags,
            showNotes: this.showNotes
        }
    }
    addTag = (tag) => {
        this.setState({ judgement_tags: [...this.state.judgement_tags, tag] });

    }
    switchSideControl = (control) => {
        this.setState({ current_side_control: control });
    }

    renderBody() {

        return (

            <div className="">

                <div className="sm:flex justify-between  sm:pl-10">

                    <div className="sm:w-4/5 sm:mr-3">
                        <JudgementListing toast={toast} parent_functions={this.getParentFunctions()} parent_state={this.state} />
                    </div>

                    <div className="rounded-md w-full sm:w-1/5 ">
                        <JudgementSideBar parent_functions={this.getParentFunctions()} parent_state={this.state} />
                    </div>

                    <div className="fixed border ml-2 px-1 top-0 bg-white  flex flex-col h-screen  gap-3  sticky">
                        <div className="p-2 mb-5">
                            <Avatar
                                label={this.getInitials(
                                    this.state.user ? this.state.user.name : "Guest User"
                                )}
                                size=""
                                style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
                                shape="circle"
                            />
                        </div>
                        <div
                            onClick={() => this.switchSideControl("tags")}
                            className={
                                "h-fit cursor-pointer border flex items-center justify-center p-2  " +
                                (this.state.current_side_control == "tags" || this.state.current_side_control == 'tags'
                                    ? " border-r-blue-500 border-r-4 "
                                    : "")
                            }
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class={"w-5 h-5 " + (this.state.current_side_control == 'tags' ? ' text-blue-500 ' : '')}>
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                            </svg>

                        </div>
                        <div
                            onClick={() => this.switchSideControl("notes")}
                            className={
                                "h-fit cursor-pointer border flex items-center justify-center p-2  " +
                                (this.state.current_side_control == "notes"
                                    ? " border-r-blue-500 border-r-4 "
                                    : "")
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class={"w-5 h-5 " + (this.state.current_side_control == 'notes' ? ' text-blue-500 ' : '')} >

                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                                />
                            </svg>
                        </div>

                        {this.state.user && this.state.user?.role == 'admin' ?
                            < div
                                onClick={() => this.switchSideControl("upload")}
                                className={
                                    "h-fit cursor-pointer border flex items-center justify-center p-2  " +
                                    (this.state.current_side_control == "upload"
                                        ? " border-r-blue-500 border-r-4 "
                                        : "")
                                }
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class={"w-5 h-5 " + (this.state.current_side_control == 'upload' ? ' text-blue-500 ' : '')} >
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                                </svg>

                            </div>
                            : ''}

                    </div>


                    <ToastContainer />

                </div>
            </div >

        )
    }

}

document.title = "Judgements"


