import React, { Component, createRef } from 'react'


export default class ReferenceViewDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_visible: false,
            reference: {}
        }

    }
    show = (reference) => {

        let ref;
        if (Array.isArray(reference)) {
            ref = reference[0]
        }
        else {
            ref = reference;
        }
        this.setState({ is_visible: true, reference: ref });
    }
    hide = () => {
        this.setState({ is_visible: false, reference: {} });
    }

    htmlDecode(input) {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
    render() {

        console.log(this.state.reference);
        return (
            <div className={`${this.state.is_visible ? 'block' : 'hidden'} bg-opacity-60 h-screen w-screen z-10 bg-gray-500 fixed top-0 left-0`}>

                <div name="reference_vewi_dilaog" className={'overflow-x-hidden overflow-y-auto bg-white dark:bg-gray-900 shadow-lg border dark:border-gray-800 w-4/5   sm:w-3/5  sm:h-1/2' + (this.state.is_visible == true ? ' block ' : ' hidden ')} style={{ zIndex: '9000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}  >
                    <div className='sticky top-0 dark:bg-gray-800 px-2 dark:border-gray-700 bg-gray-200 p-2 flex justify-between items-center items-center '>

                        <div className='flex items-center'>
                            <div className='text-blue-500'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                                </svg>

                            </div>
                            <div className='ml-2 dark:text-gray-200'>
                                <div >{this.state.reference ? this.state.reference?.annotation : 'Annotation Reference'}</div>
                                <div className='text-gray-500 text-sm '>{this.state?.reference?.name}</div>
                            </div>
                        </div>
                        <div onClick={() => this.hide()} className='text-gray-500' style={{ cursor: 'pointer' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>

                        </div>

                    </div>


                    <div className={'px-5 py-2 h-full w-full pb-5 '}>
                        {this.state.reference ? <div dangerouslySetInnerHTML={{ __html: `${this.state.reference?.reference}` }} /> :

                            <div className='flex justify-center h-full w-full items-center'>
                                <div className='text-center text-lg    text-gray-500'>
                                    No reference notes for this annotation were found
                                </div>
                            </div>
                        }

                    </div>


                </div>

            </div>
        )
    }
}
