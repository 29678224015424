import { ProgressSpinner } from 'primereact/progressspinner';
import React, { Component } from 'react'
import { updateAnnotation } from '../backend_handlers/AnnotationController';
import { toast, ToastContainer } from 'react-toastify';
export default class TagConflictDialog extends Component {

    constructor(props) {

        super(props);
        this.state = {
            visible: false,
            message: '',
            form_data: {},
            current_page: 'home',
            matchResults: {}
        }
        this.updateAnnotation = updateAnnotation.bind(this);
    }
    switchPage = (page) => {
        this.setState({ current_page: page });
    }
    changeHandler = (e) => {
        let { form_data } = this.state;

        form_data[e.target.name] = e.target.value;
        console.log(form_data);
        this.setState({ form_data });
    }
    renderCurrentPage() {
        switch (this.state.current_page) {
            case 'home':
                return this.renderHome()


            case 'merge':
                return this.renderMerge();

            case 'loading':
                return this.renderLoading();

            default:
                break;
        }
    }
    initCreateAnnotation() {
        this.close();
        this.props.initNewAnnotation(this.state.selectedText, this.state.paragraph, this.state.x, this.state.y);

    }
    renderHome() {
        return (
            <div>
                <div className='  p-2 bg-gray-200 dark:bg-gray-800 dark:text-gray-100 flex justify-between'>
                    <div class="font-semibold">Annotation Conflict Dialog</div>
                    <div className='text-gray-500 hover:text-gray-200 hover:bg-gray-400 p-1 rounded-sm' onClick={() => this.close()} style={{ cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className=' p-2 px-3 border-b text-gray-800 bg-white dark:text-gray-100 dark:bg-gray-900 dark:border-b-gray-700'>
                    <div>The highlighted text includes the following annotations:</div>
                    <div className='my-2 '>
                        {this.state.matchResults?.matches?.map((item, index) => {
                            return <div className='p-2 border'>{index + 1}. {item.annotation}</div>
                        })}
                    </div>

                    <div className='mt-1'>You can choose to extend the existing annotation or create a new one with the selected text</div>
                </div>
                <div className='flex justify-end py-1 px-2 '>
                    <button onClick={() => this.switchPage('merge')} className='hover:bg-blue-700 bg-blue-500 px-4 py-1 text-sm rounded-sm text-white mx-1 '>Extend</button>
                    <button onClick={() => this.initCreateAnnotation()} className='hover:bg-gray-300 bg-gray-100 border px-4 py-1 text-sm rounded-sm text-blue-500 mx-1'>Create New</button>
                </div>
            </div>

        )

    }
    renderMerge() {


        return (
            <div>
                <div className='  p-2 bg-gray-200 dark:bg-gray-800 dark:text-gray-100 flex justify-between'>
                    <div class="font-semibold">Extend annotation</div>
                    <div className='text-gray-500 hover:text-gray-200 hover:bg-gray-400 p-1 rounded-sm' onClick={() => this.close()} style={{ cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className='bg-white p-3'>
                    <div>Choose the annotation you would like to extend</div>
                    <div className='my-2 '>
                        {this.state.matchResults?.matches?.map((item, index) => {
                            return (
                                <div className='flex p-2 border justify-between items-center'>
                                    <div>
                                        <div>{index + 1}. {item.annotation}</div>
                                        <div className='text-gray-500 text-sm'>{this.state.selectedText}</div>
                                    </div>

                                    <div>
                                        <input name='annotation' onChange={this.changeHandler} value={item.id} className='' type='radio' />
                                    </div>
                                </div>)
                        })}
                    </div>
                </div>
                <div className='flex justify-end py-1 px-2 '>
                    <button onClick={() => this.handleMerge()} className='hover:bg-blue-700 bg-blue-500 px-4 py-1 text-sm rounded-sm text-white mx-1 '>Save</button>
                    <button onClick={() => this.initCreateAnnotation()} className='hover:bg-gray-300 bg-gray-100 border px-4 py-1 text-sm rounded-sm text-blue-500 mx-1'>Cancel</button>
                </div>

            </div>
        )
    }
    renderLoading() {
        return (
            <div>
                <div className='p-2 bg-gray-200 dark:bg-gray-800 dark:text-gray-100 flex justify-between'>
                    <div class="font-semibold">Extend annotation</div>
                    <div className='text-gray-500 hover:text-gray-200 hover:bg-gray-400 p-1 rounded-sm' onClick={() => this.close()} style={{ cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className='bg-white p-3 py-5'>
                    <div className='border p-2 text-center'>
                        <ProgressSpinner style={{ height: '40px', width: '40px' }} />
                        <div className='text-gray-500'>Extending annotation. Please wait</div>
                    </div>
                </div>
            </div>
        )
    }
    handleMerge = async () => {
        let { form_data } = this.state;

        this.setState({ current_page: 'loading' });

        try {

            let annotation;
            this.state.matchResults.matches.map((item, index) => {
                if (item.id == form_data.annotation) {
                    annotation = item;
                }
            })

            let response = await updateAnnotation(annotation.id, annotation.tag_id, toast, this.state.selectedText)
            
            if (response.status == 200) {

                let annotation = response.data;
                annotation.annotation['checked'] = true;
                this.props.parent_functions.updateAnnotation(annotation);
                

                let tag = this.props.parent_state.tags.find(Obj => Obj.id == annotation.annotation.tag_id);
                tag.expanded = true;
                this.props.parent_functions.updateTag(tag);
            }
        } catch (error) {
            console.log(error);
        }

        this.close();


    }
    show(matchResults, selectedText, paragraph, e) {
        this.setState({ current_page: 'home', visible: true, matchResults: matchResults, selectedText: selectedText, paragraph: paragraph, x: e.clientX, y: e.clientY });
    }
    close() {
        this.setState({ visible: false });

    }
    onConfirm() {
        this.close();
        this.state.confirmedCallback();
    }
    render() {

        return (
            <div className={`${this.state.visible ? 'block' : 'hidden'} bg-opacity-50 h-screen w-screen z-10 bg-gray-500 fixed top-0 left-0`}>

                <div className={' overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-800 shadow-lg border dark:border-gray-800  w-4/5  sm:w-1/3 sm:h:1/2' + (this.state.visible == true ? ' block ' : ' hidden ')} style={{ boxShadow: '0px 0px 40px 4px rgba(0,0,0,0.6)', zIndex: '9000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}  >
                    {this.renderCurrentPage()}
                </div>

            </div>
        )
    }
}
