import axios from "axios";


export async function createReference(form_data, toast) {

    try {

        let response = await axios.post(`${process.env.REACT_APP_URL}/api/references`, { 'annotation': form_data.annotation, 'reference': form_data.reference });
        toast.success("Reference created successfully", {
            position: toast.POSITION.TOP_RIGHT
        });
        return response;
    }
    catch (error) {

        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error("There was an error while creating a reference", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        return error;


    }
}
export async function getReferences(toast) {
    try {
        let response = await axios.get(`${process.env.REACT_APP_URL}/api/references`);
        return response;
    }
    catch (error) {
        console.log(error);
        toast.error("There was an error while fetching references", {
            position: toast.POSITION.TOP_RIGHT
        });

    }
}
export async function getAnnotationReference(annotation, toast) {

    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/references/getByAnnotation`, { annotation: annotation });
        return response;

    } catch (error) {
        console.log(error);
        toast.error("There was an error while fetching references", {
            position: toast.POSITION.TOP_RIGHT
        });

    }

}