import React, { Component, createRef } from "react";
import JudgementTags from "./judgement_tags";
import ParagraphNotes from "./paragraph_notes";
import JudgementInfo from "./judgement_info";
import JudgementPrint from "./judgement_print";
import CrossReferences from "./cross_references";
import NoteContextDialog from "../../../components/noteContextMenu";

export default class JudgementSideControls extends Component {
  constructor(props) {
    super(props);

    this.state = {};


   
  }
  componentDidMount() {
    this.props.parent_functions.refreshViewPageDialog();
  }
  renderCurrentPage = () => {
    switch (this.props.parent_state.current_side_control) {
      case "tags":
        this.props.parent_functions.refreshViewPageDialog();
        return (
          <JudgementTags
            parent_state={this.props.parent_state}
            parent_functions={this.props.parent_functions}
          />
        );
      case "notes":
        return (
          <ParagraphNotes
            parent_functions={this.props.parent_functions}
            parent_state={this.props.parent_state}
          />
        );
        break;

      case "info":
        this.props.parent_functions.refreshViewPageDialog();
        return (
          <JudgementInfo
            parent_functions={this.props.parent_functions}
            parent_state={this.props.parent_state}
          ></JudgementInfo>
        );

      case "print":
        return <JudgementPrint parent_functions={this.props.parent_functions} parent_state={this.props.parent_state} />;

      case 'references':
        return <CrossReferences parent_state={this.props.parent_state} parent_functions={this.props.parent_functions} />
      default:
        break;
    }
  };

  render() {
    return (<div className="sticky top-0">
      < div className="" > {this.renderCurrentPage()}</div >
    

    </div>)

  }
}
