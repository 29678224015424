import { ProgressSpinner } from "primereact/progressspinner";
import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import { RadioButton } from "primereact/radiobutton";
import { createNote, updateNote } from "../backend_handlers/notesController";

export default class NewNotesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      message: "",
      form_data: {
        access: "Private",
      },
      form_errors: {},
      current_page: "loading",
    };

    this.createNote = createNote.bind(this);
    this.updateNote = updateNote.bind(this);
  }
  switchPage = (page) => {
    this.setState({ current_page: page });
  };
  changeHandler = (e) => {
    let { form_data } = this.state;

    form_data[e.target.name] = e.target.value;
    console.log(form_data);
    this.setState({ form_data });
  };
  renderCurrentPage() {
    if (this.state.current_page === "home") {
      return this.renderHome();
    } else {
      return this.renderLoading();
    }
  }

  save = async () => {
    let { form_data } = this.state;
    if (!form_data.note) {
      let { form_errors } = this.state;
      form_errors["note"] = "Required field";
      this.setState({ form_errors });
      return;
    }

    if (this.props?.type == 'judgement') {
      form_data['type'] = 'judgement';
    }
    else {
      form_data['type'] = 'paragraph';
    }


    this.setState({ form_errors: {} });

    this.setState({ current_page: "loading" });

    if (this.state.mode == "create") {
      form_data.user_id = this.props.user.id;

      if (form_data.type == 'paragraph')
        form_data.paragraph_id = this.props.parent_state.selected_paragraph.id;

      let response = await createNote(form_data, toast);
      if (response.status == 200) {
        if (form_data.type == 'paragraph') {
          this.props.parent_functions.addNote(
            this.props.parent_state.selected_paragraph,
            response.data
          );
        }
      }
    } else {
      let response = await updateNote(form_data, toast);
      this.props.parent_functions.updateNote(response.data);
    }

    this.setState({ visible: false });
  };
  renderHome() {
    return (
      <div>
        <div className="my-1">
          <label>
            Note Label
            <span className="text-xs text-gray-500 ml-1">(optional)</span>
          </label>
          <input
            value={this.state.form_data.label}
            placeholder="type optional label for the note"
            type="text"
            name="label"
            onChange={this.changeHandler}
            className={
              "mt-1 border p-1 bg-white rounded w-full border-gray-300  text-sm p-2" +
              (this.state.form_errors.label ? " border border-red-500 " : " ")
            }
          />
          <div className="tetx-sm text-red-500">
            {this.state.form_errors.label}
          </div>
        </div>

        <div className="my-1">
          <label>Note </label>
          <textarea
            value={this.state.form_data.note}
            placeholder="Type note content here"
            type="text"
            name="note"
            onChange={this.changeHandler}
            className={
              "mt-1 border p-1 bg-white rounded w-full border-gray-300 text-sm p-2 " +
              (this.state.form_errors.note ? " border border-red-500 " : " ")
            }
          />
          <div className="tetx-sm text-red-500">
            {this.state.form_errors.note}
          </div>
        </div>

        <div className="my-1">
          <label>Accessibillity </label>
          <div className="text-sm text-gray-500">
            Choose wheather this note should be public or private
          </div>
          <div className="flex my-1  py-1 px-1 flex-wrap gap-3">
            <div className="flex  items-center mb-1">
              <RadioButton
                inputId="access1"
                name="access"
                value="Private"
                onChange={(e) => this.changeHandler(e)}
                checked={this.state.form_data.access === "Private"}
              />

              <div className="ml-2">
                <label htmlFor="ingredient1" className="">
                  Private
                </label>
                <div className="text-xs text-gray-500">
                  This note will be visible to you only
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <RadioButton
                inputId="access2"
                name="access"
                value="Public"
                onChange={(e) => this.changeHandler(e)}
                checked={this.state.form_data.access === "Public"}
              />
              <div className="ml-2">
                <label htmlFor="ingredient1" className="">
                  Public
                </label>
                <div className="text-xs text-gray-500">
                  This note will be visible to all{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderLoading() {
    return (
      <div className="p-10 border flex items-center justify-center">
        <div>
          <div className="flex justify-center">
            {" "}
            <ProgressSpinner style={{ height: "30px", width: "30px" }} />
          </div>
          <div className="text-gray-500 text-sm">
            Saving note. Please wait...
          </div>
        </div>
      </div>
    );
  }

  show(mode, note = null, ref_id = null) {

   
    if (mode == "create") {
      this.setState({
        mode: mode,
        current_page: "home",
        visible: true,
        form_errors: {},
        form_data: {  judgement_id: ref_id,access: "Private", label: "", note: "" },
      });
    } else {
      let form_data = {
        note_id: note.id,
        label: note.label ? note.label : "",
        note: note.note,
        access: note.access,
      };
      console.log(form_data);
      this.setState({
        mode: "update",
        current_page: "home",
        visible: true,
        form_errors: {},
        form_data: form_data,
      });
    }
  }
  close() {
    this.setState({ visible: false, form_data: {} });
  }
  onConfirm() {
    this.close();
    this.state.confirmedCallback();
  }
  render() {
    return (
      <div
        className={`${this.state.visible ? "block" : "hidden"
          } bg-opacity-50 h-screen w-screen z-10 bg-gray-500 fixed top-0 left-0`}
      >
        <div
          className={
            "bg-gray-100 dark:bg-gray-800 shadow-lg border dark:border-gray-800  w-4/5  sm:w-1/3" +
            (this.state.visible == true ? " block " : " hidden ")
          }
          style={{
            boxShadow: "0px 0px 40px 4px rgba(0,0,0,0.6)",
            zIndex: "9000",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <div className="p-2 bg-gray-200 dark:bg-gray-800 dark:text-gray-100 flex justify-between">
            <div class="font-semibold">{this.props?.type == 'judgement' ? 'Judgement' : 'Paragraph'} Note</div>
            <div
              className="text-gray-500 hover:text-gray-200 hover:bg-gray-400 p-1 rounded-sm"
              onClick={() => this.close()}
              style={{ cursor: "pointer" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <div className="p-2 px-3 border-b text-gray-800 bg-white dark:text-gray-100 dark:bg-gray-900 dark:border-b-gray-700">
            {this.renderCurrentPage()}
          </div>

          {this.state.current_page == "home" ? (
            <div className="flex bg-gray-100 justify-end py-1 px-2 ">
              <button
                onClick={() => this.save()}
                className="hover:bg-blue-700 bg-blue-500 px-4 py-1 text-sm rounded-sm text-white mx-1 "
              >
                Save
              </button>
              <button
                onClick={() => this.close()}
                className="hover:bg-gray-300 bg-gray-100 border px-4 py-1 text-sm rounded-sm text-blue-500 mx-1"
              >
                Close
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
