import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div className="pt-4  mt-auto   bg-gray-700 px-3 text-white sm:px-20 bg-gray-100   dark:bg-gray-900">
                <div className="sm:flex  justify-center items-center">
                    <div className="w-full text-center">
                        <div className="">
                            <div className="font-bold text-xl text-black-700 dark:text-gray-200">Malawi Laws Data Practitioner </div>
                        </div>
                        <div>
                            <div className="text-md text-gray-400 text-center ">
                                <div className=" ">A joint project between tNyasa Ltd and KuyeseraAI Lab</div>

                                <div className="flex justify-center gap-5">
                                    <div className="flex text-center  items-center mt-1">

                                        <div className="bg-blue-500 twxt-white p-1.5 mr-1 rounded-full">
                                        </div>

                                        <div className="">info@tnyasa.com</div>
                                    </div>

                                </div>


                            </div>
                        </div>

                    </div>

                </div>
                <div className="border-t text-center py-1 border-gray-600 mt-4 pb-2">
                    <div className="text-gray-500">&copy; {(new Date().getFullYear())} Malawi Law Annotator</div>
                </div>

            </div>
        );
    }

}
