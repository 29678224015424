import React, { Component, useEffect } from "react";
import { printPdf } from "../../../backend_handlers/JudgementController";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

export default class JudgementPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_tags: [],
      selected_notes: [],
      selected_tag: null,
      is_notes_included: false,
      is_tags_included: true,
      notes_type: "all",
      is_printing: false,
      current_page: 'chooser'
    }

    this.printPdf = printPdf.bind(this);
  }
  componentDidMount() {
    let tags = this.props.parent_state.tags.filter(
      (tag) =>
        tag.name == "LAW" ||
        tag.name == "LAWNAME" ||
        tag.name == "CASE REFERENCE"
    );
    this.setState({ selected_tags: tags });
  }

  toggleNotes = () => {
    this.setState({ is_notes_included: !this.state.is_notes_included });
  };
  toggleAnnotations = () => {
    this.setState({ is_tags_included: !this.state.is_tags_included });
  };
  removeSelectedTag = (tag) => {
    let newTags = this.state.selected_tags.filter((Obj) => Obj.id != tag.id);
    this.setState({ selected_tags: newTags });
  };
  addSelectedTag = (tag) => {
    if (tag) {
      tag = this.props.parent_state.tags.find((Obj) => Obj.id == tag);

      let { selected_tags } = this.state;

      selected_tags = [...selected_tags, tag];
      this.setState({ selected_tags, selected_tag: null });
    }
  };

  renderSelectedTags() {
    return (
      <div>
        <div className="border p-2">
          {this.state.selected_tags.length == 0 ? (
            <div className="p-10 text-gray-500 text-sm text-center">
              No tags selected
            </div>
          ) : (
            <div className="flex gap-2 py-2 flex-wrap">
              {this.state.selected_tags.map((tag, index) => {
                return (
                  <div
                    className="text-sm rounded items-center  flex justify-between  px-1  text-white"
                    style={{ backgroundColor: `${tag.color}` }}
                  >
                    {tag.name}
                    <svg
                      onClick={() => this.removeSelectedTag(tag)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="cursor-pointer w-5 ml-3 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="flex justify-between items-center my-1">
          <select
            onChange={(e) => this.setState({ selected_tag: e.target.value })}
            className="border text-sm border-gray-200  p-1  py-1.5 text-gray-500 w-full "
          >
            <option>Choose Tag</option>
            {this.props.parent_state.tags.map((item, index) => {
              if (!this.state.selected_tags.includes(item)) {
                return <option value={item.id}>{item.name}</option>;
              }
            })}
          </select>
          <button

            onClick={() => this.addSelectedTag(this.state.selected_tag)}
            className="bg-blue-800 text-white  p-1   ml-1 hover:bg-blue-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  }
  renderSelectedNotes() {
    return (
      <div>
        <div className=" ">
          <div className="text-gray-500 text-sm">
            Which notes would you like to include in the printout
          </div>

          <div className="my-2">
            <div className="flex border py-2 px-1 items-center text-gray-700 text-sm">
              <input
                value={"me"}
                onChange={() => this.setState({ notes_type: "me" })}
                checked={this.state.notes_type == "me" ? true : false}
                className="mr-1"
                type="radio"
              />
              My notes only
            </div>
            <div className="border mt-1 flex py-2 px-1 items-center text-gray-700 text-sm">
              <input
                onChange={() => this.setState({ notes_type: "all" })}
                value={"all"}
                checked={this.state.notes_type == "all" ? true : false}
                name="notes_type"
                className="mr-1"
                type="radio"
              />
              All notes
            </div>
          </div>
        </div>
      </div>
    );
  }
  async initiatePrint(print_mode) {

    let print_data = {
      judgement: this.props.parent_state.judgement.id,
      notes_type: this.state.notes_type,
      selected_tags: this.state.selected_tags,
      is_notes_included: this.state.is_notes_included,
      is_tags_included: this.state.is_tags_included,
      notes_type: this.state.notes_type,
      user_id: this.props.parent_state.user.id,
      print_mode: print_mode
    }

    this.setState({ is_printing: true });

    await this.printPdf(print_data, toast);

    this.setState({ is_printing: false });
  }
  renderChooser = () => {

    return (
      <div>
        <div className="mb-2">Choose Print option</div>
        <div onClick={() => this.setState({ current_page: 'judgement' })} className="bg-gray-200 items-center flex justify-between p-2 pl-1.5 cursor-pointer  w-full ">
          <div>
            Judgement Text
            <div className="text-gray-500 text-sm">Judgement, annotations, notes</div>
          </div>

          <div className="rounded-full hover:bg-gray-300 hover:shadow-lg p-1.5 bg-gray-200">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 ml-0.5 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </div>

        </div>

        <div onClick={() => this.setState({ current_page: 'annotations' })} className="bg-gray-200 p-2 pl-1.5 rounded-md justify-between  mt-1 flex items-center  cursor-pointer    w-full ">
          <div>
            Judgement Annotations
            <div className="text-gray-500 text-sm">Annotations, judgements</div>
          </div>
          <div className="rounded-full hover:bg-gray-300 hover:shadow-lg p-1.5 bg-gray-200">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 ml-0.5 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </div>
        </div>
      </div>

    )

  }
  renderJudgementPrint = () => {
    return (<div className="my-5">
      <div className="flex items-center ">
        <svg onClick={() => this.setState({ current_page: 'chooser' })} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 cursor-pointer mr-1 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>


        Print Judgement Text
      </div>

      <div className="mt-3">
        <div className="">
          <div className="flex  items-center justify-between">
            <div className="text-gray-800 my-1">Include annotations</div>
            <input
              checked={this.state.is_tags_included}
              onChange={() => this.toggleAnnotations()}
              type="checkbox"
              className="p-1 border rounded"
            />
          </div>

          {this.state.is_tags_included ? this.renderSelectedTags() : ""}
        </div>

        <div className="mt-7">
          <div className="flex  items-center justify-between">
            <div className="text-gray-800 my-1">Include Notes</div>
            <input
              checked={this.state.is_notes_included}
              onChange={() => this.toggleNotes()}
              type="checkbox"
              className="p-1 border rounded"
            />
          </div>

          {this.state.is_notes_included ? this.renderSelectedNotes() : ""}
        </div>

        <div className="flex justify-end mt-5">
          {this.state.is_printing ?

            <div className="flex mr-1  p-5">
              <ProgressSpinner style={{ width: '25px', height: '25px' }} />
              <div className="ml-1 items-center text-gray-500 text-sm">Printing. Please wait..</div>
            </div>

            :
            <button
              onClick={() => this.initiatePrint('judgement_text')}
              className="flex w-full items-center bg-blue-800 items-center justify-center text-white p-1 rounded px-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 mx-2 text-white h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
                />
              </svg>
              Print
            </button>

          }

        </div>
      </div>
    </div>)
  }
  renderPrintAnnotations = () => {
    return (
      <div className="my-5">
        <div className="flex items-center ">
          <svg onClick={() => this.setState({ current_page: 'chooser' })} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 cursor-pointer mr-1 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
          </svg>
          Print Judgement Annotations
        </div>
        <div className="mt-2">
          <div className="text-gray-500">
            This will print all annotations in this judgement, including references to all other judgements where this annotation appears
          </div>

          {this.state.is_printing ?

            <div className="flex mr-1  p-5">
              <ProgressSpinner style={{ width: '25px', height: '25px' }} />
              <div className="ml-1 items-center text-gray-500 text-sm">Printing. Please wait..</div>
            </div> :
            <button
              onClick={() => this.initiatePrint('judgement_annotations')}
              className="mt-3 flex w-full items-center bg-blue-800 items-center justify-center text-white p-1 rounded px-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 mx-2 text-white h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
                />
              </svg>
              Print
            </button>
          }
        </div>
      </div>
    )
  }
  renderCurrentPage() {
    switch (this.state.current_page) {
      case 'chooser':
        return this.renderChooser();

      case 'judgement':
        return this.renderJudgementPrint();

      case 'annotations':
        return this.renderPrintAnnotations();

      default:
        break;
    }
  }


  render() {
    return (
      <div className="sticky top-0  px-1  py-3 mr-1 dark:text-gray-100  dark:border-gray-700">
        <div className=" bg-dark:grey-800  mb-3 flex justify-between items-center dark:border-gray-700 ">
          <div>
            <div className=" dark:text-gray-200 dark:font-semibold text-lg ">
              Judgement Print
            </div>
            <div className="text-gray-500 text-xs ">
              Print judgement text and annotations to pdf
            </div>
          </div>
        </div>

        <div>
          {this.renderCurrentPage()}
        </div>

      </div>
    )
  }
}
