import React, { Component } from 'react'
import { createTag } from '../backend_handlers/TagController'
import { toast } from 'react-toastify';
import { ProgressSpinner } from 'primereact/progressspinner';

export default class NewTagDialog extends Component {
    constructor(props) {
        super(props);
        this.createTag = createTag.bind(this);

        this.state = {
            form_data: {},
            form_errors: {},
            is_visible: false,
            is_loading: false,
            judgement: {}
        }
    }
    changeHandler = (e) => {
        let { form_data } = this.state;
        form_data[e.target.name] = e.target.value;
        this.setState({ form_data });
    }
    show = (judgement) => {
        this.setState({ is_visible: true, judgement: judgement });
    }
    async handleCreateTag() {

        let { form_data, judgement } = this.state;

        if (!form_data.name || form_data.name === "") {
            let { form_errors } = this.state;
            form_errors.name = 'Name can not be empty';
            this.setState({ form_errors });
        }
        else {
            this.setState({ is_loading: true });
            let response = await createTag(form_data.name, judgement.id, toast);

            if (response.status == 200) {
                this.setState({ is_loading: false, is_visible: false, form_errors: {}, form_data: {} });
                this.props.parent_functions.refreshTags();

            }
            else{
                this.setState({is_visible:false, is_loading:false, form_data:{},form_errors:{}});
            }

        }

    }
    renderCreateFormBody = () => {
        let { form_data, form_errors } = this.state;
        return (
            <div className='px-3 py-3 dark:bg-gray-900 bg-gray-100'>
                <div className='flex  items-center'>
                    <div className='w-4/5'>
                        <input name='name' value={form_data.name} onChange={this.changeHandler} class={"border-gray-300 rounded w-full py-1.5 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.name ? ' border-red-500 ' : ' ')} type="text" placeholder="" />
                    </div>
                    <button name='tag_item_save_btn' onClick={() => this.handleCreateTag()} className='w-1/4 bg-blue-500 ml-1 text-white px-3 py-1.5 text-sm rounded-md hover:bg-blue-800'>
                        Save
                    </button>
                </div>
                <div className='text-red-500 text-sm'>{form_errors.name}</div>
            </div>
        )
    }
    handleOnClose = (e) => {
        if (!this.state.is_loading) {
            this.setState({ is_visible: false, form_data: {}, form_errors: {} });
        }
    }
    renderLoading() {
        return (
            <div className='p-2 bg-gray-100 dark:bg-gray-900  flex justify-center'>
                <div className='text-center'>
                    <ProgressSpinner style={{ width: '40px', height: '40px' }} />
                    <div className='text-gray-800  dark:text-gray-500 text-sm'>Creating tag. Please wait...</div>
                </div>

            </div>
        );
    }
    render() {

        return (
            <div name="new_tag_dialog" className={'bg-white dark:bg-gray-800 shadow-lg border dark:border-gray-700 w-4/5  sm:w-1/5' + (this.state.is_visible == true ? ' block ' : ' hidden ')} style={{ zIndex: '9000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}  >
                <div className='p-2 bg-gray-200 dark:bg-gray-800 dark:text-gray-100 flex justify-between'>
                    <div>Create New Tag</div>
                    <div className='text-gray-500' onClick={() => this.handleOnClose()} style={{ cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                {this.state.is_loading ?
                    this.renderLoading() :
                    this.renderCreateFormBody()
                }


            </div>
        )
    }
}
