import React, { Component } from 'react'
import NavBar from '../../components/navbar';
import LineChart from '../../components/lineChart';
import { ToastContainer, toast } from 'react-toastify';
import { getAdminStats } from '../../backend_handlers/StatsController';

export default class AdminHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stats: {}
        }
        this.getAdminStats = getAdminStats.bind(this);
    }
    componentDidMount() {
        this.fetchStats()
    }
    async fetchStats() {

        let response = await getAdminStats(toast);
        console.log(response);
        if (response) {
            if (response.status == 200) {
                this.setState({ stats: response.data });
            }
        }
    }

    renderTopCards() {

        let { stats } = this.state;
        return (
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-2'>
                <div className='bg-white p-2 border  rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100'>
                    <div className='py-5 px-2 flex justify-between '>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 text-blue-500 h-12 bg-gray-200 dark:bg-gray-900 rounded-full p-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                        </svg>
                        <div className='text-end'>

                            <div className='text-4xl font-bold'>{stats.users ? stats.users : '--'}</div>
                            <div className='text-gray-500 text-sm'>Available system users</div>
                        </div>
                    </div>
                </div>
                <div className='bg-white p-2 border  rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100'>
                    <div className='py-5 px-2 flex justify-between'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 text-blue-500 h-12 bg-gray-200 dark:bg-gray-900 rounded-full p-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                        </svg>

                        <div className='text-end'>

                            <div className='text-4xl font-bold'>{stats.judgements ? stats.judgements : '--'}</div>
                            <div className='text-gray-500 text-sm'>Uploaded judgements</div>
                        </div>
                    </div>
                </div>
                <div className='bg-white p-2 border  rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100'>
                    <div className='py-5 px-2 flex justify-between'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 text-blue-500 h-12 bg-gray-200 dark:bg-gray-900 rounded-full p-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>

                        <div className='text-end'>
                            <div className='text-4xl font-bold'>{stats.reads ? stats.reads : '--'}</div>
                            <div className='text-gray-500 text-sm'>Today's reads</div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
    renderFrequentlyAccessed() {
        return (
            <div>
                <div className='mb-2 '>
                    <div className='text-gray-700 text-lg font-semibold dark:text-gray-100'>Frequently accessed Judgements</div>
                    <div className='text-gray-500 text-sm'>Here a list of top 5 frequentl access judgements</div>
                </div>

                <div className='bg-white rounded-md mt-1 p-2 py-3 border dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100'>
                    <div className='text-sm'>The State Vs Hanwell Ngambi and Owen Mtawali</div>
                    <div className='text-xs text-gray-500'>600 views</div>
                </div>
                <div className='bg-white rounded-md mt-1 p-2 py-3 border dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100'>
                    <div className='text-sm'>Gregory Mwaungulu Vs The State</div>
                    <div className='text-xs text-gray-500'>400 views</div>
                </div>
                <div className='bg-white rounded-md mt-1 p-2 py-3 border dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100'>
                    <div className='text-sm'>Lawrence Chibwana Vs The State</div>
                    <div className='text-xs text-gray-500'>200 views</div>
                </div>

                <div className='flex justify-end mt-2'>
                    <button className='text-white bg-blue-500 p-1 px-2 text-sm rounded-md'>
                        See all judgements
                    </button>
                </div>
            </div>
        );
    }
    renderLoginsCharts() {
        return (
            <div className='mt-4 bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100 border py-3 sm:p-5'>
                <LineChart />
            </div>
        );
    }
    render() {


        return (
            <div className='w-full '>
                <NavBar />

                <div className='px-2 sm:px-10 my-5 '>
                    <div className='font-semibold text-lg dark:text-gray-100'>Welcome {this.props.user.name} </div>

                    <div className='sm:flex w-full mt-5'>
                        <div className='w-full sm:w-3/4 '>
                            {this.renderTopCards()}

                            {this.renderLoginsCharts()}
                        </div>
                        <div className='w-full sm:w-1/4 mt-2 sm:mt-0 sm:ml-5'>
                            {this.renderFrequentlyAccessed()}
                        </div>
                    </div>
                </div>

                <ToastContainer />
            </div>
        )
    }
}
