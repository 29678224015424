import axios from "axios";

export async function getAdminStats(toast) {
    try {
        let response = await axios.get(`${process.env.REACT_APP_URL}/api/stats/admin/`);
        return response;

    } catch (error) {

        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error("There was an error while fetching stats", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        return error;
    }
}

export async function getAdminUsersStats(toast) {
    try {
        let response = await axios.get(`${process.env.REACT_APP_URL}/api/stats/admin/users`);
        return response;

    } catch (error) {

        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error("There was an error while fetching stats", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        return error;
    }
}