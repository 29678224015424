import React, { Component } from 'react'
import Page from '../../../components/page';
import ListingPage from '../../sections/admin/legislation/listing_page';
import { getLegislations } from '../../../backend_handlers/legislationController';
import { ToastContainer, toast } from 'react-toastify';
import { ProgressSpinner } from 'primereact/progressspinner';

export default class LegislationPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            is_loading: false,
            legislations: []
        }

        this.getLegislations = getLegislations.bind(this);

    }

    componentDidMount() {
        document.title = "Legislation";
        this.fetchLegislations();
    }

    fetchLegislations = async () => {
        this.setState({ is_loading: true });

        let response = await getLegislations(toast);
        if (response?.status == 200) {
            this.setState({ legislations: response.data });
        }

        this.setState({ is_loading: false });
    }
    addLegislation = (datum) => {
        this.setState({ legislations: [...this.state.legislations, datum] });
    }

    renderBody() {
        return (
            <div>
                {this.state.is_loading ?
                    <div className='my-5 mx-5 '>
                        <div className='border bg-white p-10 flex justify-center items-center'>
                            <div className="flex items-center"><ProgressSpinner style={{ height: '30px', width: '30px' }} />
                                <div className='ml-5'>Fetching Legislations. Please wait</div></div>

                        </div>
                    </div>
                    :
                    <ListingPage legislations={this.state.legislations} addLegislation={this.addLegislation} />
                }

                <ToastContainer />
            </div>
        )
    }
}
