import React, { Component, createRef } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default class ReferenceTextDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_visible: false,
            value: ''
        }

    }
    changeHandler = (v) => {
        this.setState({ value: v })
    }
    show = () => {
        this.setState({ is_visible: true });
    }
    hide = () => {
        this.setState({ is_visible: false });
    }
    save = () => {
        console.log(this.state.value);
        this.props.setReferenceText(this.state.value);
        this.hide();
    }
    render() {
        return (
            <div name="user_profile_dialog" className={'bg-white dark:bg-gray-900 shadow-lg border dark:border-gray-800 w-4/5  sm:w-1/3' + (this.state.is_visible == true ? ' block ' : ' hidden ')} style={{ zIndex: '9000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}  >
                <div className='dark:bg-gray-800 px-2 dark:border-gray-700 bg-gray-200 p-2 flex justify-between items-center items-center '>

                    <div className='flex items-center'>
                        <div className='ml-2 dark:text-gray-200'>
                            <div >Annotation Reference Text</div>
                            <div className='text-gray-500 text-sm'>Add reference text to this annotation</div>
                        </div>
                    </div>
                    <div onClick={() => this.hide()} className='text-gray-500' style={{ cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>

                    </div>

                </div>

                <div className='p-3'>
                    <ReactQuill theme="snow" value={this.state.value} onChange={this.changeHandler} />
                </div>


                <div className={'px-3 bg-gray-200 flex justify-end  py-1.5'}>
                    <div className=''>
                        <button onClick={() => this.save()} className='bg-white text-sm hover:bg-gray-100 border px-3 border-gray-200 py-1 rounded'>Save</button>
                    </div>
                </div>


            </div>
        )
    }
}
