import React, { Component, useEffect } from 'react'
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { Sidebar } from 'primereact/sidebar';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../redux';


export default class NavBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            side_bar_visible: false,
            dispatch: null,
            user: null
        }
    }
    DummyView = () => {
        const user = useSelector(state => state.user)
        const dispatch = useDispatch()
        useEffect(() => {
            this.setState({
                user: user,
                dispatch: dispatch
            })
        }, [])
        return null
    }
    handleLogout() {
        this.state.dispatch(setLogout());
        window.location.href = "/";
    }
    renderStart() {
        return (
            <div>{process.env.REACT_APP_APP_NAME}</div>
        );
    }
    renderSideBar() {


        let route = window.location.pathname;
        return (
            <Sidebar visible={this.state.side_bar_visible} position="left" onHide={() => this.setState({ side_bar_visible: false })} >
                <h2 className='h2 text-lg font-semibold'>Malawi Law Annotator</h2>
                <div className='m-2'>
                    <Link to={'/'}>
                        <div className={'p-1 flex items-center hover:text-blue-400 hover:underline' + (route == '/' ? ' bg-blue-500 text-white' : ' ')}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                            </svg>
                            <div className='mx-1'>Home</div>
                        </div>
                    </Link>

                    <Link to={'/judgements'}>
                        <a className={'p-1 flex items-center hover:text-blue-400 hover:underline' + (route == '/judgements' ? ' bg-blue-500 text-white' : ' ')}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                            </svg>

                            <div className='mx-1'>Judgements</div>
                        </a>
                    </Link>


                    <Link to={'/register'}>
                        <div className={'p-1 flex items-center hover:text-blue-400 hover:underline' + (route == '/register' ? ' bg-blue-500 text-white' : ' ')}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>


                            <div className='mx-1'>Register</div>
                        </div>
                    </Link>

                    <Link to={'/login'}>
                        <div className={'p-1 flex items-center hover:text-blue-400 hover:underline' + (route == '/login' ? ' bg-blue-500 text-white' : ' ')}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5  h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                            </svg>
                            <div className='mx-1'>Login</div>
                        </div>
                    </Link>

                </div>
            </Sidebar>
        );
    }
    render() {

        let route = window.location.pathname;
        var pathArray = window.location.pathname.split('/');

        return (
            <div className='text-blue bg-gray-200    p-5 py-2 dark:border-0 dark:bg-gray-900'>
                <div className='flex justify-between items-center'>
                    <div className='flex items-center'>
                        <img style={{ height: '50px', width: '50px' }} src={'/logo192.png'} />
                        <div className='ml-1 text-dark-500 dark:text-gray-300 text-lg font-semibold'>Malawi Law Annotator</div>
                    </div>

                    <div >
                        <div className='text-md  text-gray-600 hidden sm:flex items-center dark:text-white'>
                            <Link to={"/home"}>
                                <a href='#' className={'flex items-center  mx-1 hover:text-blue-400 ' + (route == '/' || route == '/admin/home' ? ' text-blue-500' : '')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 mx-1  h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                    </svg>Home</a>
                            </Link>


                            <Link to={"/judgements"}>
                                <a href='#' className={'flex items-center cursor-pointer mx-1 hover:text-blue-400 ' + (route == '/judgements' || pathArray[1] == 'judgement-view' ? ' text-blue-500' : '')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mx-1 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                                    </svg>
                                    Judgements</a></Link>

                            {this.state.user?.role == 'admin' ?

                                <div className='flex'>
                                    {/* <Link to={"/references"}><a className={'flex items-center  mx-1 hover:text-blue-400 ' + (route == '/references' ? ' text-blue-500' : '')}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mx-1  h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                                        </svg>

                                        References
                                    </a></Link> */}

                                    <Link to={"/legislation"}><a className={'flex items-center  mx-1 hover:text-blue-400 ' + (route == '/legislation' ? ' text-blue-500' : '')}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mx-1 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                        </svg>


                                        Legislation
                                    </a></Link>

                                    <Link to={"/users"}><a className={'flex items-center  mx-1 hover:text-blue-400 ' + (route == '/users' ? ' text-blue-500' : '')}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mx-1  h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                        </svg>
                                        Users
                                    </a></Link>

                                </div>

                                : ''
                            }

                            <Link to={"/about"}>
                                <a href='#' className={'flex items-center  mx-1 hover:text-blue-400 ' + (route == '/about' ? ' text-blue-500' : '')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mx-1">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                    </svg>
                                    About</a>
                            </Link>



                            {!this.state.user ?
                                <>
                                    <Link to={'/register'}><a href='#' className={'flex items-center  mx-1 hover:text-blue-400 ' + (route == '/register' ? ' text-blue-500' : '')}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mx-1  h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                        </svg>
                                        Register
                                    </a></Link>
                                    <Link to={'/login'}>
                                        <a href='#' className={'flex items-center  mx-1 hover:text-blue-400 ' + (route == '/login' ? ' text-blue-500' : '')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mx-1 h-5">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                            </svg>
                                            Login
                                        </a>
                                    </Link>
                                </>

                                :

                                <a onClick={() => this.handleLogout()} href='#' className={'flex items-center  mx-1 hover:text-blue-400 ' + (route == '/login' ? ' text-blue-500' : '')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mx-1  h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                                    </svg>

                                    Logout
                                </a>

                            }







                        </div>
                        <div className='sm:hidden'>
                            <button className='btn btn-sm' onClick={() => this.setState({ side_bar_visible: true })}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-500 dark:text-gray-500 ">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                </svg>
                            </button>
                        </div>
                    </div>


                </div>

                {this.renderSideBar()}


                <this.DummyView />

            </div>
        )
    }
}


