import React, { Component } from 'react'

export default class Searchable extends Component {

    constructor(props) {
        super(props)

        this.state = {
            search_filter: 'NOT_STARTTED'
        }
    }
    changeHandler = (event) => {
        this.setState({ search_filter: event.target.value });
    }
    isSearching = () => {
        return this.state.search_filter?.trim() != '' ? true : false;
    }

    renderSearchBar = () => {

        console.log(this.state.search_filter);
       
        return (
            <div className='w-full'> <input value={this.state.search_filter} onChange={this.changeHandler} placeholder={this.state.placeholder_text} type='text' className='text-sm bg-white w-full py-2.5 mr-1 border-gray-200 border rounded-md'></input></div>
        )
    }

}
