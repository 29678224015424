import React, { Component, createRef } from 'react'
import NewNotesDialog from '../../../../components/newNotesDoloag'
import { Avatar } from 'primereact/avatar';
import { ScrollPanel } from 'primereact/scrollpanel';
import { getInitials, getDaysDifferenceString } from '../../../../utils/utils';

export default class JudgementNotes extends Component {

    constructor(props) {
        super(props)

        this.newNotesDialogRef = createRef();
        this.getInitials = getInitials.bind(this);
        this.getDaysDifferenceString = getDaysDifferenceString.bind(this);
    }
    shouldShow(item) {
        if (this.props.parent_state.user) {
            if (this.props.parent_state.user.id == item.User.id) {
                return true;
            }
            if (
                this.props.parent_state.user.id != item.User.id &&
                item.access == "Private"
            ) {
                return false;
            }
            if (item.access == "Public") {
                return true;
            }
        } else {
            if (item.access == "Private") {
                return false;
            } else {
                return true;
            }
        }
    }
    renderNotes = () => {

        if (!this.props.parent_state.active_judgement.Notes) {
            return (<div className="p-10 text-center flex my-5 justify-center items-center border ">
                <div className="text-gray-500 ">
                    <div className="text-sm">Click on judgement to view summary notes here</div>
                </div>
            </div>)
        }

        if (this.props.parent_state.active_judgement.Notes.length == 0) {
            return (
                <div className="p-10 flex my-5 justify-center items-center border ">
                    <div className="text-gray-500 ">
                        <div className="text-sm">Summaries available</div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="my-5">
                    <ScrollPanel
                        style={{ width: "100%", height: "500px", padding: "0px" }}
                        className="custombar2  w-full m-0 b-0"
                    >
                        {this.props.parent_state.active_judgement?.Notes.map((item, index) => {
                            return (
                                <div
                                    className={
                                        "mb-10  flex " + (this.shouldShow(item) ? "" : " hidden ")
                                    }
                                >
                                    <div>
                                        <Avatar
                                            className="text-blue-500"
                                            label={this.getInitials(item.User.name)}
                                            shape="circle"
                                        />
                                    </div>

                                    <div className="ml-2 w-full">
                                        <div className="flex justify-between ">
                                            <div>
                                                <div className="text-sm  font-semibold">
                                                    {item.User.name}{" "}
                                                    {this.props.parent_state.user?.id ==
                                                        item.User.id ? (
                                                        <span className="text-xs font-normal ml-1 text-gray-500">
                                                            (you)
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="text-gray-500 text-xs">
                                                    {this.getDaysDifferenceString(item.createdAt)}
                                                </div>
                                            </div>
                                            <div className=" text-end">
                                                {this.props.parent_state.user?.id == item.User.id ? (
                                                    <button
                                                        onClick={(e) =>
                                                            this.showNotesContextMenu(e, item)
                                                        }
                                                        className="hover:bg-gray-200 text-gray-500 rounded-md "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="currentColor"
                                                            class="w-5 h-5"
                                                        >
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                                            />
                                                        </svg>
                                                    </button>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="mt-1  text-sm text-gray-700">
                                            {item.note}
                                        </div>

                                        <div className="flex justify-start mt-3">
                                            {item.label ? (
                                                <div className="border rounded-md p-0.5 px-3 text-xs bg-gray-200">
                                                    {item.label}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <div className="border rounded-md p-0.5 px-3 text-xs bg-gray-200 ml-1">
                                                {item.access}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </ScrollPanel>
                </div>
            );
        }

    }
    showNotesContextMenu = (e, item) => {
        this.props.noteMenuRef.current.show(e.clientX, e.clientY, item);
    };
    render() {
        return (
            <div className="sticky top-0  px-1  py-3 mr-1 dark:text-gray-100  dark:border-gray-700">
                <div className=" bg-dark:grey-800  mb-3 flex justify-between items-center dark:border-gray-700 ">
                    <div>
                        <div className=" dark:text-gray-200 dark:font-semibold text-lg ">
                            Judgement Summaries
                        </div>
                        <div className="text-gray-500 text-xs ">
                            View judgement summaries created by users
                        </div>
                    </div>
                </div>

                <div className="flex justify-between">
                    <div className="text-gray-900 flex items-center dark:text-gray-100 ">

                        {this.props.parent_state.active_judgement.titleStmt_main}
                    </div>
                    <div className="flex">
                        {this.props.parent_state.user &&
                            this.props.parent_state.selected_paragraph ? (
                            <button
                                name="create_tag_btn "
                                onClick={() => this.notesDialogRef.current.show("create")}
                                className="hover:bg-gray-400  bg-gray-200 text-gray-900 rounded-md px-2 py-1 text-sm flex dark:bg-gray-900  dark:text-gray-100 dark:border-gray-700 dark:hover:bg-gray-800"
                            >
                                <svg
                                    name="create_tag_btn"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-4 h-4 mx-1"
                                >
                                    <path
                                        name="create_tag_btn"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                <div>
                    {this.renderNotes()}
                </div>


            </div>
        )
    }
}
