import React, { Component } from 'react'
import Paginator from '../../../../components/paginator'
import LegislationLoaderDialog from '../../../../components/legislation_loader'
import { toast } from 'react-toastify';
import { ProgressSpinner } from 'primereact/progressspinner';

export default class ListingPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }

        this.legistrationLoaderDialogRef = React.createRef();
    }

    renderHeader() {
        return (
            <div className='mt-2 sm:flex justify-between items-center '>

                <div className='sm:w-1/2'>
                    <div className='text-lg font-semibold dark:text-gray-100'>Legislations</div>
                    <div className='text-gray-500'>Load legislation text, link legislation to annotations</div>
                </div>
                <div className='sm:w-1/2 mt-2 sm:mt-0  w-full  sm:justify-end flex items-center  '>
                    <div className='1/2'>
                        <input placeholder='Search legislation' type='text' className='text-sm bg-white  mr-1 border-gray-200 border rounded-md'></input>
                    </div>
                    <div onClick={() => this.legistrationLoaderDialogRef.current.show()} className='hover:shadow-md hover:bg-black-800 cursor-pointer bg-black rounded-full pb-1.5  px-3 py-1  text-white text-gray-600 flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 mr-1 mt-0.5  h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                        Load New

                    </div>
                </div>


            </div>
        )
    }
    renderList() {

        return (
            <div className='my-5'>

                {this.props.legislations.map((item, index) => {
                    return (<div onClick={() => alert("Hello there! This section is still under development. The idea is when a user clicks on a legislation item , it will redirect him to a page that is used to link legislation references to available annotations")} className='bg-white mb-1 hover:bg-gray-200  cursor-pointer hover:shadow-sm border rounded-md p-2 flex justify-between items-center'>
                        <div>
                            <div className=''>{item.name} - {item.number}</div>
                            <div className='text-gray-500 text-sm'>{item.intro}</div>
                        </div>
                        <div>
                            <div className="hover:bg-white bg-gray-200 rounded-full p-1">  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg></div>

                        </div>


                    </div>)
                })}


                {/* <Paginator /> */}
            </div>
        )
    }
    render() {
        return (
            <div className='p-2  sm:px-20'>
                <div>
                    {this.renderHeader()}

                    {this.renderList()}

                </div>
                <LegislationLoaderDialog ref={this.legistrationLoaderDialogRef} addLegislation={this.props.addLegislation} />
            </div>
        )
    }
}
