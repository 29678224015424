import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import userReducer from './index'

const persistConfig ={
    key : 'auth',
    storage,
    whitelist :['user','token', 'print_data']
};

const persistedReducer = persistReducer(persistConfig,userReducer);

export const store = configureStore({
    reducer : persistedReducer,
    middleware : getDefaultMiddleware=>
    getDefaultMiddleware({
        serializableCheck:{
             ignoreActions : [FLUSH,REHYDRATE,PAUSE,PERSIST,PURGE,REGISTER]
        }
    })
});

export const persistor = persistStore(store);