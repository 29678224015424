import React, { Component } from 'react'
import ReferenceItem from '../../../components/reference_item';
import { ScrollPanel } from 'primereact/scrollpanel';

export default class CrossReferences extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }
    renderHeader(name) {
        return (
            <div className=' flex justify-between mb-2 items-center dark:border-gray-700'>

                <div className='flex justify-start'>
                    <button className='dark:text-white' onClick={() => this.toggleTagItemsView()}>

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>

                    </button>
                    <div className='dark:text-gray-200 font-semibold text-sm  ms-2'>{name}</div>

                </div>

            </div >
        );
    }

    renderList() {

        let found = false;

        let listBody = this.props.parent_state.annotation_metadata.map((metadata, index) => {


            if (metadata.annotation.id == this.props.parent_state.selected_annotation.id) {
                if (metadata.cross_references.length != 0) {
                    found = true;
                }
                return metadata.cross_references.map((reference, index) => {
                    return (
                        <ReferenceItem parent_functions={this.props.parent_functions} parent_state={this.props.parent_state} annotation={this.props.parent_state.selected_annotation} reference={reference} />
                    )
                })
            }
        })



        if (!found) {
            return (
                <div className='border my-2 border-gray-200 text-gray-500 text-center p-5'>
                    No references found
                </div>
            )
        }
        else {
            return listBody;
        }




    }
    render() {



        return (

            <div className='sticky top-0  px-1  py-3  dark:text-gray-100  dark:border-gray-700'>

                <div className=' bg-dark:grey-800  mb-3 flex justify-between items-center dark:border-gray-700 '>
                    <div>
                        <div className=' dark:text-gray-200 dark:font-semibold text-lg '>Cross References</div>
                        <div className='text-gray-500 text-xs '>Annotation judgement cross reference</div>
                    </div>
                    <div>
                        <svg onClick={() => this.props.parent_functions.switchSideControl('tags')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 cursor-pointer h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                        </svg>
                    </div>

                </div>

                <div>
                    {this.props.parent_state.selected_annotation.annotation}
                </div>

                <ScrollPanel style={{ width: '100%', height: '500px', padding: '0px' }} className="custombar2  w-full m-0 b-0">
                    {this.renderList()}
                </ScrollPanel>

            </div>

        )
    }
}
