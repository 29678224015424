import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getCoutryCodes } from '../../../../backend_handlers/dataPoolsController';
import { validate } from '../../../../backend_handlers/validationController';

export default class FieldsForms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            countries: []
        }

        this.getCoutries = getCoutryCodes.bind(this);
        this.validateData = validate.bind(this);
    }
    changeHandler = (e) => {
        this.props.updateFormData(e.target.name, e.target.value);
    }
    componentDidMount() {
        this.fetchData()
    }
    fetchData = async () => {
        let countries = await this.getCoutries();
        this.setState({ countries: countries });
    }
    async switchPage() {

        let response = await this.validateData(this.props.form_data, 'users_form');
        if (response.success) {
            this.props.updateFormErrors({});
            this.props.nextPage(3)
            return true;
        }
        else {
            this.props.updateFormErrors(response.data.errors);
            return false;
        }

    }
    render() {

        let form_data = this.props.form_data;
        let form_errors = this.props.form_errors;
        let { countries } = this.state;


        return (
            <div>
                <div className=''>
                    <div className='text-md text-gray-500'>Great! Now complete the following fields to proceed</div>
                    <div className='my-5'>
                        <div>
                            <div className='dark:text-gray-100'>Your Name</div>
                            <input name='name' value={form_data.name} onChange={this.changeHandler} class={"mt-2  appearance-none border-gray-300 rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.name || form_errors.general_error ? ' border border-red-500' : ' ')} id="name" type="text" placeholder="Name" />
                            <div className='text-red-500 text-sm'>{form_errors.name}</div>
                        </div>
                        <div>
                            <div className='dark:text-gray-100 mt-2'>Your Email </div>
                            <input name='email' value={form_data.email} onChange={this.changeHandler} class={"mt-2  appearance-none border-gray-300 rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.email || form_errors.general_error ? ' border border-red-500' : ' ')} id="username" type="text" placeholder="Email" />
                            <div className='text-red-500 text-sm'>{form_errors.email}</div>
                        </div>
                        <div>
                            <div className='dark:text-gray-100 mt-2'>Your Phone </div>
                            <div className='flex'>
                                <div className='w-1/3'>

                                    <select name='country_code' onChange={this.changeHandler} class={"mt-2   appearance-none border-gray-300 rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.email || form_errors.general_error ? ' border border-red-500' : ' ')}>
                                        <option>Country Code</option>
                                        {countries.map((country, index) => {
                                            return (<option key={index}>{country.idd.root + country.idd.suffixes[0] + "-" + country.altSpellings[0]}</option>)
                                        })}

                                    </select>
                                    <div className='text-red-500 text-sm'>{form_errors.country_code}</div>

                                </div>
                                <div className='w-2/3 ml-1'>
                                    <input name='phone' value={form_data.phone} onChange={this.changeHandler} class={"mt-2  appearance-none border-gray-300 rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.phone || form_errors.general_error ? ' border border-red-500' : ' ')} id="phone" type="text" placeholder="Phone" />
                                    <div className='text-red-500 text-sm'>{form_errors.phone}</div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='flex justify-between'>
                        <div className='flex'>
                            <button onClick={() => this.props.nextPage(1)} className='bg-blue-700 px-3 text-gray-100 rounded-sm py-1.5 hover:bg-blue-500'>
                                Prev
                            </button>
                            <button onClick={() => this.switchPage()} className='bg-blue-700 px-3 ml-2 text-gray-100 rounded-sm py-1.5 hover:bg-blue-500'>
                                Next
                            </button>
                        </div>

                        <div className='text-end text-sm text-gray-500 mt-2'>
                            [ 2 of 4 ]
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}
