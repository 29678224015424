import axios from "axios";


export async function getUnique(toast) {
    try {
        let response = await axios.get(`${process.env.REACT_APP_URL}/api/annotations/distinct`);

        return response;
    }
    catch (error) {
        console.log(error);
        toast.error("There was an error while fetching annotations", {
            position: toast.POSITION.TOP_RIGHT
        });

    }
}
export async function createAnnotation(tag_id, text, paragraph, toast) {

    try {

        let response = await axios.post(`${process.env.REACT_APP_URL}/api/annotations`, { 'tag_id': tag_id, 'text': text, 'paragraph': paragraph });
        toast.success("Annotation created successfully", {
            position: toast.POSITION.TOP_RIGHT
        });
        return response;
    }
    catch (error) {

        if (error.response.data.message) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error("There was an error while creating an annotation", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        return error;


    }
}
export async function updateAnnotation(annotation_id, tag_id, toast, text = null) {

    try {


        let response = await axios.post(`${process.env.REACT_APP_URL}/api/annotations/${annotation_id}`, { 'tag_id': tag_id, 'text': text });
        toast.success("Annotation updated successfully", {
            position: toast.POSITION.TOP_RIGHT
        });
        return response;
    }
    catch (error) {
        console.log(error);
        toast.error("There was an error while updating an annotation", {
            position: toast.POSITION.TOP_RIGHT
        });

    }

}
export async function removeAnnotation(annotation_id, toast) {
    try {
        let response = await axios.delete(`${process.env.REACT_APP_URL}/api/annotations/${annotation_id}`);
        toast.success("Annotation deleted successfully", {
            position: toast.POSITION.TOP_RIGHT
        });
        return response;
    }
    catch (error) {
        console.log(error);
        toast.error("There was an error while deleted an annotation", {
            position: toast.POSITION.TOP_RIGHT
        });

    }
}
export async function uploadAnnotationFile(form_data, toast) {

    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/annotations/upload`, form_data, { headers: { "Content-Type": "multipart/form-data" } });
        toast.success("Annotations uploaded successfully", {
            position: toast.POSITION.TOP_RIGHT
        });
        console.log(response);
        return response;
    }
    catch (error) {
        console.log(error);
        if (error.response) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates7",
            });
        }
        else {
            toast.error("There was an error while parsing the annotations file", {
                position: toast.POSITION.TOP_RIGHT,
                toastId: "toastAvoidsDuplicates8",
            });
        }
        return error;
    }

}