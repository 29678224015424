import React, { Component } from 'react'
export default class ConfirmDialog extends Component {

    constructor(props) {

        super(props);
        this.state = {
            visible: false,
            message: '',
            confirmedCallback: null,
            deniedCallback: null
        }
    }
    show(message, confirmedCallback = null, deniedCallback = null) {
        this.setState({ visible: true, message: message, confirmedCallback, confirmedCallback, deniedCallback: deniedCallback });
    }
    close() {
        this.setState({ visible: false });
    }
    onConfirm() {
        this.close();
        this.state.confirmedCallback();
    }
    render() {
        return (
            <div className={'bg-gray-100 dark:bg-gray-800 shadow-lg border dark:border-gray-800  w-4/5  sm:w-1/5' + (this.state.visible == true ? ' block ' : ' hidden ')} style={{ zIndex: '9000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}  >
                <div className='p-2 bg-gray-200 dark:bg-gray-800 dark:text-gray-100 flex justify-between'>
                    <div class="font-semibold">Confirm action</div>
                    <div className='text-gray-500 hover:text-gray-200 hover:bg-gray-400 p-1 rounded-sm' onClick={() => this.close()} style={{ cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className='p-2 border-b text-gray-800 bg-white dark:text-gray-100 dark:bg-gray-900 dark:border-b-gray-700'>
                    {this.state.message}
                </div>
                <div className='flex justify-end py-1 px-2 '>
                    <button onClick={() => this.onConfirm()} className='hover:bg-blue-700 bg-blue-500 px-4 py-1 text-sm rounded-sm text-white mx-1 '>Yes</button>
                    <button onClick={() => this.close()} className='hover:bg-gray-300 bg-gray-100 border px-4 py-1 text-sm rounded-sm text-blue-500 mx-1'>No</button>
                </div>

            </div>
        )
    }
}
