import React, { Component } from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { ProgressSpinner } from "primereact/progressspinner";

export default class JudgementInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="sticky top-0  px-1  py-3 mr-1 dark:text-gray-100  dark:border-gray-700">
        <div className=" bg-dark:grey-800  mb-3 flex justify-between items-center dark:border-gray-700 ">
          <div>
            <div className=" dark:text-gray-200 dark:font-semibold text-lg ">
              Judgement Details
            </div>
            <div className="text-gray-500 text-xs ">
              View more judgement details
            </div>
          </div>
        </div>

        <div className="mt-3 py-5 ">
          <div className="bg-gray-200 px-1">Basic Details</div>
          <div className="mt-2 border-b pb-1 ">
            <div className="text-sm text-gray-500 ">Title</div>
            <div className="font-normal text-gray-700">
              {this.props.parent_state.judgement.titleStmt_main}
            </div>
          </div>
          <div className="mt-2 mt-2 border-b pb-1 ">
            <div className="text-sm text-gray-500">Sub title</div>
            <div className="text-gray-700">
              {this.props.parent_state.judgement.titleStmt_sub}
            </div>
          </div>

          <div className="mt-2 mt-2 border-b pb-1 ">
            <div className="text-sm text-gray-500">Extent</div>
            <div className="text-gray-700">
              {this.props.parent_state.judgement.extent}
            </div>
          </div>

         
        </div>

        <div className="mt-3 py-5">
          <div className="bg-gray-200 px-1">Publication Details</div>
          <div className="mt-2 border-b pb-1">
            <div className="text-sm text-gray-500 ">Publisher</div>
            <div className="font-normal text-gray-700">
              {this.props.parent_state.judgement.publicationStmt_publisher}
            </div>
          </div>

          <div className="mt-2 border-b pb-1">
            <div className="text-sm text-gray-500">Distributor</div>
            <div className="text-gray-700">
              {this.props.parent_state.judgement.publicationStmt_distributor}
            </div>
          </div>

          <div className="mt-2 border-b pb-1">
            <div className="text-sm text-gray-500">Publisher</div>
            <div className="text-gray-700">
              {this.props.parent_state.judgement.publicationStmt_publisher}
            </div>
          </div>

          <div className="mt-2 border-b pb-1">
            <div className="text-sm text-gray-500">Pulication date</div>
            <div className="text-gray-700">
              {this.props.parent_state.judgement.publicationStmt_date}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
