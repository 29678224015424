import React, { Component } from 'react'
import JudgementTags from './side_bar_screens/judgement_tags';
import JudgementNotes from './side_bar_screens/judgements_notes';
import NoteContextDialog from '../../../components/noteContextMenu';
import JudgementUpload from './judgement_upload';

export default class JudgementSideBar extends Component {

    constructor(props) {
        super(props);
        this.noteMenuRef = React.createRef();
    }
    renderCurrentSection = () => {
        switch (this.props.parent_state.current_side_control) {
            case 'tags':
                return <JudgementTags parent_functions={this.props.parent_functions} parent_state={this.props.parent_state} />
                break;
            case 'notes':
                return <JudgementNotes noteMenuRef={this.noteMenuRef} parent_functions={this.props.parent_functions} parent_state={this.props.parent_state} />

            case 'upload':
                 return <JudgementUpload />
            default:
                break;
        }
    }
    render() {
        return (
            <div className='mt-2'>
                {this.renderCurrentSection()}
                <NoteContextDialog
                    notesDialogRef={this.notesDialogRef}
                    parent_state={this.props.parent_state}
                    parent_functions={this.props.parent_functions}
                    ref={this.noteMenuRef}
                />
            </div>
        )
    }
}
