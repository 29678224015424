import React, { Component, createRef } from 'react'

import { toast } from 'react-toastify';
import { deleteNote } from '../backend_handlers/notesController';





export default class NoteContextDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current_page: 'menu',
            form_data: {},
            form_errors: {},
            locationX: '',
            locationY: '',
            styleType: 'relative',
            visible: false,
            tag: {},
            is_loading: false

        }

        this.confirmDialogRef = createRef();
        this.deleteNote = deleteNote.bind(this);


    }


    handleRename = async () => {
        this.setState({ is_loading: true });

        let { form_data, form_errors } = this.state;
        if (!form_data.name) {
            form_errors.name = 'Name can not be empty!';
            this.setState({ form_errors });
        }
        else {
            this.handleOnClose(true);
            let response = await this.updateTag(this.state.tag.id, form_data.name, toast);

            if (response.status == 200) {
                this.handleOnClose(true);
                this.props.parent_functions.refreshTags();
            }
            else {
                console.log(response.data);
                form_errors.name = response.data.message;
                this.setState({ form_errors: form_errors, is_loading: false });

            }
        }


    }

    handleRemove = () => {
        this.handleOnClose();
        let msg = "Are you sure you want to remove note ?"
        this.props.parent_functions.confirmDialog.current.show(msg, this.deleteConfirmedCallback);
    }


    deleteConfirmedCallback = async () => {

        this.setState({ is_loading: true });

        this.deleteNote(this.state.note.id, toast);
        this.props.parent_functions.removeNote(this.state.note);

    }


    show = (x, y, note) => {
        this.setState({ visible: true, is_loading: false, locationX: x, locationY: y, note: note, styleType: 'relative' });
    }

    initEdit = () => {
        this.setState({ visible: false });
        this.props.notesDialogRef.current.show('edit', this.state.note);
    }

    handleOnClose = (force = false) => {
        if (force) {
            this.setState({ current_page: 'menu', form_data: {}, visible: false, form_errors: {}, is_loading: false });
        }
        else {
            if (!this.state.is_loading) {
                this.setState({ current_page: 'menu', form_data: {}, visible: false, form_errors: {}, is_loading: false });
            }
        }
    }

    getCurrentStyle() {
        if (this.state.styleType == 'relative') {
            return { width: '200px', top: this.state.locationY + 'px', left: ((this.state.locationX - 200) + 'px'), position: 'fixed', zIndex: '9000' };
        }
        else {
            return { width: '300px', zIndex: '9000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' };
        }
    }
    render() {
        return (
            <div className={'bg-gray-200 dark:bg-gray-800 dark:border-gray-700 border shadow-lg rounded-md' + (this.state.visible == true ? ' block ' : ' hidden ')} style={this.getCurrentStyle()} >
                <div className='p-1 px-3 dark:border-gray-800 dark:text-gray-100  rounded-md text-gray-900 flex justify-between items-center'>
                    <div>Note</div>
                    <div className='text-gray-500 ml-5' onClick={() => this.handleOnClose()} style={{ cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className='bg-white  dark:bg-gray-900 py-1 dark:text-gray-100'>
                    <div>

                        <div onClick={() => this.initEdit()} className='flex px-3 py-1 text-sm hover:bg-gray-300  hover:rounded-sm border-b dark:hover:bg-gray-700 dark:border-b-gray-700' style={{ cursor: 'pointer' }}>
                            Edit
                        </div>

                        <div onClick={() => this.handleRemove()} className='flex px-3 py-1 text-sm hover:bg-gray-300 hover:rounded-sm  dark:hover:bg-gray-700 dark:border-b-gray-700' style={{ cursor: 'pointer' }}>
                            Delete
                        </div>

                    </div>
                </div>



            </div >
        )
    }
}
