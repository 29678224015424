import React, { Component, createRef } from 'react'
import UserContextMenu from '../../../components/userContextMenu';
import UserModal from '../../../components/userModal';
import ConfirmDialog from '../../../components/confirmDialog';
import { updateRole, deleteUser } from '../../../backend_handlers/UsersController';


export default class UsersList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selected_user: null
        }

        this.userContextMenu = createRef();
        this.userDialog = createRef();
        this.confirmDialog = createRef();
        this.updateRole = updateRole.bind(this);
        this.removeUser = deleteUser.bind(this);
    }
    toggleContextMenu = (e, user) => {
        this.userContextMenu.current.show(e.clientX, e.clientY, user);
    }
    confirmApprove = (user) => {
        this.setState({ selected_user: user });
        this.confirmDialog.current.show("Are you sure you want to approve this annotator?", this.approvalConfirmedCallback);
    }
    confirmRevoke = (user) => {
        this.setState({ selected_user: user });
        this.confirmDialog.current.show("Are you sure you want to revoke annotator rights on this user?", this.revokeConfirmedCallback);
    }
    approvalConfirmedCallback = async () => {
        let response = await this.updateRole(this.state.selected_user.id, 'annotator', this.props.toast);
        if (response.status == 200) {
            this.props.fetchUsers();
        }
    }
    confirmRemove = (user) => {
        this.setState({ selected_user: user });
        this.confirmDialog.current.show("Are you sure you want to remove this user?", this.removeUserConfirmedCallback);
    }
    removeUserConfirmedCallback = async () => {
        let response = await this.removeUser(this.state.selected_user.id, this.props.toast);
        if (response.status == 200) {
            this.props.fetchUsers();
        }
    }
    revokeConfirmedCallback = async () => {
        let response = await this.updateRole(this.state.selected_user.id, 'annotator-pending', this.props.toast);
        if (response.status == 200) {
            this.props.fetchUsers();
        }
    }

    render() {
        return (

            <div className=" my-2 ">


                {this.props.users.map((item, index) => {
                    return (<div className='flex justify-between border rounded-md bg-white p-2   mb-1 dark:bg-gray-900 dark:border-gray-800'>


                        <div className=''>
                            <div>
                                <div className=' text-gray-800  font-semibold dark:text-blue-400   cursor-hand'>{item.name}</div>
                                <div className='text-gray-500 '><span className='text-blue-500'></span>{item.role}</div>
                            </div>
                        </div>

                        <div className='flex items-center hidden sm:flex'>
                            <div className='  text-gray-500'>{item.email}</div>
                        </div>

                        <div className=' text-end'>
                            <button onClick={(e) => this.toggleContextMenu(e, item)} name='user_menu_toggler' className='rounded-md dark:text-gray-200  dark:bg-gray-800 hover:bg-gray-300 hover:dark:bg-gray-700'>
                                <svg name='user_menu_toggler' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path name='user_menu_toggler' stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                </svg>
                            </button>
                            <div className='text-xs text-gray-500'>May,2023</div>
                        </div>

                    </div>)
                })}


                <div>
                    <div className='flex justify-start my-5'>
                        <div className='pr-1 '>
                            <div className='mx-2 dark:text-gray-400'>Showing Page 1 of 10</div>
                            <div className='items-center flex justify-start mt-1 '>
                                <button className='dark:bg-blue-800 hover:dark:bg-blue-500 hover:bg-blue-800 bg-blue-500 text-white shadow-md rounded-full p-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5  h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                    </svg>
                                </button>

                                <button className='ml-1 dark:bg-blue-800 hover:dark:bg-blue-500  hover:bg-blue-800 bg-blue-500 text-white shadow-md rounded-full p-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5  h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                    </svg>
                                </button>


                            </div>

                        </div>
                    </div>
                </div>

                <UserContextMenu confirmRemove={this.confirmRemove} userDialog={this.userDialog} ref={this.userContextMenu} />
                <UserModal confirmApprove={this.confirmApprove} confirmRevoke={this.confirmRevoke} ref={this.userDialog} />
                <ConfirmDialog ref={this.confirmDialog} />
            </div>

        );


    }
}
