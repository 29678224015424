import { ProgressSpinner } from 'primereact/progressspinner';
import React, { Component } from 'react'
import { validate } from '../../../../backend_handlers/validationController';
import { createUser } from '../../../../backend_handlers/UsersController';



export default class ChoosePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current_page: 'passwords'
        }

        this.validate = validate.bind(this);
        this.createUser = createUser.bind(this);
    }

    switchPage = (page) => {
        this.setState({ current_page: page })
    }
    sendRegisterAccountRequest = async () => {

        this.setState({ current_page: 'loading' });
        let response = await validate(this.props.form_data, 'passwords');
        if (!response.success) {
            this.props.updateFormErrors(response.data.errors)
        } else {
            this.props.updateFormErrors({});

            let response = await createUser(this.props.form_data);
            if (response.status == 200) {
                this.props.nextPage(5);
            }

        }

        this.setState({ current_page: 'passwords' });
    }
    renderCurrentPage() {

        switch (this.state.current_page) {
            case 'passwords':
                return this.renderPasswordsForm();

            case 'loading':
                return this.renderLoading();


            default:
                break;
        }
    }
    changeHandler = (e) => {
        this.props.updateFormData(e.target.name, e.target.value);
    }
    renderPasswordsForm() {
        let form_data = this.props.form_data
        let form_errors = this.props.form_errors;



        return (
            <div>
                <div className=''>
                    <div>
                        <div className='text-gray-700 dark:text-gray-100'>Password</div>
                        <input name='password' value={form_data.password} onChange={this.changeHandler} class={"mt-2  appearance-none border-gray-300 rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.otp || form_errors.general_error ? ' border border-red-900' : ' ')} id="otp" type="password" placeholder='choose password' />
                        <div className='text-red-500 text-sm mt-1'>{form_errors.password}</div>
                    </div>

                    <div className='mt-2'>
                        <div className='text-gray-700 dark:text-gray-100'>Confirm Password</div>
                        <input name='password_confirmation' value={form_data.password_confirmation} onChange={this.changeHandler} class={"mt-2  appearance-none border-gray-300 rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 " + (form_errors.otp || form_errors.general_error ? ' border border-red-500' : ' ')} id="otp" type='password' placeholder="re-type password to confirm" />
                        <div className='text-red-500 text-sm mt-1'>{form_errors.password_confirmation}</div>
                    </div>

                </div>

                <div className='flex justify-between mt-4'>
                    <button onClick={this.sendRegisterAccountRequest} className='bg-blue-700 px-4 text-gray-100 rounded-sm py-1.5 hover:bg-blue-500'>
                        Save
                    </button>
                    <div className='text-end text-sm text-gray-500 mt-2'>
                        [ 4 of 4 ]
                    </div>
                </div>
            </div>
        );
    }

    renderLoading() {
        return (
            <div className='my-2 flex justify-center border border-gray-300 dark:border-gray-800'>
                <div className='flex justify-between items-center'>
                    <div>
                        <ProgressSpinner style={{ width: '30px' }} />
                    </div>

                    <div className='text-sm mx-2  text-gray-500'>Processing. Please wait</div>
                </div>
            </div>
        );
    }
    render() {


        return (
            <div>
                <div className=''>
                    <div className='text-md text-gray-500'>Well done! Now finish by securing your account with a password</div>

                    <div className='my-5'>
                        {this.renderCurrentPage()}
                    </div>
                </div>

            </div>
        )
    }
}
