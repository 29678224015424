import React, { Component } from 'react'
import { createAnnotation, updateAnnotation } from '../backend_handlers/AnnotationController';
import { toast, ToastContainer } from 'react-toastify';
import { ProgressSpinner } from 'primereact/progressspinner';

export default class NewAnnotationDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form_data: {},
            form_errors: {},
            visible: false,
            x: '',
            y: '',
            shown: false,
            is_merging: false,
            is_loading: false
        }
        this.createAnnotation = createAnnotation.bind(this);
        this.updateAnnotation = updateAnnotation.bind(this);
    }
    findTag(annotation) {
        let tag = null;
        this.props.parent_state.tags.map((tagItem, index) => {
            tagItem.JudgementAnnotations.map((ann, index) => {
                if (ann.id == annotation.id) {
                    tag = tagItem;
                }
            })
        })
        return tag;

    }
    changeHandler = (e) => {
        let { form_data } = this.state;
        form_data[e.target.name] = e.target.value;
        this.setState({ form_data });
    }
    setIsMerging = (status, conflictedAnnotation) => {
        this.setState({ is_merging: status, conflictedAnnotation: conflictedAnnotation });
    }
    handlePageOnClick = (e) => {

        if (this.state.shown) {
            if (e.target.parentElement) {
                if (e.target.parentElement.getAttribute('name') !== 'dialog_window' && e.target.parentElement.getAttribute('name') !== 'tag') {
                    this.handleOnClose();
                }
            }

        }
        else {
            this.setState({ shown: true });
        }



    }
    show(text, paragraph, x, y) {

        this.setState({ visible: true, x: x, y: y, selectedText: text, selectedParagraph: paragraph });
        document.addEventListener('click', this.handlePageOnClick);
    }
    async saveAnnotation() {

        let { form_data, form_errors } = this.state;
        if (!form_data.tag) {
            form_errors.tag = "Tag name cannot be empty";
            this.setState({ form_errors });
        }
        else {
            this.setState({ form_errors: {} })
            let response = {};

            this.setState({ is_loading: true })

            if (this.state.is_merging) {

                response = await this.updateAnnotation(this.state.conflictedAnnotation.id, this.state.conflictedAnnotation.tag_id, toast, this.state.selectedText.toString());
                if (response.status == 200) {
                 
                    let annotation = response.data;
                    annotation.annotation['checked'] = true;
                    this.props.parent_functions.updateAnnotation(annotation);

                    let tag = this.props.parent_state.tags.find(Obj => Obj.id == annotation.annotation.tag_id);
                    tag.expanded = true;
                    this.props.parent_functions.updateTag(tag);
                }
            }
            else {
                response = await this.createAnnotation(parseInt(form_data.tag), this.state.selectedText.toString(), this.state.selectedParagraph, toast);

                if (response.status == 200) {

                    let annotation = response.data;
                    annotation.annotation['checked'] = true;
                    this.props.parent_functions.addAnnotation(annotation);

                    let tag = this.props.parent_state.tags.find(Obj => Obj.id == annotation.annotation.tag_id);
                    tag.expanded = true;
                    this.props.parent_functions.updateTag(tag);


                }
            }


            this.handleOnClose();
        }
    }
    handleOnClose = () => {
        document.removeEventListener('click', this.handlePageOnClick);
        this.setState({ form_errors: {}, visible: false, shown: false, is_loading: false })

    }
    render() {

        let { visible, x, y } = this.state;
        return (
            <div className={`${visible ? 'block' : 'hidden'} bg-opacity-50 h-screen w-screen z-10 bg-gray-500 fixed top-0 left-0`}>
                <div name="dialog_window" className={'z-20 shadow-lg dark:bg-gray-900  border dark:border-gray-800 ' + (visible ? ' block ' : ' hidden ')} style={{ width: '300px', height: '150px', top: y + 'px', left: x + 'px', position: 'fixed', zIndex: '9000' }}>
                    <div name="dialog_window" className='p-2 bg-gray-300 dark:bg-gray-900 dark:text-gray-100 flex justify-between flex justify-between'>
                        <div className=''>Annotate </div>
                        <div name="dialog_window" className='text-gray-500' onClick={() => this.handleOnClose()} style={{ cursor: 'pointer' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                    </div>
                    <div name="dialog_window" className='p-3 border border-gray-100 bg-gray-100 dark:bg-gray-800 dark:text-gray-100'>

                        {this.state.is_loading ?

                            <div className='h-full text-center dark:bg-gray-800'>
                                <ProgressSpinner style={{ width: '30px', height: '30px' }} />
                                <div className='text-gray-100'>Please wait ....</div>
                            </div>

                            :
                            <div name="dialog_window">
                                <select name='tag' onChange={this.changeHandler} className={'mt-2 border   rounded w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500 ' + (this.state.form_errors.tag ? ' border-red-500 ' : ' border-none ')}>
                                    <option value={''} selected>Select annotation tag</option>
                                    {this.props.parent_state.tags.map((tag, index) => {
                                        return (
                                            <option value={tag.id}>{tag.name}</option>
                                        );
                                    })}
                                </select>
                                <div className='text-red-500 text-sm '>{this.state.form_errors.tag}</div>
                                <button onClick={() => this.saveAnnotation()} className='bg-blue-500 text-white px-3 py-1.5 my-2 rounded-md text-sm'>Apply</button>

                            </div>

                        }
                    </div>


                </div>
            </div>

        )
    }
}
