import React, { Component } from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { ProgressSpinner } from "primereact/progressspinner";
import NewNotesDialog from "../../../components/newNotesDoloag";
import { getNotes } from "../../../backend_handlers/notesController";
import NoteContextDialog from "../../../components/noteContextMenu";
import { Avatar } from "primereact/avatar";
import { getInitials } from "../../../utils/utils";
import { getDaysDifferenceString } from "../../../utils/utils";

export default class ParagraphNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      notes: [],
    };

    this.notesDialogRef = React.createRef();
    this.getNotes = getNotes.bind(this);
    this.noteMenuRef = React.createRef();
    this.getInitials = getInitials.bind(this);
    this.getDaysDifferenceString = getDaysDifferenceString.bind(this);
  }

  componentDidMount() {
    this.props.parent_functions.refreshViewPageDialog();
  }

  renderHeader(name) {
    return (
      <div className=" flex justify-between mb-2 items-center dark:border-gray-700">
        <div className="flex justify-start">
          <button
            className="dark:text-white"
            onClick={() => this.toggleTagItemsView()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </button>
          <div className="dark:text-gray-200 font-semibold text-sm  ms-2">
            {name}
          </div>
        </div>
      </div>
    );
  }
  shouldShow(item) {
    if (this.props.parent_state.user) {
      if (this.props.parent_state.user.id == item.User.id) {
        return true;
      }
      if (
        this.props.parent_state.user.id != item.User.id &&
        item.access == "Private"
      ) {
        return false;
      }
      if (item.access == "Public") {
        return true;
      }
    } else {
      if (item.access == "Private") {
        return false;
      } else {
        return true;
      }
    }
  }

  renderNotes() {
    let paragraph = this.props.parent_state.selected_paragraph;

    if (this.state.is_loading) {
      return (
        <div className="p-10 flex my-5 bg-white justify-center items-center border ">
          <div className="text-gray-500 ">
            <div className="flex justify-center">
              {" "}
              <ProgressSpinner style={{ width: "30px", height: "30px" }} />
            </div>
            <div className="text-sm">Fetching Notes. Please wait</div>
          </div>
        </div>
      );
    } else {
      
      if (paragraph?.Notes.length == 0) {
        return (
          <div className="p-10 flex my-5 justify-center items-center border ">
            <div className="text-gray-500 ">
              <div className="text-sm">Notes not available</div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="my-5">
            <ScrollPanel
              style={{ width: "100%", height: "500px", padding: "0px" }}
              className="custombar2  w-full m-0 b-0"
            >
              {paragraph?.Notes.map((item, index) => {
                return (
                  <div
                    className={
                      "mb-10  flex " + (this.shouldShow(item) ? "" : " hidden ")
                    }
                  >
                    <div>
                      <Avatar
                        className="text-blue-500"
                        label={this.getInitials(item.User.name)}
                        shape="circle"
                      />
                    </div>

                    <div className="ml-2 w-full">
                      <div className="flex justify-between ">
                        <div>
                          <div className="text-sm  font-semibold">
                            {item.User.name}{" "}
                            {this.props.parent_state.user?.id ==
                              item.User.id ? (
                              <span className="text-xs font-normal ml-1 text-gray-500">
                                (you)
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="text-gray-500 text-xs">
                            {this.getDaysDifferenceString(item.createdAt)}
                          </div>
                        </div>
                        <div className=" text-end">
                          {this.props.parent_state.user?.id == item.User.id ? (
                            <button
                              onClick={(e) =>
                                this.showNotesContextMenu(e, item)
                              }
                              className="hover:bg-gray-200 text-gray-500 rounded-md "
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                />
                              </svg>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="mt-1  text-sm text-gray-700">
                        {item.note}
                      </div>

                      <div className="flex justify-start mt-3">
                        {item.label ? (
                          <div className="border rounded-md p-0.5 px-3 text-xs bg-gray-200">
                            {item.label}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="border rounded-md p-0.5 px-3 text-xs bg-gray-200 ml-1">
                          {item.access}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </ScrollPanel>
          </div>
        );
      }
    }
  }
  showNotesContextMenu = (e, item) => {
    this.noteMenuRef.current.show(e.clientX, e.clientY, item);
  };
  render() {
    return (
      <div className="sticky top-0  px-1  py-3 mr-1 dark:text-gray-100  dark:border-gray-700">
        <div className=" bg-dark:grey-800  mb-3 flex justify-between items-center dark:border-gray-700 ">
          <div>
            <div className=" dark:text-gray-200 dark:font-semibold text-lg ">
              Paragraph Notes
            </div>
            <div className="text-gray-500 text-xs ">
              View notes created by annotators
            </div>
          </div>
        </div>

        <div>

          <div className="flex justify-between">
            <div className="text-gray-900 flex items-center dark:text-gray-100 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 text-blue-500 mr-2 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                />
              </svg>
              Paragraph{" "}
              {this.props.parent_state.selected_paragraph?.paragraph_number}{" "}
              notes
            </div>
            <div className="flex">
              {this.props.parent_state.user &&
                this.props.parent_state.selected_paragraph ? (
                <button
                  name="create_tag_btn "
                  onClick={() => this.notesDialogRef.current.show("create")}
                  className="hover:bg-gray-400  bg-gray-200 text-gray-900 rounded-md px-2 py-1 text-sm flex dark:bg-gray-900  dark:text-gray-100 dark:border-gray-700 dark:hover:bg-gray-800"
                >
                  <svg
                    name="create_tag_btn"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4 mx-1"
                  >
                    <path
                      name="create_tag_btn"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div>
          {this.props.parent_state.selected_paragraph ? (
            this.renderNotes()
          ) : (
            <div className="border text-center text-sm  my-2 p-10 text-gray-500 justify-center items-center flex">
              <div>Click on a paragraph to see and create notes</div>
            </div>
          )}
        </div>

        <NewNotesDialog
          parent_functions={this.props.parent_functions}
          parent_state={this.props.parent_state}
          user={this.props.parent_state.user}
          paragraph={this.props.paragraph}
          ref={this.notesDialogRef}
        />

        <NoteContextDialog
          notesDialogRef={this.notesDialogRef}
          parent_state={this.props.parent_state}
          parent_functions={this.props.parent_functions}
          ref={this.noteMenuRef}
        />
      </div>
    );
  }
}
