import React, { Component } from 'react'
import { loadLegislationMetadata, saveLegislation } from '../backend_handlers/legislationController';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ToastContainer, toast } from 'react-toastify';

export default class LegislationLoaderDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            is_visible: false,
            form_data: {},
            form_errors: {},
            is_loading: false,
            is_saving: false,
            current_page: 'page1'
        }

        this.loadLegislationMetadata = loadLegislationMetadata.bind(this);
        this.saveLegislation = saveLegislation.bind(this);
    }
    changeHandler = (e) => {
        let { form_data } = this.state;
        form_data[e.target.name] = e.target.value;
        this.setState(form_data);
    }

    show = () => {
        this.setState({ is_visible: true });
    }
    hide = () => {
        this.setState({ is_visible: false });
    }
    handleOnClose = () => {
        this.hide();
    }
    loadMetadata = async () => {



        if (this.state.form_data.url != null && this.state.form_data?.url?.trim() != '') {

            this.setState({ is_loading: true });
            let response = await this.loadLegislationMetadata(this.state.form_data.url, toast);
            console.log(response);
            if (response?.status == 200) {
                this.setState({ metadata: response?.data, 'current_page': 'page2' });
            }
            this.setState({ is_loading: false });
        }
        else {
            this.setState({ form_errors: { url: 'url is a required field' } });
        }

    }
    renderInputForm = () => {
        return (
            <div>
                <div>
                    <div className='flex items-center'>Legislation URL (<div className='text-sm text-gray-500'>Copy legislation url from malawilii.org</div>)</div>

                    <input name='url' onChange={this.changeHandler} value={this.state.form_data.url} className={'text-gray-500 border text-sm border-gray-300 w-full rounded-md mt-2 ' + (this.state.form_errors.url ? ' border border-red-500 ' : '')}></input>
                    <div className="text-sm text-red-500">{this.state.form_errors.url}</div>
                </div>
                <div className='my-2 mt-4'>
                    <button disabled={this.state.is_loading ? true : false} onClick={() => this.loadMetadata()} className='bg-blue-500 text-white px-3 p-1.5 rounded-md mb-1 hover:shadow-sm '>
                        {this.state.is_loading ? <div className="flex items-center"><ProgressSpinner className='mr-2' style={{ height: '20px', width: '20px' }} /> <div className='m-1'>Please wait..</div></div> : 'Load document'}</button>
                </div>
            </div>
        )
    }
    saveLegislationFromUrl = async () => {

        console.log(this.state);
        this.setState({ is_saving: true });

        let response = await this.saveLegislation(this.state.form_data.url, toast);

        if(response.status ==200){

        }
        this.setState({ is_saving: false });
        this.props.addLegislation(response.data);
        this.hide();
        
    }
    renderConfirmation = () => {
        return <div>


            <div>

                <div className='mt-1 text-lg '>{this.state.metadata.name}</div>
                <div className=''>{this.state.metadata.number}</div>
                <div className='text-gray-500 text-sm'>{this.state.metadata.introText}</div>
            </div>

            <div className='my-2 mt-4'>
                <button disabled={this.state.is_saving ? true : false} onClick={() => this.saveLegislationFromUrl()} className='bg-blue-500 text-white px-3 p-1.5 rounded-md mb-1 hover:shadow-sm '>
                    {this.state.is_saving ? <div className='flex items-center'>
                        <ProgressSpinner className='mr-2' style={{ height: '20px', width: '20px' }} />
                        <div className='ml-2'>Saving. Please wait...</div>
                    </div> : 'Save Document'}

                </button>
            </div>



        </div>
    }
    renderCurrentPage = () => {

        switch (this.state.current_page) {
            case 'page1':
                return this.renderInputForm();

            case 'page2':
                return this.renderConfirmation();

            default:
                break;
        }
    }

    render() {

        return (
            <div name="new_tag_dialog" className={'bg-white dark:bg-gray-800 shadow-lg border dark:border-gray-700 w-4/5  sm:w-2/5' + (this.state.is_visible == true ? ' block ' : ' hidden ')} style={{ zIndex: '9000', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}  >
                <div className='p-2 bg-gray-200 sm:px-5 dark:bg-gray-800 dark:text-gray-100 flex justify-between items-center'>
                    <div> <div className='font-semibold'>Load New Legislation</div>
                        <div className='text-gray-500 text-sm'>Load legislation documents from malawilii</div>
                    </div>
                    <div className='text-gray-500' onClick={() => this.handleOnClose()} style={{ cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-black">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>

                <div className='p-2 sm:px-5'>

                    {this.renderCurrentPage()}

                </div>


            </div>
        )
    }
}
