import axios from "axios";

export async function validate(data, key) {

    try {
        let response = await axios.post(`${process.env.REACT_APP_URL}/api/validations/${key}`, data);
        return response.data;
    } catch (error) {

        return error.response.data;
    }

}