import React, { Component } from "react";
import Page from "../../../components/page";
import UsersList from "../../sections/users/usersList";
import "./table_style.css";
import { getUsers } from "../../../backend_handlers/UsersController";
import { ProgressSpinner } from "primereact/progressspinner";
import SidePane from "../../sections/users/sidePane";
import { ToastContainer, toast } from "react-toastify";
import { getAdminUsersStats } from "../../../backend_handlers/StatsController";

export default class UsersPage extends Page {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      is_loading: true,
      stats: {},
    };
    this.getUsers = getUsers.bind(this);
    this.getAdminUsersStats = getAdminUsersStats.bind(this);
  }
  componentDidMount() {
    this.fetchUsers();
    this.fetchStats();
  }
  fetchStats = async () => {
    let response = await this.getAdminUsersStats();
    if (response.status == 200) {
      this.setState({ stats: response.data });
    }
  };
  fetchUsers = async () => {
    this.setState({ is_loading: true });
    let response = await getUsers();
    if (response.status == 200) {
      this.setState({ users: response.data });
    }
    this.setState({ is_loading: false });
  };
  renderHeader() {
    return (
      <div className="mt-2 flex justify-between items-center ">
        <div className="">
          <div className="text-xl dark:text-gray-100">System Users</div>
          <div className="text-gray-500">View and manage system users</div>
        </div>

        <div className="w-1/4 flex items-center">
          <div className="w-full">
            <input
              placeholder="Search"
              className="text-sm w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md p-2 focus:ring-blue-500 focus:border-blue-500 block   dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="ml-2 h-full text-blue-500">
            <button className="dark:bg-gray-900 dark:border-gray-800 dark:border dark:hover:bg-gray-800 bg-gray-300 p-2 rounded-md hover:bg-gray-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }
  renderBody() {
    return (
      <div className="p-2 py-2 sm:px-10 ">
        <div className="sm:flex ">
          <div className="w-full sm:w-3/4 sm:mr-2  ">
            {this.renderHeader()}

            {this.state.is_loading ? (
              <div className="border rounded-md my-2 p-10 dark:bg-gray-900 dark:border-gray-800 bg-white">
                <div className="text-center">
                  <ProgressSpinner style={{ width: "40px" }} />
                  <div className="text-gray-500">
                    Fetching data. Please wait
                  </div>
                </div>
              </div>
            ) : this.state.users.length == 0 ? (
              <div className="border rounded-md my-2 p-10 dark:bg-gray-900 dark:border-gray-800 bg-white">
                <div className="text-center text-gray-500 flex justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 text-red-500 mx-1 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
                    />
                  </svg>
                  No users found. Please check again later
                </div>
              </div>
            ) : (
              <UsersList
                toast={toast}
                fetchUsers={this.fetchUsers}
                users={this.state.users}
                updateUsers={this.updateUsers}
              />
            )}
          </div>
          <div className="w-full sm:w-1/4  p-1 ">
            <SidePane stats={this.state.stats} />
          </div>
        </div>

        <ToastContainer />
      </div>
    );
  }
}

document.title = "Users";
