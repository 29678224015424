import React, { Component, createRef } from "react";
import Page from "../../components/page";
import JudgementViewPane from "../sections/judgement_view/view_page";
import JudgementSideControls from "../sections/judgement_view/judgement_side_controls";
import { ProgressSpinner } from "primereact/progressspinner";
import { fetchTags } from "../../backend_handlers/TagController";
import ConfirmDialog from "../../components/confirmDialog";
import { Avatar } from "primereact/avatar";
import { fetchJudgement, getAnnotations } from "../../backend_handlers/JudgementController";
import { ToastContainer, toast } from "react-toastify";
import ReferenceViewDialog from "../../components/referenceViewDialog";
import { getInitials } from "../../utils/utils";


export default class ViewPage extends Page {
  constructor(props) {
    super(props);
    this.state = {
      judgement: {},
      highlighted: [],
      is_loading: false,
      tags: [],
      annotation_metadata: [],
      colors: [
        "#dc2626",
        "#0d9488",
        "#4f46e5",
        "#c026d3",
        "#db2777",
        "#059669",
        "#ea580c",
        "#d97706",
        "#65a30d",
        "#ca8a04",
      ],
      user: this.props.user,
      selected_paragraph: null,
      current_side_control: "tags",
      selected_annotation: null,
      active_tag: null
    };
    this.fetchTags = fetchTags.bind(this);
    this.confirmDialog = React.createRef();
    this.fetchJudgement = fetchJudgement.bind(this);
    this.fetchTagMetadata = getAnnotations.bind(this);
    this.judgementViewPaneRef = createRef();
    this.sideControlRef = React.createRef();
    this.referenceViewDialog = React.createRef();
    this.getInitials = getInitials.bind(this);
  }
  updateTag = (tag) => {

    let { tags } = this.state;
    tags.forEach((tagItem, index) => {
      if (tagItem.id == tag.id) {
        tags[index] = tag;
      }
      else {
        if (tag.expanded) {
          tagItem.expanded = false;
          tags[index] = tagItem;
        }
      }
    })

    this.setState({ tags: tags });

  }
  activateParagraph = (paragraph) => {
    this.setState({ selected_paragraph: paragraph });
  }
  switchSideControl = (control, data = null) => {
    if (control == 'references') {
      this.setState({ current_side_control: control, selected_annotation: data });
    }
    this.setState({ current_side_control: control });
  }
  showNotes = (control) => {
    this.setState({ current_side_control: control });
  }
  componentDidMount() {
    this.fetchJudgementData();
    document.title = "Judgement | View"
  }
  getAnnotationReference = (annotation) => {
    let ref = this.state.annotation_metadata.find((Obj) => {
      return Obj.annotation.id == annotation.id;
    });
    return ref.reference;
  }
  activateAllAnnotations = (tag) => {

    let { annotation_metadata } = this.state;

    annotation_metadata.forEach((annotationItem, index) => {
      if (annotationItem.annotation.tag_id == tag.id) {
        annotationItem.annotation['checked'] = true;
      }

    })

    this.setState({ annotation_metadata });
  }
  deactivateAllAnnotations = (tag) => {
    let { annotation_metadata } = this.state;

    annotation_metadata.forEach((annotationItem, index) => {
      if (annotationItem.annotation.tag_id == tag.id) {
        annotationItem.annotation['checked'] = false;
      }

    })

    this.setState({ annotation_metadata });
  }
  async fetchJudgementData() {
    let { id } = this.props.match.params;

    this.setState({ is_loading: true });
    let response = await this.fetchJudgement(id, toast);

    if (response) {
      if (response.status == 200) {
        this.setState({ judgement: response.data });
        this.fetchAnnotations(id);
      }
    }
    this.setState({ is_loading: false });
  }
  refreshTags = () => {

    this.fetchAnnotations(this.state.judgement.id);
  }
  addAnnotation = (annotation) => {
    let { annotation_metadata } = this.state;
    annotation_metadata = [...annotation_metadata, annotation];
    this.setState({ annotation_metadata });
  }
  updateAnnotation = (annotation) => {

    let { annotation_metadata } = this.state;

    annotation_metadata.forEach((annotationItem, index) => {
      if (annotationItem.annotation.id == annotation.annotation.id) {
        annotation_metadata[index] = annotation;

      }
    })

    this.setState({ annotation_metadata });

  }
  deleteAnnotation = (annotation_id) => {
    console.log("deleting annotation")
    let { annotation_metadata } = this.state;
    let newAnnotations = annotation_metadata.filter(Obj => Obj.annotation.id != annotation_id);
    console.log(annotation_id);
    console.log(annotation_metadata)
    console.log(newAnnotations);
    this.setState({ annotation_metadata: newAnnotations });
  }
  fetchAnnotations = async (judgement_id) => {
    let response = await this.fetchTags(judgement_id, toast);
    let activeTag = this.state.tags.find(Obj => Obj.expanded == true);



    if (response) {
      if (response.status == 200) {
        let tags_obj = response.data;
        tags_obj.map((tag, index) => {
          tag["color"] = this.state.colors[index];
          if (tag.id == activeTag?.id) {
            tag['expanded'] = true;
          }
        });
        this.setState({ tags: tags_obj });

        this.fetchAnnotationsMetadata(judgement_id);
      }
    }
  }
  addNote = async (paragraph, note) => {
    let { judgement } = this.state;

    let newParagraphs = await Promise.all(
      judgement.Paragraphs.map((item, index) => {
        if (item.id == paragraph.id) {
          item.Notes = [...item.Notes, note];
        }
        return item;
      })
    );

    judgement.Paragraphs = newParagraphs;
    this.setState({ judgement: judgement, selected_paragraph: paragraph });
    this.judgementViewPaneRef.current.prepareContent();
  }
  removeNote = async (note) => {
    let { judgement } = this.state;

    let newParagraphs = await Promise.all(
      judgement.Paragraphs.map((item, index) => {
        if (item.id == note.paragraph_id) {
          let newNotes = item.Notes.filter((Obj) => Obj.id != note.id);
          item.Notes = newNotes;
        }
        return item;
      })
    );

    judgement.Paragraphs = newParagraphs;
    this.setState({ judgement });
    this.judgementViewPaneRef.current.prepareContent();
  }
  updateNote = async (note) => {
    let { judgement } = this.state;

    let newParagraphs = await Promise.all(
      judgement.Paragraphs.map((item, index) => {
        if (item.id == note.paragraph_id) {
          item.Notes.map((noteItem, index) => {
            if (noteItem.id == note.id) {
              noteItem.label = note.label;
              noteItem.note = note.note;
              noteItem.access = note.access;
            }

            return noteItem;
          });
        }
        return item;
      })
    );

    judgement.Paragraphs = newParagraphs;
    this.setState({ judgement });
  }
  fetchAnnotationsMetadata = async (judgement_id) => {

    let oldAnnotationState = this.state.annotation_metadata;
    let response = await this.fetchTagMetadata(judgement_id, toast);

    if (response.status == 200) {
      response.data.forEach(annotationItem => {
        let oldState = oldAnnotationState.find(Obj => Obj.annotation.id == annotationItem.annotation.id);
      })
      this.setState({ annotation_metadata: response.data });
    }
  }


  toggleHighlight = (annotation) => {

    let { annotation_metadata } = this.state;


    annotation_metadata.forEach((annotationItem, index) => {
      if (annotationItem.annotation.id == annotation.id) {
        annotation['checked'] = !annotation['checked'];
      }
    })
    this.setState({ annotation_metadata })
  }

  refreshViewPage = () => {
    this.judgementViewPaneRef?.current?.prepareContent();
  }

  preparePropsBundle() {
    return {
      removeHighliting: this.removeHighliting,
      highlight: this.highlight,
      refreshTags: this.refreshTags,
      confirmDialog: this.confirmDialog,
      switchSideControl: this.switchSideControl,
      referenceViewDialog: this.referenceViewDialog,
      updateNote: this.updateNote,
      addNote: this.addNote,
      removeNote: this.removeNote,
      activateParagraph: this.activateParagraph,
      getAnnotationReference: this.getAnnotationReference,
      refreshViewPageDialog: this.refreshViewPage,
      updateTag: this.updateTag,
      getTagColor: this.getTagColor,
      toggleHighlight: this.toggleHighlight,
      activateAllAnnotations: this.activateAllAnnotations,
      deactivateAllAnnotations: this.deactivateAllAnnotations,
      addAnnotation: this.addAnnotation,
      updateAnnotation: this.updateAnnotation,
      deleteAnnotation: this.deleteAnnotation
    }
  }
  getTagColor = (annotation) => {
    return this.state.tags.find(Obj => Obj.id == annotation.tag_id).color;
  }

  renderBody() {
    if (this.state.is_loading) {
      return (
        <div className=" bg-white dark:bg-black  dark:border-gray-700 dark:text-gray-100 sm:m-10 border p-10 dark:border-gray-700 rounded-md">
          <div className="flex justify-center">
            <div className="text-center">
              <ProgressSpinner style={{ height: "50px" }} />
              <div className="dark:text-white">
                Loading Judgement Data. Please wait....
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      );
    }

    return (
      <div className=" sm:flex justify-between ">

        <div className="w-full z-0 bg-white dark:bg-gray-900  border dark:border-gray-800 p-3 m-2 mt-3 sm:w-4/5 ">
          <JudgementViewPane
            sideControlRef={this.sideControlRef}
            ref={this.judgementViewPaneRef}
            parent_functions={this.preparePropsBundle()}
            parent_state={this.state}
          />
        </div>

        <div className="w-full   sm:w-1/5">
          <JudgementSideControls
            ref={this.sideControlRef}
            toast={toast}
            parent_functions={this.preparePropsBundle()}
            parent_state={this.state}
          />
        </div>

        <div className="sticky top-0 fixed border ml-2 px-1  top-0 bg-white  flex flex-col h-screen  gap-3  sticky">
          <div className="p-2 mb-5">
            <Avatar
              label={this.getInitials(
                this.state.user ? this.state.user.name : "Guest User"
              )}
              size=""
              style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
              shape="circle"
            />
          </div>
          <div
            onClick={() => this.switchSideControl("tags")}
            className={
              "h-fit cursor-pointer border flex items-center justify-center p-2  " +
              (this.state.current_side_control == "tags" || this.state.current_side_control == 'references'
                ? " border-r-blue-500 border-r-4 "
                : "")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 6h.008v.008H6V6z"
              />
            </svg>
          </div>
          <div
            onClick={() => this.switchSideControl("notes")}
            className={
              "h-fit cursor-pointer border flex items-center justify-center p-2  " +
              (this.state.current_side_control == "notes"
                ? " border-r-blue-500 border-r-4 "
                : "")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
              />
            </svg>
          </div>
          <div
            onClick={() => this.switchSideControl("info")}
            className={
              "h-fit cursor-pointer border flex items-center justify-center p-2  " +
              (this.state.current_side_control == "info"
                ? " border-r-blue-500 border-r-4 "
                : "")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
          </div>
          {this.state.user ? <div
            onClick={() => this.switchSideControl("print")}
            className={
              "h-fit cursor-pointer border flex items-center justify-center p-2  " +
              (this.state.current_side_control == "print"
                ? " border-r-blue-500 border-r-4 "
                : "")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
              />
            </svg>
          </div> : ''}
        </div>

        <ToastContainer />
        <ConfirmDialog ref={this.confirmDialog} />
        <ReferenceViewDialog ref={this.referenceViewDialog} />
      </div>
    );
  }
}
document.title = "Judgment view";
