import React, { Component } from 'react'

export default class SearchBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current_paragraph: 0,
            results_paragraphs: [],
        }
    }
    getResultsCounts() {
        let counts = 0;
        let results_paragraphs = [];
        Object.keys(this.props.parent_state.results_counts).map((item, index) => {
            counts += parseInt(this.props.parent_state.results_counts[item][item]);
        })

        return counts;
    }

    render() {




        return (
            <div>
                <div className='flex  w-full justify-end  items-center '>
                    <div className='w-3/5 flex rounded-md border dark:border-gray-600 mr-1'>
                        <input name='search' value={this.props.parent_state.searchFilter} onChange={(e) => this.props.updateSearchState(e.target.value)} placeholder='Type to search in judgement ....' className='text-sm bg-white p-2 border-none rounded-md border text-gray-900 text-sm  focus:ring-blue-500 focus:border-t-blue-500 block w-full  dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-200 dark:focus:ring-blue-500 dark:focus:border-t-blue-500 w-full ' />
                        <button onClick={(e) => this.props.updateSearchState('')} className={'text-blue-500 bg-gray-800 px-1 rounded-md ml-2' + (this.props.parent_state.isSearching ? ' ' : ' hidden ')}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className={'text-sm dark:text-gray-100 mx-2 dark:text-red-500 ' + (this.props.parent_state.isSearching ? ' ' : ' hidden ')}>
                        {this.getResultsCounts() > 0 ?
                            <div className='text-green-500'> {this.getResultsCounts()}  results found</div>
                            :

                            <div className='text-red-500'> no results</div>
                        }

                    </div>

                    <div className={'flex  justify-start' + (this.props.parent_state.isSearching ? ' ' : ' hidden ')}>
                        <div className=' flex w-full justify-center'>
                            <button onClick={() => this.props.scrollToPrevOccurence()} className='text-blue-500'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
                                </svg>
                            </button>
                            <button onClick={() => this.props.scrollToNextOccourence()}  className='text-blue-500'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}
